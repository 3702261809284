









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import StageHeader from './StageHeader.vue';
import { getStageIcon } from './stage-details';
import { PlanningAuthorityDetails } from './stages.model';

import {
  getPlanningAuthorityStatus,
  getPlanningAuthorityData,
} from './planning-authority';

@Component({
  components: {
    StageHeader,
  },
})
export default class PlanningAuthority extends Vue {
  @Prop() model!: PlanningAuthorityDetails;

  get planningAuthorityStatus() {
    return getStageIcon(getPlanningAuthorityStatus(this.model));
  }

  get planningAuthorityData() {
    return getPlanningAuthorityData(this.model);
  }
}
