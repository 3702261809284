


















import { setUpFormAccessibilityOptions } from '@/helpers/accessibilityOptions';
import { updateAmendmentSearchParams } from '@/helpers/search-params';
import { SearchAmendmentRequestOptions, SelectedScheme } from '@/models';
import RplForm from '@dpc-sdp/ripple-form';
import { RplIcon, RplTextIcon } from '@dpc-sdp/ripple-icon';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ClearSearch from '../shared/ClearSearch.vue';

const namespace = 'amendment';

@Component({
  components: {
    ClearSearch,
    RplForm,
    RplTextIcon,
    RplIcon,
  },
})
export default class SearchAmendment extends Vue {
  public val = null;

  public formData;

  private searchPopupText = 'Press [Enter] to search amendments.';

  private helpText = 'For example: Amendment number or description';

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  get updatedFormData() {
    this.formData.model.text = this.searchParams?.q;
    return this.formData;
  }

  constructor() {
    super();
    this.formData = {
      model: {
        text: null,
      },
      schema: {
        fields: [
          {
            attributes: {
              'aria-label': 'search',
              autocomplete: 'off',
            },
            type: 'input',
            inputType: 'text',
            label: 'search amendments',
            labelClass: 'sr-only',
            styleClasses: [
              'rpl-form__prepend-icon',
              'rpl-form__prepend-icon--search',
            ],
            placeholder: 'Search...',
            model: 'text',
          },
        ],
      },
      tag: 'rpl-fieldset',
      formState: {},
    };
  }

  public clearSearch() {
    this.formData.model.text = '';
    this.submit();
  }

  public submit(): void {
    const q =
      this.formData.model.text === '' ? undefined : this.formData.model.text;

    updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...this.searchParams,
      page: undefined,
      q,
    });
  }

  mounted() {
    this.$nextTick(() => {
      setUpFormAccessibilityOptions(
        this.$refs.searchForm,
        'search form',
        'clear search',
      );
    });
  }
}
