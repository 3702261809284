import { MutationTree } from 'vuex';
import {
  DateFilter,
  IncorporatedDocument,
  IncorporatedDocumentState,
} from '@/store/incorporated-documents/types';
import { Sorting } from '@/store/schemes/types';

export const mutations: MutationTree<IncorporatedDocumentState> = {
  setDocuments(state, documents: IncorporatedDocument[]) {
    state.documents = documents;
  },
  setDateFilter(state, dateFilter: DateFilter) {
    state.dateFilter = dateFilter;
  },
  setSearchTerm(state, searchQuery: string) {
    state.searchQuery = searchQuery;
  },
  setSortParams(state: IncorporatedDocumentState, payload: Sorting) {
    state.sortParams = payload;
  },
  setPageSize(state: IncorporatedDocumentState, size: number) {
    state.pageSize = size;
  },
  setSelectedTypeOptions(state, payload: string[]) {
    state.selectedTypeOptions = payload;
  },
  setSelectedStatusOptions(state, payload: string[]) {
    state.selectedStatusOptions = payload;
  },
};
