






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Summary extends Vue {
  @Getter('amendmentDetails', { namespace: 'amendment' })
  private amendmentDetails!: any;
}
