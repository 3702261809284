import { PlanningSchemeBySchemeIdClauses } from '@/models';
import { extractCategory, refineHeader } from '@/helpers';

export const createScheduleText = (text: string): string => {
  if (!text) return '';
  const hasSchedule = text.indexOf('SCHEDULE');
  if (hasSchedule === 0) {
    return `<span><span class="schedule-text">SCHEDULE</span> ${text.substr(
      hasSchedule + 8,
    )} </span>`;
  }
  return text;
};

export const createMenuGroupsHeader = (text: string): string => {
  const cat = extractCategory(text);
  // split by . to avoid pattern like 12.05
  const newCatByDot = cat.split('.')[0];
  return newCatByDot.substr(0, newCatByDot.length - 1);
};

export const threeDigitRootTitle = (text: string): string => {
  if (!text) return '';
  let result = text;
  const cat = extractCategory(text).split('.')[0];
  if (cat.length > 2) {
    result = `${cat.substr(0, 2)}0 ${refineHeader(text)}`;
  }
  return result;
};

const convertToRootLevelMenuObject = (
  clauses: PlanningSchemeBySchemeIdClauses[],
): any => {
  const subMenu = {};
  clauses.forEach((clause) => {
    const key = createMenuGroupsHeader(clause.title);
    subMenu[key] = subMenu[key] || {
      title: threeDigitRootTitle(clause.title),
      ordinanceType: clause.ordinanceType,
      ordinanceLevel: clause.ordinanceLevel,
      ordinanceID: clause.ordinanceID,
      children: [],
    };
    subMenu[key].children.push(clause);
  });
  return subMenu;
};

const generateSubMenuChildren = (children: any, isSchedule: boolean): any => {
  const child = children?.map((c) => {
    const subChild: {
      text: string;
      ordinanceID: string;
      identifier?: string;
      ordinanceLevel?: string;
      ordinanceType?: string;
      ordinanceMapCode?: string;
      isSchedule: boolean;
    } = {
      text: isSchedule ? createScheduleText(c.title) : c.title,
      ordinanceID: c?.ordinanceID,
      identifier: isSchedule ? '' : c?.title?.split(' ')[0],
      ordinanceLevel: isSchedule ? '' : c?.ordinanceLevel,
      ordinanceType: c?.ordinanceType,
      isSchedule,
      ordinanceMapCode: c.ordinanceMapCode
        ? c.ordinanceMapCode.split(',').join(', ')
        : null,
    };
    if (c.subClauses) {
      return {
        ...subChild,
        children: generateSubMenuChildren(c.subClauses, false),
      };
    }
    if (c.schedules) {
      return {
        ...subChild,
        children: generateSubMenuChildren(c.schedules, true),
      };
    }
    return subChild;
  });
  return child;
};

const generateMenuLinks = (links: {}): any => {
  const menuLinks: any[] = [];
  Object.keys(links).forEach((l) => {
    menuLinks.push({
      text: links[l].title,
      ordinanceID: links[l]?.ordinanceID,
      ordinanceType: links[l].ordinanceType,
      ordinanceLevel: links[l].ordinanceLevel,
      identifier: links[l].title.split(' ')[0],
      children: generateSubMenuChildren(links[l].children, false),
    });
  });

  return menuLinks;
};

const buildNav = (clauses: PlanningSchemeBySchemeIdClauses[]): any =>
  generateMenuLinks(convertToRootLevelMenuObject(clauses));

export { buildNav };
