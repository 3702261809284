












import Vue from 'vue';
import Component from 'vue-class-component';
import getPageTitle from '@/helpers/page-title';
import Header from '@/components/header-footer/Header.vue';
import Footer from '@/components/header-footer/Footer.vue';
import BackToTop from '@/components/shared/BackToTop.vue';

@Component({
  components: {
    BackToTop,
    Header,
    Footer,
  },
  metaInfo() {
    const title = getPageTitle(this.$route);
    return {
      title,
    };
  },
})
export default class App extends Vue {
  get schemeTitle() {
    return this.$store.getters['schemes/schemeTitle'];
  }

  constructor() {
    super();
    this.routeOldQueryParams();
  }

  private routeOldQueryParams(): void {
    const { planningSchemeName: schemeName, ordinanceID } =
      (this.$route?.query as { [key: string]: string }) || {};

    if (!schemeName) {
      return;
    }

    this.$router.push({
      name: ordinanceID ? 'ordinanceId' : 'scheme',
      params: {
        schemeName,
        ordinanceID,
      },
    });
  }
}
