import {
  SearchAmendmentFilter,
  FilterQuery,
  HistoryRequestOptions,
} from '@/models';
import dayjs from 'dayjs';

export const toOffsetFormat = (
  value: string | undefined,
  timeFormat: string,
) => {
  if (!value) {
    return value;
  }

  return dayjs(`${value}T${timeFormat}`).format();
};

export const formatFilter = (filter: SearchAmendmentFilter) => {
  const { from, to, ...otherFilters } = filter;
  return {
    from: toOffsetFormat(from, '00:00:00.000'),
    to: toOffsetFormat(to, '23:59:59.999'),
    ...otherFilters,
  };
};

export const flattenFilter = (
  filterParams: SearchAmendmentFilter,
): FilterQuery =>
  Object.entries(filterParams).reduce((acc, [key, value]) => {
    if (!value || value.length === 0) {
      return acc;
    }

    let filterValue;

    if (typeof value === 'string' || typeof value === 'boolean') {
      filterValue = value;
    }
    if (typeof value === 'object') {
      filterValue = value.join(',');
    }
    if (typeof value === 'boolean') {
      filterValue = Boolean(value);
    }

    return {
      ...acc,
      [`filter.${key}`]: filterValue,
    };
  }, {});

export const composeHistoriesFilter = (
  filterParams: HistoryRequestOptions,
): HistoryRequestOptions =>
  Object.entries(filterParams).reduce((acc, [key, value]) => {
    if (!value || value.length === 0) {
      return acc;
    }

    return {
      ...acc,
      [`${key}`]: value,
    };
  }, {});

export const formatTimeZones = (
  from: string | undefined,
  to: string | undefined,
): HistoryRequestOptions => ({
  from: toOffsetFormat(from, '00:00:00.000'),
  to: toOffsetFormat(to, '23:59:59.999'),
});
