












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplTextIcon, RplIcon } from '@dpc-sdp/ripple-icon';

@Component({
  components: {
    RplTextIcon,
    RplIcon,
  },
})
export default class ClearSearch extends Vue {
  @Prop() text;

  public emitClearSearch() {
    this.$emit('clear-search');
  }
}
