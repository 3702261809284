










import Vue from 'vue';
import { Getter } from 'vuex-class';

import { Component, Prop } from 'vue-property-decorator';
import {
  SearchAmendmentRequestOptions,
  SelectedScheme,
  SearchAmendmentFilter,
  FilterOption,
  MultiSelectConfig,
} from '@/models';

import { LoaderStates, LoaderState } from '@/store/loading';
import MultipleSelect from '../shared/MultipleSelect.vue';
import FilterSection from '../filter/FilterSection.vue';

import { updateAmendmentSearchParams } from '../../helpers/search-params';

const namespace = 'amendment';

@Component({
  components: {
    MultipleSelect,
    FilterSection,
  },
})
export default class CheckboxGroup extends Vue {
  private filters: FilterOption[] = [];

  @Prop() title;

  @Prop() filterName;

  @Prop() options;

  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('state', { namespace: 'loading' })
  private loaderState!: LoaderState;

  public multiSelectConfig: MultiSelectConfig = {
    multiselect: true,
    placeholder: 'Select',
    showItems: 1,
    fieldId: 'select',
    inputName: null,
    label: '',
  };

  get isRunning() {
    return this.loaderState !== LoaderStates.COMPLETED;
  }

  get multiSelectOptions() {
    this.filters = this.generateFilterOptions(this.searchParams.filter);
    return this.filters;
  }

  private generateFilterOptions(
    filter: SearchAmendmentFilter = {},
  ): FilterOption[] {
    const filterValues = filter[this.filterName] || [];

    return [
      {
        selected: filterValues.length === this.options.length,
        focussed: true,
        uuid: '',
        id: 'all',
        name: 'All',
        disabled: this.isRunning,
      },
      ...this.options.map((option) => ({
        ...option,
        id: typeof option === 'string' ? option : option && option.key,
        name: typeof option === 'string' ? option : option && option.label,
        selected: filterValues.includes(option.key),
        focussed: false,
        uuid: '',
        disabled: this.isRunning,
      })),
    ];
  }

  private updateSelectedCheckbox(key) {
    const { filters } = this;
    const selectedOptions = key.split(',');

    filters.forEach((filter) => {
      // eslint-disable-next-line no-param-reassign
      filter!.selected = selectedOptions.includes(filter.key);
    });
  }

  private updateRouteWithFilterParams() {
    const { filter = {} } = this.searchParams;

    const selectedOptions = this.filters
      ?.filter(({ selected }) => selected === true)
      .map((item) => item.key);

    updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...this.searchParams,
      page: undefined,
      filter: { ...filter, [`${this.filterName}`]: selectedOptions },
    });
  }

  public toggleCheckbox(key) {
    this.updateSelectedCheckbox(key);
    this.updateRouteWithFilterParams();
  }
}
