import { HistoricalScheme } from '@/store/schemes/types';

const formatDateLong = (date: Date) => {
  if (date instanceof Date) {
    const weekday = new Intl.DateTimeFormat('en-AU', {
      weekday: 'long',
    }).format(date);

    const month = new Intl.DateTimeFormat('en-AU', {
      month: 'long',
    }).format(date);

    return `${weekday} ${String(date.getDate()).padStart(
      2,
      '0',
    )} ${month} ${date.getFullYear()}`;
  }
  return '';
};

const historicPlanningSchemeTitle = (schemeTitle: string): string => {
  let schemeTitleFormatted = '';
  if (schemeTitle) {
    schemeTitleFormatted =
      schemeTitle !== 'Victoria Planning Provisions'
        ? `${schemeTitle} historical planning scheme records`
        : `${schemeTitle} historical records`;
  }
  return schemeTitleFormatted;
};

const formatVersionTitle = (schemeVersion: HistoricalScheme): string => {
  let versionTitle = '';

  if (schemeVersion) {
    versionTitle = schemeVersion.operationalDate
      ? `${schemeVersion.amendmentNumber} - ${formatDateLong(
          schemeVersion.operationalDate,
        )}`
      : schemeVersion.amendmentNumber;
  }

  return versionTitle;
};

const selectedVersionTitle = (historicalScheme: HistoricalScheme): string =>
  historicalScheme && historicalScheme.amendmentNumber
    ? `Version: ${formatVersionTitle(historicalScheme)}`
    : '';

export { formatDateLong, historicPlanningSchemeTitle, selectedVersionTitle };
