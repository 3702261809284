var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"section"},on:{"enter":_vm.start,"after-enter":_vm.end,"before-leave":_vm.start,"after-leave":_vm.end}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"rpl-section-menu",attrs:{"data-depth":_vm.depth}},_vm._l((_vm.menu),function(link,index){return _c('li',{key:index,class:[
        'rpl-section-menu__item',
        'rpl-section-menu__item--parent',
        {
          'rpl-section-menu__item--active': _vm.menuItemOpen[index],
          'schedule-item': link.isScheduleSection,
        } ],attrs:{"data-historic":_vm.isHistoricalNavigator}},[_c('MenuItem',{attrs:{"depth":_vm.depth,"children":link.children,"header":_vm.getHeader(link.title),"identifier":link.identifier,"is-open":!!_vm.menuItemOpen[index],"is-schedule":link.isSchedule,"title":link.title,"routeMeta":link.route.meta,"map-code":link.ordinanceMapCode,"isHistoricalNavigator":_vm.isHistoricalNavigator,"ordinance-level":link.ordinanceLevel,"ordinance-type":link.ordinanceType,"route":link.route},on:{"expand-menu":function () {
            _vm.expandSection(index);
          },"toggle-menu":function () {
            _vm.toggleSection(index);
          }}}),(link.children)?_c('Menu',{ref:"childMenu",refInFor:true,attrs:{"open":_vm.menuItemOpen[index],"menu":link.children,"depth":_vm.depth ? _vm.depth + 1 : 1,"isHistoricalNavigator":_vm.isHistoricalNavigator}}):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }