








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import OrdinanceTableModal from './OrdinanceTableModal.vue';

@Component({
  components: {
    RplIcon,
  },
})
export default class OpenTableModalButton extends Vue {
  @Prop() content;

  @Prop() width;

  public modalOpened(): void {
    document.body.classList.add('modal-opened');
  }

  public modalClosed(): void {
    document.body.classList.remove('modal-opened');
  }

  public openModal(): void {
    this.$modal.show(
      OrdinanceTableModal,
      {
        content: this.content,
      },
      {
        width: this.width,
        height: 'auto',
        adaptive: true,
        focusTrap: true,
      },
      {
        opened: this.modalOpened,
        closed: this.modalClosed,
      },
    );
  }
}
