import Router from 'vue-router';

import { HistoryRequestOptions } from '@/models';
import { composeHistoriesFilter } from '@/helpers/filter-formatter';
import { SortOrder } from '@/store/schemes/types';

const { isNavigationFailure } = Router;

export const updateHistoriesFilter =
  (router, schemeName: string) => (query: HistoryRequestOptions) => {
    router
      .push({
        name: 'histories',
        params: { schemeName },
        query: {
          ...composeHistoriesFilter(query),
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  };

export const filterHistories = (
  item: string | undefined,
  val: string,
): boolean =>
  item ? item.toLowerCase().indexOf(val.toLowerCase()) > -1 : false;

export const filterHistoriesOutcomeStatusAndType = (
  item: string | undefined,
  val: string[],
): boolean => (item && val.length > 0 ? val.includes(item) : true);

export const filterByLegacyAmendmentId = (
  legacyAmendmentNumbers: string[],
  amendmentId: string,
  historicListSearchTerm: string,
): boolean => {
  if (legacyAmendmentNumbers.length > 0) {
    const match = legacyAmendmentNumbers.some(
      (x) => x.toLowerCase().indexOf(historicListSearchTerm.toLowerCase()) > -1,
    );
    if (match) {
      return match;
    }
    return (
      amendmentId.toLowerCase().indexOf(historicListSearchTerm.toLowerCase()) >
      -1
    );
  }
  return (
    amendmentId.toLowerCase().indexOf(historicListSearchTerm.toLowerCase()) > -1
  );
};

export const sortByOperationalDate = (sortOrder, a, b) => {
  if (sortOrder === SortOrder.ASC) {
    if (a.getTime() < b.getTime()) {
      return -1;
    }
    if (a.getTime() > b.getTime()) {
      return 1;
    }
    return 0;
  }
  if (sortOrder === SortOrder.DESC) {
    if (a.getTime() < b.getTime()) {
      return 1;
    }
    if (a.getTime() > b.getTime()) {
      return -1;
    }
    return 0;
  }
  return 0;
};

export const sortByLocaleCompare = (sortOrder, a, b) => {
  if (sortOrder === SortOrder.DESC) {
    return a.localeCompare(b);
  }
  if (sortOrder === SortOrder.ASC) {
    return b.localeCompare(a);
  }
  return 0;
};
