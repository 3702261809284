







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { featureEnabled } from '@/helpers/feature-toggles';

@Component
class FeatureToggled extends Vue {
  // TODO: replace these 2 fields with a store getter when available (PSO-329)
  @Prop() public toggleKey;

  private backingToggle: boolean | null = null;

  async mounted() {
    this.backingToggle = await featureEnabled(this.toggleKey);
  }

  get toggleOn() {
    return this.backingToggle;
  }
}

export default FeatureToggled;
