const Abandoned: unique symbol = Symbol('Abandoned');
const Adopted: unique symbol = Symbol('Adopted');
const SplitIntoParts: unique symbol = Symbol('SplitIntoParts');
const Lapsed: unique symbol = Symbol('Lapsed');
const Unknown: unique symbol = Symbol('Unknown');

export const PlanningAuthorityDecision = {
  Abandoned,
  Adopted,
  Lapsed,
  SplitIntoParts,
  Unknown,
} as const;

export type PlanningAuthorityDecisionSymbol =
  typeof PlanningAuthorityDecision[keyof typeof PlanningAuthorityDecision];

export interface Stages {
  exhibitionRequired: boolean;
  panelRequested: boolean;
  exhibition?: ExhibitionStage;
  panelHearing?: PanelHearingStage;
  planningAuthority?: PlanningAuthority;
  decisionRequired: boolean;
  submittedDate?: string;
  gazettal?: Gazettal;
}

export interface InitialStages {
  exhibitionRequired: boolean;
  panelRequested: boolean;
  exhibition?: ExhibitionStage;
  panelHearing?: PanelHearingStage;
  planningAuthority?: InitialPlanningAuthority;
  decisionRequired: boolean;
  submittedDate?: string;
}

export interface Document {
  filename: string;
  url: string;
  bytes: number;
}

export interface ExhibitionStage {
  startDate: string;
  endDate: string;
  submissionsEndDate?: string;
  clausesAndSchedules: Array<Document>;
  mapSheets: Array<Document>;
  supportingDocuments: Array<Document>;
  incorporatedDocuments: Array<Document>;
  otherDocuments: Array<Document>;
}

export interface PanelHearingStage {
  reportReceivedDate: string;
  requestedDate: string;
  appointedDate: string;
  reportDueDate: string;
  chair?: string;
  members?: string;
  report: Document;
  documents: Array<Document>;
  otherDocuments: Array<Document>;
}

export interface PlanningAuthority {
  decision: PlanningAuthorityDecisionSymbol;
  decisionDate: string;
}

export interface InitialPlanningAuthority {
  decision: string;
  decisionDate: string;
}

export interface Gazettal {
  date: string;
  operationalDate: string;
  explanatoryReport?: Document;
  instructionSheet?: Document;
  clausesAndSchedules: Array<Document>;
  mapSheets: Array<Document>;
  supportingDocuments: Array<Document>;
  incorporatedDocuments: Array<Document>;
  otherDocuments: Array<Document>;
}

export interface SearchAmendmentQueryParams {
  limit?: string;
  offset?: number;
  page?: string;
  q?: string;
  schemeCode?: string;
  sortKey?: string;
  sortOrder?: string;
  'filter.scheme'?: string;
  'filter.amendmentType'?: string;
  'filter.status'?: string;
  'filter.outcome'?: string;
  'filter.from'?: string;
  'filter.to'?: string;
  'filter.dfp'?: string;
}

export interface FilterOption {
  key: string;
  label: string;
  selected?: boolean;
}
