import { AmendmentOutcome } from '@/models';
import { decisionToString } from './convert-to-ui';

import {
  StageStatus,
  StageStatusSymbol,
  StageHeaderData,
  PlanningAuthorityDetails,
  StageDetailsType,
} from './stages.model';

export const getPlanningAuthorityStatus = (
  model: PlanningAuthorityDetails,
): StageStatusSymbol => {
  const { stages, statuses } = model;
  const outcomesForNotRequired = [
    AmendmentOutcome.Lapsed,
    AmendmentOutcome.SplitParent,
  ];
  const amendmentStatus = ['PreGazettal', 'Gazetted', 'Finished'];

  if (outcomesForNotRequired.includes(statuses.outcome!)) {
    return StageStatus.NOT_REQUIRED;
  }

  if (stages.planningAuthority) {
    return StageStatus.COMPLETE;
  }

  if (amendmentStatus.includes(statuses.status)) {
    return StageStatus.NOT_REQUIRED;
  }

  return StageStatus.PENDING;
};

export const getPlanningAuthorityData = (
  model: PlanningAuthorityDetails,
): StageHeaderData[] => {
  const stage = getPlanningAuthorityStatus(model);
  switch (stage) {
    case StageStatus.NOT_REQUIRED:
      return [{ value: 'Not required' }];
    case StageStatus.PENDING:
      return [{ value: 'Pending' }];
    default: {
      const { decision, decisionDate } = model.stages.planningAuthority!;
      return [
        {
          label: 'Decision',
          value: decisionToString(decision),
          type: StageDetailsType.Text,
        },
        {
          label: 'Decision Date',
          value: decisionDate,
          type: StageDetailsType.Date,
        },
      ];
    }
  }
};
