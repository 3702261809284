var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amendment-row",attrs:{"role":"row"}},[_c('div',{staticClass:"header-mobile"},[_c('div',{staticClass:"header-mobile__cell",attrs:{"role":"cell"}},[_c('p',{staticClass:"header-mobile__cell-value"},[(_vm.allSchemesView)?_c('PSOLink',{attrs:{"linkTo":{
            name: 'amendmentDetail',
            params: {
              amendmentNumber: _vm.row.amendmentNumber,
              schemeName: _vm.allSchemesTitle,
            },
            query: {
              schemeCode: _vm.row.schemes.length ? _vm.row.schemes[0].code : '',
            },
          },"showBackButton":false}},[_c('span',{attrs:{"slot":"highlight"},domProps:{"innerHTML":_vm._s(_vm.markedId)},slot:"highlight"})]):_c('PSOLink',{attrs:{"linkTo":{
            name: 'amendmentDetail',
            params: { amendmentNumber: _vm.row.amendmentNumber },
          },"showBackButton":false}},[_c('span',{attrs:{"slot":"highlight"},domProps:{"innerHTML":_vm._s(_vm.markedId)},slot:"highlight"})])],1)])]),_c('div',{staticClass:"header"},[_c('RplCol',{staticClass:"header__cell",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Amendment no.")]),_c('p',{staticClass:"header__cell-value amendment-link"},[(_vm.allSchemesView)?_c('PSOLink',{attrs:{"id":("item-" + _vm.listItems),"linkTo":{
            name: 'amendmentDetail',
            params: {
              amendmentNumber: _vm.row.amendmentNumber,
              schemeName: _vm.allSchemesTitle,
            },
            query: {
              schemeCode: _vm.row.schemes.length ? _vm.row.schemes[0].code : '',
            },
          },"showBackButton":false}},[_c('span',{attrs:{"slot":"highlight"},domProps:{"innerHTML":_vm._s(_vm.markedId)},slot:"highlight"})]):_c('PSOLink',{attrs:{"id":("item-" + _vm.listItems),"linkTo":{
            name: 'amendmentDetail',
            params: { amendmentNumber: _vm.row.amendmentNumber },
          },"showBackButton":false}},[_c('span',{attrs:{"slot":"highlight"},domProps:{"innerHTML":_vm._s(_vm.markedId)},slot:"highlight"})])],1)]),(_vm.allSchemesView)?_c('RplCol',{staticClass:"header__cell",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Planning scheme")]),_c('p',{staticClass:"header__cell-value"},[_vm._v(" "+_vm._s(_vm._f("schemesDisplayValue")(_vm.row.schemes))+" ")])]):_vm._e(),_c('RplCol',{staticClass:"header__cell",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Classification")]),_c('p',{staticClass:"header__cell-value"},[_vm._v(" "+_vm._s(_vm.row.classification.label)+" ")])]),_c('RplCol',{staticClass:"header__cell",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Status")]),_c('p',{staticClass:"header__cell-value"},[_vm._v(" "+_vm._s(_vm._f("capitalizeFirstLetter")(_vm.row.status))+" ")])]),_c('RplCol',{staticClass:"header__cell",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Last updated")]),_c('p',{staticClass:"header__cell-value"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.row.lastUpdated)))])]),_c('RplCol',{staticClass:"header__cell outcome",attrs:{"colsBp":_vm.psoColsBp,"role":"cell"}},[_c('p',{staticClass:"header__cell-label"},[_vm._v("Outcome")]),_c('p',{staticClass:"header__cell-value"},[_vm._v(_vm._s(_vm._f("outcomeToString")(_vm.row.outcome)))])])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__cell",attrs:{"role":"cell"}},[_c('p',{staticClass:"content__cell-label description"},[_vm._v("Brief description")]),_c('p',{staticClass:"content__cell-value"},[_c('TextHighlight',{attrs:{"queries":_vm.queryTerms,"highlightStyle":_vm.highlightStyle()}},[_vm._v(" "+_vm._s(_vm.row.description)+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }