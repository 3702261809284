










import Vue from 'vue';
import { Getter, Action } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import { OrdinanceReference, SelectedScheme } from '@/models';

import Loader from '@/components/shared/Loader.vue';
import Redirect from '@/components/shared/Redirect.vue';

@Component({
  components: {
    Loader,
    Redirect,
  },
})
export default class MapLookup extends Vue {
  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('redirectOrdinance', { namespace: 'schemes' })
  private redirectOrdinance!: OrdinanceReference;

  @Action('lookUpOrdinanceIdForMap', { namespace: 'schemes' })
  private lookUpOrdinanceIdForMap;

  get redirectRoute(): any {
    if (!this.redirectOrdinance) return null;

    const { ordinanceID, childOrdinanceID, semanticNumber } =
      this.redirectOrdinance || {};

    if (ordinanceID) {
      return {
        name: 'ordinanceId',
        params: {
          ordinanceID:
            semanticNumber && semanticNumber !== ''
              ? semanticNumber
              : ordinanceID,
        },
        hash: this.calculateHash(childOrdinanceID, semanticNumber),
      };
    }
    return {
      name: '404',
      params: { notFound: this.$route.params.schemeName },
      query: this.$route.query || {},
    };
  }

  private calculateHash(childOrdinanceID, semanticNumber) {
    if (semanticNumber && semanticNumber.length > 0) {
      return '';
    }

    if (childOrdinanceID) {
      return childOrdinanceID ? `#${childOrdinanceID}` : '';
    }

    return '';
  }

  mounted() {
    const { level, mapCode } = this.$route.query || {};
    const schemeCode = this.selectedScheme?.schemeID;

    this.lookUpOrdinanceIdForMap({
      schemeCode,
      mapCode,
      level,
    });
  }
}
