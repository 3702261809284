import { Configuration, getConfiguration } from './config';
import { Features, getFeatures } from './feature-toggle';

import { SchemesClient } from '../api/types';
import { schemesClient } from '../api';

const config: Promise<Configuration> = getConfiguration();
const features: Promise<Features> = getFeatures();

export interface ApiDeps {
  schemesBaseUrl: string;
  pdfGeneratorBaseUrl: string;
  schemesClient: SchemesClient;
}

const api: Promise<ApiDeps> = config
  .then(({ apiUrl, pdfUrl }) => ({
    schemesBaseUrl: apiUrl,
    schemesClient: schemesClient(apiUrl),
    pdfGeneratorBaseUrl: pdfUrl,
  }))
  .catch();

const mapUrl: Promise<string> = config.then((it) => it.mapUrl).catch();

const redirectEnabled: Promise<boolean> = config
  .then(({ redirectEnabled: redirect }) => redirect)
  .catch();

const disallowedSchemeList: Promise<string[]> = config
  .then(({ disallowedSchemeCodes: schemeList }) => schemeList)
  .catch();

const historicPdfUrl: Promise<string> = config
  .then(({ historicPdfBaseUrl }) => historicPdfBaseUrl)
  .catch();

const getRedirectionMappings = async (): Promise<Record<string, string>> => {
  const { planningSchemeRedirectMappings } = await config;
  return Object.entries(planningSchemeRedirectMappings).reduce(
    (group, [sourceScheme, targetScheme]) => {
      // eslint-disable-next-line no-param-reassign
      group[sourceScheme.toLowerCase().trim()] = targetScheme;
      return group;
    },
    {} as Record<string, string>,
  );
};

const amListUrl: Promise<string> = config.then((it) => it.amendmentListUrl);

const oldBaseUrl =
  'https://www.planning.vic.gov.au/schemes-and-amendments/browse-planning-scheme';

const browseAllSchemesUrl =
  'https://www.planning.vic.gov.au/schemes-and-amendments/browse-amendments';

export {
  api,
  mapUrl,
  browseAllSchemesUrl,
  historicPdfUrl,
  oldBaseUrl,
  redirectEnabled,
  features,
  disallowedSchemeList,
  amListUrl,
  getRedirectionMappings,
};
