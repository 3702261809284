import { Route } from 'vue-router';

const getSchemeTitleName = (route: Route): string | undefined => {
  switch (route.name) {
    case 'ordinanceSearch':
      return `Search ${route.params.schemeName}`;
    case 'ordinance':
    case 'ordinanceId':
    case 'maps':
    case 'docs':
    case 'amendments':
    case 'amendmentDetail':
    case 'histories':
    case 'scheme':
      return route.params.schemeName;
    case 'notFound':
    case '404':
      return 'Page Not Found';
    default:
      return undefined;
  }
};

const tabTitleName: Record<string, any> = {
  ordinance: 'Ordinance',
  ordinanceId: 'Ordinance',
  ordinanceSearch: 'Ordinance',
  maps: 'Maps',
  docs: 'Docs',
  amendments: 'Amendments',
  amendmentDetail: 'Amendments',
  histories: 'Histories',
};

const getPageTitle = (route: Route): string => {
  const appName = 'Planning Scheme';
  const schemeTitleName = getSchemeTitleName(route);
  if (schemeTitleName && tabTitleName[route.name!]) {
    return `${schemeTitleName} ${appName} - ${tabTitleName[route.name!]}`;
  }
  if (schemeTitleName) {
    return `${schemeTitleName} ${appName}`;
  }
  return appName;
};

export default getPageTitle;
