const findTablesIn = (page: Document, parentClassName: string) =>
  page.querySelectorAll<HTMLElement>(`.${parentClassName} table`);

const findHeadingRowsIn = (table: HTMLElement) =>
  table.querySelectorAll<HTMLElement>('table caption, table tr');

const findTableHeadingCellsIn = (headingRow: HTMLElement) =>
  headingRow.querySelectorAll<HTMLElement>('table tr th');

const makeElementSticky =
  (top: number) =>
  (element: HTMLElement): void => {
    // eslint-disable-next-line no-param-reassign
    element.style.position = 'sticky';
    // eslint-disable-next-line no-param-reassign
    element.style.top = `${top}px`;
  };

const makeHeadingSticky = (heading: HTMLElement, top: number): boolean => {
  if (heading.tagName === 'CAPTION') {
    makeElementSticky(top)(heading);
    return true;
  }
  const tableHeaders = findTableHeadingCellsIn(heading);
  tableHeaders.forEach(makeElementSticky(top));
  return tableHeaders.length > 0;
};

// captions and tr children sticky and give an appropriate 'top' offset
const makeHeadingElementSticky = (table: HTMLElement) => {
  let top = 0;
  findHeadingRowsIn(table).forEach((heading) => {
    const headingWasUpdated = makeHeadingSticky(heading, top);
    if (headingWasUpdated) {
      top += heading.getBoundingClientRect().height;
    }
  });
};

const makeTableFitWidth = (table: HTMLElement) => {
  if (
    table.getElementsByTagName('tbody')[0].getBoundingClientRect().width <
    table.getBoundingClientRect().width
  ) {
    // eslint-disable-next-line no-param-reassign
    table.style.width = 'auto';
  }
};

const updateTableStyling = (page: Document, parentClassName: string): void => {
  findTablesIn(page, parentClassName).forEach((table) => {
    makeHeadingElementSticky(table);
    makeTableFitWidth(table);
  });
};

// eslint-disable-next-line import/prefer-default-export
export { updateTableStyling };
