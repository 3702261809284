import { GroupedSchemes, OrdinanceSchemeDetails } from '@/models';
import { orderBy, groupBy } from 'lodash';

const groupAllSchemes = (
  allschemes: OrdinanceSchemeDetails[],
): GroupedSchemes =>
  groupBy(allschemes, (scheme) => scheme.title.toUpperCase().charAt(0));

export const groupedPlanningSchemes = (
  allschemes: OrdinanceSchemeDetails[],
): GroupedSchemes => {
  const sortedSchemes = orderBy(
    allschemes,
    (scheme) => scheme.title.toLowerCase(),
    'asc',
  );
  return groupAllSchemes(sortedSchemes);
};
