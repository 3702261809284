const NOT_STARTED: unique symbol = Symbol('NOT_STARTED');
const RUNNING: unique symbol = Symbol('RUNNING');
const COMPLETED: unique symbol = Symbol('COMPLETED');
const ERROR: unique symbol = Symbol('ERROR');

export const LoaderStates = {
  NOT_STARTED,
  RUNNING,
  COMPLETED,
  ERROR,
} as const;

export type LoaderState = typeof LoaderStates[keyof typeof LoaderStates];

export interface Loading {
  loaderState: LoaderState;
}
