

















import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import Tabs from '@/components/scheme/Tabs.vue';
import { SelectedScheme } from '@/models';
import Maps from '@/views/scheme/Maps.vue';

@Component({
  components: {
    Tabs,
    Maps,
  },
})
export default class Scheme extends Vue {
  @Action('fetchAlertBannerDetails', { namespace: 'alertBanner' })
  private fetchAlertBannerDetails;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('isAllSchemesView')
  private isAllSchemesView!: boolean;

  get allSchemesView(): boolean {
    return (
      this.isAllSchemesView &&
      ['amendments', 'amendmentDetail'].includes(`${this.$route?.name}`)
    );
  }

  created() {
    this.fetchAlertBannerDetails();
  }
}
