





























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PlanningSchemeVersion } from '@/models';
import PSOLink from '@/components/shared/PSOLink.vue';

@Component({
  components: {
    PSOLink,
  },
})
export default class HistoryListRow extends Vue {
  @Prop() row!: PlanningSchemeVersion;

  @Prop() searchTerm!: string;

  @Prop() hasPlanningSchemeColumn!: boolean;

  @Prop() planningSchemeCode!: string;

  @Prop() itemInList!: number;

  get markedId() {
    return this.searchAndMarkForSubString(this.searchTerm, this.row.id);
  }

  get planningScheme(): string {
    return this.row.planningScheme ? this.row.planningScheme.name : '';
  }

  get markedDesc() {
    if (this.row.description) {
      return this.searchAndMarkForSubString(
        this.searchTerm,
        this.row.description,
      );
    }
    return this.row.description;
  }

  searchAndMarkForSubString(searchTerm: string, text: string) {
    if (searchTerm && text) {
      const re = new RegExp(searchTerm, 'gi');
      const match = re.exec(text);

      if (match && match.length > 0) {
        const r = new RegExp(match[0], 'g');
        return text.replace(r, `<mark>${match[0]}</mark>`);
      }
    }

    return text;
  }
}
