







































































import Redirect from '@/components/shared/Redirect.vue';
import { RplCol, RplContainer, RplRow } from '@dpc-sdp/ripple-grid';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import AlertBanner from '@/components/shared/AlertBanner.vue';
import { api } from '@/config';
import {
  MultiSelectConfig,
  OrdinanceSchemeDetails,
  SelectOption,
  SelectedScheme,
} from '@/models';
import { RplDivider } from '@dpc-sdp/ripple-global';

import SearchResultList from '@/components/search/SearchResultList.vue';
import SearchSimpleComponent from '@/components/search/SearchSimple.vue';
import MultipleSelect from '@/components/shared/MultipleSelect.vue';
import { scrollToElement } from '@/helpers/scroll-to-element';

import ClearSearch from '@/components/shared/ClearSearch.vue';
import PSOLink from '@/components/shared/PSOLink.vue';
import { formatDateYmd } from '@/helpers';
import { extractTags, extractTitles } from '@/helpers/search-result-title';
import { MappedOrdinanceType } from '@/store/schemes/types';
import { RplIcon } from '@dpc-sdp/ripple-icon';

type NumberTransform = (x: number) => number;

@Component({
  name: 'SearchScheme',
  components: {
    AlertBanner,
    RplDivider,
    SearchSimpleComponent,
    SearchResultList,
    Redirect,
    RplContainer,
    RplRow,
    RplCol,
    RplIcon,
    MultipleSelect,
    ClearSearch,
    PSOLink,
  },
})
export default class SearchScheme extends Vue {
  @Prop() schemeName;

  @Getter('allSchemes')
  private allSchemes!: OrdinanceSchemeDetails[];

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('mappedOrdinanceTypes', { namespace: 'schemes' })
  private types!: MappedOrdinanceType[];

  public count = 0;

  public multiSelectConfig: MultiSelectConfig = {
    multiselect: true,
    placeholder: 'Select',
    showItems: 1,
    fieldId: 'select',
    inputName: null,
    label: '',
  };

  public multiSelectOrdinanceTypeConfig: MultiSelectConfig = {
    multiselect: true,
    placeholder: 'Select',
    showItems: 1,
    fieldId: 'ordinance-type-select',
    inputName: null,
    label: '',
  };

  public searchFormData;

  public formLoading;

  public result: string[] | null = null;

  public pager;

  public perPage;

  public locationHost;

  public text: string | null = '';

  public maxPerPage = 25;

  constructor() {
    super();
    this.formLoading = true;
    this.pager = null;
    this.perPage = null;
    this.count = 0;

    if (this.$store?.getters?.getCurrentPageGetter) {
      this.$store.dispatch('SET_CURRENTPAGE_ACTION', null);
    }
  }

  get schemeSelection() {
    return !!this.$route.query.schemes;
  }

  get ordinanceTypeOptions(): SelectOption[] {
    const options = this.types.map((item) => ({
      id: item.id,
      name: item.label,
      selected: this.addSelectedOrdinanceType(item.id),
      focussed: false,
      uuid: '',
      disabled: false,
    }));

    return [
      {
        selected: this.addSelectedOrdinanceType('all'),
        focussed: true,
        uuid: '',
        id: 'all',
        name: 'All',
        disabled: false,
      },
    ].concat(options);
  }

  get multiselectOptions(): SelectOption[] {
    const options = this.allSchemes
      .filter((z) => z.state.activeFlag)
      .map((item) => ({
        id: item.schemeID,
        name: item.title,
        selected: this.addSelected(item.schemeID),
        focussed: false,
        uuid: '',
        disabled: false,
      }));

    return [
      {
        selected: this.addSelected('all'),
        focussed: true,
        uuid: '',
        id: 'all',
        name: 'All',
        disabled: false,
      },
    ].concat(options);
  }

  get selectedSchemeExists() {
    return this.selectedScheme !== undefined;
  }

  get schemeLookupFailedUrl() {
    return `/404/${this.schemeName}`;
  }

  get searchQuery(): string {
    return (
      (Array.isArray(this.$route?.query?.q)
        ? this.$route?.query?.q[0]
        : this.$route?.query?.q) || ''
    );
  }

  resetFilters() {
    const { path } = this.$route;

    this.$router
      .replace({
        path,
        query: {
          schemes: this.selectedScheme.schemeID,
        },
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
  }

  pageSizeForTotalMatches: NumberTransform = (matchCount) =>
    Math.min(matchCount, this.maxPerPage);

  pagesForTotalMatches: NumberTransform = (matchCount) =>
    Math.ceil(matchCount / this.maxPerPage);

  public createRoute(schemeName: string, semanticNumber: string) {
    return {
      name: 'ordinanceId',
      params: {
        schemeName,
        ordinanceID: semanticNumber,
      },
    };
  }

  mapResult: (res: any, schemeName: string) => any[] = (res, schemeName) =>
    (res?.matches || []).map((x) => ({
      ...x,
      route: this.createRoute(schemeName, x?.semanticNumber),
      snippet: x?.snippet,
      gazettalDate: formatDateYmd(x?.gazettalDate),
      amendmentNumber: x?.amendmentNumber,
      tags: extractTags(x.title, x.type),
      titles: extractTitles(x.title, x.parentTitle),
    }));

  pagerForResult: (res: any, page?: number) => any = (res, page) => {
    if (res.matchCount > 0) {
      return {
        totalSteps: this.pagesForTotalMatches(res.matchCount),
        initialStep: page || 1,
        stepsAround: 2,
      };
    }
    return null;
  };

  public addSelected(schemeId) {
    let schemes;

    if (this.$route && this.$route.query) {
      if (typeof this.$route.query.schemes === 'string') {
        schemes = this.$route.query.schemes.split(',');
      } else {
        schemes = this.$route.query.schemes;
      }
      if (
        schemeId === 'all' &&
        schemes.length ===
          this.allSchemes.filter((z) => z.state.activeFlag).length
      ) {
        return true;
      }

      return schemes.includes(schemeId);
    }
    return false;
  }

  public addSelectedOrdinanceType(option) {
    let routeOrdinanceType;

    if (this.$route && this.$route.query && this.$route.query.ordinanceType) {
      if (typeof this.$route.query.ordinanceType === 'string') {
        routeOrdinanceType = this.$route.query.ordinanceType.split(',');
      } else {
        routeOrdinanceType = this.$route.query.ordinanceType;
      }
      if (option === 'all' && routeOrdinanceType.length === this.types.length) {
        return true;
      }

      return routeOrdinanceType.includes(option);
    }

    return false;
  }

  public triggerSearch(val): void {
    const { path } = this.$route;
    this.text = val || '';

    this.$router
      .replace({
        path,
        query: {
          ...this.$route.query,
          q: val,
        },
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
  }

  public triggerSearchByOrdinanceType(types) {
    const { path } = this.$route;

    this.$router
      .replace({
        path,
        query: {
          ...this.$route.query,
          ordinanceType: types,
          q: this.searchQuery,
        },
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
  }

  public triggerSearchByScheme(schemes) {
    const { path } = this.$route;

    this.$router
      .replace({
        path,
        query: {
          ...this.$route.query,
          schemes,
          q: this.searchQuery,
        },
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
  }

  public paginationChanged(page: number) {
    this.searchScheme(page);
    scrollToElement('.search-scheme');
  }

  public async searchScheme(page?: number): Promise<void> {
    this.formLoadingChanged(true);

    const options = this.createSearchOptions(page);

    const enrichedData = (
      await (await api).schemesClient.searchSchemes(options)
    ).data;

    this.result = this.mapResult(enrichedData, this.schemeName);
    this.pager = this.pagerForResult(enrichedData, page);
    if (this.pager) {
      this.perPage = this.pageSizeForTotalMatches(enrichedData.matchCount);
      this.count = enrichedData.matchCount;
    }
    this.formLoadingChanged(false);
  }

  private createSearchOptions(page): any {
    return {
      schemeId: this.$route.query.schemes,
      query: this.searchQuery,
      pageSize: 25,
      page,
      mappedOrdinanceType: this.$route.query.ordinanceType,
    };
  }

  public goBackOrToOrdinance() {
    if (window.history.length > 2) {
      this.$router.go(-1);
    } else {
      this.$router.push({ name: 'ordinance' });
    }
  }

  created() {
    this.text = Array.isArray(this.$route?.query?.q)
      ? this.$route?.query?.q[0]
      : this.$route?.query?.q || '';
  }

  @Watch('formLoading')
  formLoadingChanged(newVal: boolean) {
    this.formLoading = newVal;
  }

  @Watch('$store.state.scheme')
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange({ query }) {
    if (
      query &&
      query.q &&
      query.q !== '' &&
      query.schemes &&
      query.schemes !== ''
    ) {
      this.searchScheme(this.$store?.getters?.getCurrentPageGetter);
    } else {
      this.formLoadingChanged(false);
    }
  }
}
