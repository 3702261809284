











import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { OrdinanceContent, SelectedScheme } from '@/models';
import { HistoricalScheme } from '@/store/schemes/types';
import Ordinance from '@/components/scheme/Ordinance.vue';
import {
  historicPlanningSchemeTitle,
  selectedVersionTitle,
} from './convert-from-domain';

@Component({
  components: {
    Ordinance,
  },
})
export default class HistoricOrdinanceDetail extends Vue {
  @Prop()
  ordinanceID;

  @Getter('historicalScheme', { namespace: 'schemes' })
  private historicalScheme!: HistoricalScheme;

  @Getter('historicOrdinance', { namespace: 'schemes' })
  private historicOrdinance!: OrdinanceContent;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Action('fetchHistoricOrdinance', { namespace: 'schemes' })
  private fetchHistoricOrdinance;

  get ordinance(): OrdinanceContent {
    return this.historicOrdinance;
  }

  get ordinancePdfUrl(): string | undefined {
    return this.ordinance?.ordinancePdfUrl;
  }

  get versionId(): string {
    return selectedVersionTitle(this.historicalScheme);
  }

  get planningSchemeTitle(): string {
    return historicPlanningSchemeTitle(this.selectedScheme.title);
  }

  public scrollToOrdinanceContent(ordinanceID): any {
    const titlePadding = 55; // FIXME? Can we get rid of this?
    const el = document.getElementById(ordinanceID);

    if (el) {
      const ordinanceContentPosition = el.getBoundingClientRect().top;
      const currentScrollPosition =
        el?.ownerDocument?.defaultView?.pageYOffset || 0;
      const top = ordinanceContentPosition + currentScrollPosition;
      if (window.innerWidth < 768) {
        let height = 0;
        const actionButtons = document.querySelector(
          '.ordinance-actions-small.historical',
        ) as HTMLElement;

        if (actionButtons) height = actionButtons.offsetHeight;
        window.scrollTo({
          top: top - height - titlePadding,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({ top: top - titlePadding, behavior: 'smooth' });
      }
    }
  }

  private async loadOrdinanceAndScrollToTop(): Promise<void> {
    await this.fetchHistoricOrdinance({
      schemeId: this.selectedScheme?.schemeID,
      ordinanceID: this.ordinanceID,
    });

    this.scrollToOrdinanceContent(this.ordinanceID);
  }

  @Watch('ordinanceID')
  onUrlChange() {
    this.loadOrdinanceAndScrollToTop();
  }

  async mounted() {
    this.loadOrdinanceAndScrollToTop();
  }
}
