
















import Vue from 'vue';

import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { SearchAmendmentRequestOptions, SelectedScheme } from '@/models';
import { updateAmendmentSearchParams } from '@/helpers/search-params';
import PSODatePicker from '../shared/dates/PSODatePicker.vue';
import FilterSection from '../filter/FilterSection.vue';

@Component({
  components: {
    FilterSection,
    PSODatePicker,
  },
})
export default class DateFilter extends Vue {
  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('searchParams', { namespace: 'amendment' })
  private searchParams!: SearchAmendmentRequestOptions;

  get updatedFrom() {
    const { from } = this.searchParams?.filter || {};
    return from;
  }

  get updatedTo() {
    const { to } = this.searchParams?.filter || {};
    return to;
  }

  public submitFrom({ from }): void {
    const to = this.updatedTo;
    this.submit({
      from: from === '' ? undefined : from,
      to: to === '' ? undefined : to,
    });
  }

  public submitTo({ to }): void {
    const from = this.updatedFrom;
    this.submit({
      from: from === '' ? undefined : from,
      to: to === '' ? undefined : to,
    });
  }

  public submit({ to, from }): void {
    const { filter = {} } = this.searchParams;
    // without this if statement there is a double amendments search request
    // Not sure why, this is temporary fix until a frontend dev is available
    if (filter.to !== to || filter.from !== from) {
      updateAmendmentSearchParams(
        this.$router,
        this.selectedScheme.title,
      )({
        ...this.searchParams,
        page: undefined,
        filter: {
          ...filter,
          to,
          from,
        },
      });
    }
  }
}
