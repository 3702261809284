import { StageDetailsType } from './stages.model';

const { SingleDocument, DocumentList, Date, Text } = StageDetailsType;

export const exhibitionAndGazettalLabels = [
  {
    key: 'explanatoryReport',
    label: 'Explanatory report',
    type: SingleDocument,
  },
  {
    key: 'instructionSheet',
    label: 'Instruction sheet',
    type: SingleDocument,
  },
  {
    key: 'clausesAndSchedules',
    label: 'Clauses and schedules',
    type: DocumentList,
  },
  { key: 'mapSheets', label: 'Map sheets', type: DocumentList },
  {
    key: 'incorporatedDocuments',
    label: 'Incorporated documents',
    type: DocumentList,
  },
  {
    key: 'supportingDocuments',
    label:
      'Supporting documents, clauses and schedules in track changes format',
    type: DocumentList,
  },
];
export const panelHearingLabels = [
  {
    key: 'reportDueDate',
    label: 'Report due date',
    type: Date,
  },
  { key: 'chair', label: 'Panel chair', type: Text },
  { key: 'members', label: 'Panel members', type: Text },
  { key: 'report', label: 'Panel report', type: SingleDocument },
  { key: 'documents', label: 'Panel documents', type: DocumentList },
];
