import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { OrdinanceContent, SchemeState } from './types';

export const schemes: Module<SchemeState, RootState> = {
  namespaced: true,
  state: {
    focusedItem: '',
    sortParams: undefined,
    historicPDFSearchTerm: '',
    historicMapPDFSearchTerm: '',
    historicHTMLMapPDFSearchTerm: '',
    historicListSearchTerm: '',
    flatHistoricalOrdinances: [],
    pdfClausesColumns: ['Clause number', 'Clause title', 'Size'],
    mapClausesColumns: ['Map number', 'Map type', 'Size'],
    historicOrdinance: {} as OrdinanceContent,
    redirectOrdinance: undefined,
    schemeVersions: [],
    schemeVersionsFilters: {
      from: '',
      to: '',
      status: ['Gazetted', 'Finished'],
      outcome: [
        'Approved',
        'ApprovedWithChanges',
        'Revoked',
        'ApprovedByPlanningAuthority',
      ],
      type: [],
      classification: [],
    },
    scheme: {
      amendmentNumber: '',
      documentSize: 0,
      documentURL: '',
      gazettalDate: '',
      nav: [],
      schemeID: '',
      title: '',
    },
    historicalScheme: {
      amendmentNumber: '',
      clauses: [],
      documentSize: 0,
      documentURL: '',
      operationalDate: null,
      schemeID: '',
      title: '',
      isPdfVersion: false,
      legacyAmendmentNumbers: [''],
      type: '',
      isMapsPdfVersion: false,
    },
    simpleOrdinances: [],
    backToTopSignal: '',
    hasPlanningSchemeColumn: false,
    historicListClassificationOptions: [
      {
        key: 'Exhibited',
        label: 'Exhibited',
      },
      {
        key: 'Ministerial',
        label: 'Ministerial - S20(4)',
      },
      {
        key: 'Prescribed',
        label: 'Prescribed - S20(A)',
      },
    ],
    historicListTypeOptions: [
      {
        key: 'C',
        label: 'C - Council',
      },
      {
        key: 'GC',
        label: 'GC - Group of councils',
      },
      {
        key: 'V',
        label: 'V - Victoria Planning Provisions',
      },
      {
        key: 'VC',
        label:
          'VC - Victoria Planning Provisions and one or more planning schemes',
      },
      {
        key: 'L',
        label: 'L - Local',
      },
      {
        key: 'SL',
        label: 'SL - State and Local',
      },
      {
        key: 'AMA',
        label: 'AMA - Amalgamated planning schemes',
      },
      {
        key: 'NPS',
        label: 'NPS - New format planning schemes',
      },
    ],
    historicListOutcomeOptions: [
      {
        key: 'Approved',
        label: 'Approved',
      },
      {
        key: 'ApprovedByPlanningAuthority',
        label: 'Approved by planning authority',
      },
      {
        key: 'ApprovedWithChanges',
        label: 'Approved with changes',
      },
      {
        key: 'Revoked',
        label: 'Revoked',
      },
    ],
    historicListStatusOptions: [
      {
        key: 'Gazetted',
        label: 'Gazetted',
      },
      {
        key: 'Finished',
        label: 'Finished',
      },
    ],
    historicalListCurrentPage: 0,
    mappedOrdinanceTypes: [
      {
        id: 'MPS',
        label: 'Municipal planning strategy',
      },
      {
        id: 'LPPF',
        label: 'Local planning policy framework',
      },
      {
        id: 'PPF',
        label: 'Planning policy framework',
      },
      {
        id: 'Z',
        label: 'Zone',
      },
      {
        id: 'ZS',
        label: 'Zone schedule',
      },
      {
        id: 'OV',
        label: 'Overlay',
      },
      {
        id: 'OVS',
        label: 'Overlay schedule',
      },
      {
        id: 'PP',
        label: 'Particular provisions',
      },
      {
        id: 'PPS',
        label: 'Particular provisions schedule',
      },
      {
        id: 'GP',
        label: 'General provisions',
      },
      {
        id: 'GPS',
        label: 'General provisions schedule',
      },
      {
        id: 'OP',
        label: 'Operational provisions',
      },
      {
        id: 'OPS',
        label: 'Operational provisions schedule',
      },
    ],
  },
  getters,
  actions,
  mutations,
};
