import { outcomeToString } from './convert-to-ui';
import {
  StageStatus,
  StageStatusSymbol,
  StageHeaderData,
  OutcomeDetails,
  StageDetailsType,
} from './stages.model';

const getStatus: (OutcomeDetails) => StageStatusSymbol = (
  details?: OutcomeDetails,
) => (details?.outcome ? StageStatus.COMPLETE : StageStatus.PENDING);

const getData: (OutcomeDetails) => StageHeaderData[] = (
  details?: OutcomeDetails,
) =>
  details?.outcome
    ? [
        {
          label: 'Final decision',
          value: outcomeToString(details.outcome) || 'Unknown',
          type: StageDetailsType.Text,
        },
      ]
    : [
        {
          value: 'Pending',
          type: StageDetailsType.Text,
        },
      ];

export { getStatus, getData };
