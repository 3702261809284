






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PSOLink from '../shared/PSOLink.vue';

@Component({ components: { PSOLink } })
export default class GlobalScheme extends Vue {
  @Prop() historicalScheme;

  @Prop() version;

  @Prop() selectedScheme;

  get ordinanceLinkLabel(): string {
    return `${this.selectedScheme.title} ordinances`;
  }
}
