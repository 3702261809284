import {
  PlanningAuthorityDecision,
  PlanningAuthorityDecisionSymbol,
} from '@/models';
import { outcomeToString } from '../../convert-to-ui';

const decisionToString = (
  decision: PlanningAuthorityDecisionSymbol,
): string => {
  switch (decision) {
    case PlanningAuthorityDecision.Abandoned:
      return 'Abandoned';
    case PlanningAuthorityDecision.Adopted:
      return 'Adopted';
    case PlanningAuthorityDecision.Lapsed:
      return 'Lapsed';
    case PlanningAuthorityDecision.SplitIntoParts:
      return 'Split into parts';
    default:
      return 'Unknown';
  }
};

export { decisionToString, outcomeToString };
