










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import StageHeader from './StageHeader.vue';
import { panelHearingLabels } from './stage-details-labels';
import StageDetails from './StageDetails.vue';
import { panelHearingStatus, panelHearingData } from './panel-hearing';
import {
  getStageIcon,
  getStageDetails,
  mapOtherDocuments,
} from './stage-details';
import { StageStatus } from './stages.model';

@Component({
  components: {
    StageHeader,
    StageDetails,
  },
})
export default class PanelHearing extends Vue {
  @Prop() model;

  @Prop() amendmentStatus;

  get status() {
    return getStageIcon(panelHearingStatus(this.model, this.amendmentStatus));
  }

  get stageData() {
    return panelHearingData(this.model, this.amendmentStatus);
  }

  get panelHearingDetails() {
    return getStageDetails(this.model.panelHearing, panelHearingLabels);
  }

  get mappedOtherDocumentsDetails() {
    return mapOtherDocuments(this.model.panelHearing);
  }

  get allPanelHearingDetails() {
    return [...this.panelHearingDetails, ...this.mappedOtherDocumentsDetails];
  }

  get isPanelHearingCompleted() {
    return (
      panelHearingStatus(this.model, this.amendmentStatus) ===
      StageStatus.COMPLETE
    );
  }
}
