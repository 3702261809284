









































import Vue from 'vue';
import { Getter } from 'vuex-class';
import RadioGroup from '@/components/shared/RadioGroup.vue';
import { Component } from 'vue-property-decorator';
import { RplSelect } from '@dpc-sdp/ripple-form';
import { SearchAmendmentRequestOptions, SelectedScheme } from '@/models';
import { updateAmendmentSearchParams } from '../../helpers/search-params';

const namespace = 'amendment';

@Component({
  components: {
    RadioGroup,
    RplSelect,
  },
})
export default class SortAmendment extends Vue {
  public sortKey = 'lastUpdated';

  public sortOrder = 'desc';

  public dropdownValues = [
    {
      id: 'lastUpdated',
      name: 'Last updated',
    },
    {
      id: 'amendmentNumber',
      name: 'Amendment no.',
    },
  ];

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  private updateSearchParams;

  mounted() {
    this.updateSearchParams = updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme?.title,
    );
  }

  get selectedSortOrder() {
    return this.searchParams?.sortOrder || this.sortOrder;
  }

  get selectedSortKey() {
    return this.searchParams?.sortKey || this.sortKey;
  }

  public setSortOrder(sortOrder: string) {
    this.updateSearchParams({
      ...this.searchParams,
      sortOrder,
      page: undefined,
    });
  }

  public setSortKey(sortKey: string) {
    this.updateSearchParams({ ...this.searchParams, sortKey, page: undefined });
  }

  public reset() {
    this.updateSearchParams({
      ...this.searchParams,
      sortKey: undefined,
      sortOrder: undefined,
      page: undefined,
    });
  }
}
