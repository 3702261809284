import {
  AmendmentOutcomeSymbol,
  PlanningAuthorityDecisionSymbol,
} from '@/models';

const NOT_REQUIRED: unique symbol = Symbol('NOT_REQUIRED');
const PENDING: unique symbol = Symbol('PENDING');
const COMPLETE: unique symbol = Symbol('COMPLETE');

export const StageStatus = {
  COMPLETE,
  NOT_REQUIRED,
  PENDING,
} as const;

export type StageStatusSymbol = typeof StageStatus[keyof typeof StageStatus];

const Date: unique symbol = Symbol('Date');
const DocumentList: unique symbol = Symbol('DocumentList');
const SingleDocument: unique symbol = Symbol('SingleDocument');
const Text: unique symbol = Symbol('Text');

export const StageDetailsType = {
  Date,
  DocumentList,
  SingleDocument,
  Text,
} as const;

export type StageDetailsTypeSymbol =
  typeof StageDetailsType[keyof typeof StageDetailsType];

export interface StageHeaderData {
  label?: string;
  value?: PlanningAuthorityDecisionSymbol | string;
  type?: StageDetailsTypeSymbol;
}

export interface Stages {
  exhibitionRequired: boolean;
  panelRequested: boolean;
  exhibition?: ExhibitionStage;
  panelHearing?: PanelHearingStage;
  planningAuthority?: PlanningAuthorityStage;
  submittedDate?: string;
  decisionRequired: boolean;
  gazettal?: GazettalStage;
  amendmentStatus?: string | null;
}

export interface Document {
  filename: string;
  url: string;
  bytes: number;
}

export interface ExhibitionStage {
  startDate: string;
  endDate: string;
  submissionsEndDate?: string;
  explanatoryReport?: Document;
  instructionSheet?: Document;
  clausesAndSchedules: Array<Document>;
  mapSheets: Array<Document>;
  supportingDocuments: Array<Document>;
  incorporatedDocuments: Array<Document>;
  otherDocuments: Array<Document>;
}

export interface PanelHearingStage {
  reportReceivedDate: string;
  requestedDate: string;
  appointedDate: string;
  reportDueDate: string;
  chair?: string;
  members?: string;
  report: Document;
  documents: Array<Document>;
  otherDocuments: Array<Document>;
}

export interface PlanningAuthorityStage {
  decision: PlanningAuthorityDecisionSymbol;
  decisionDate: string;
}

export interface StageDetailsLabels {
  key: string;
  label: string;
  type: symbol;
}

export interface StageDetails {
  label: string;
  value: string | Document[];
  type: symbol;
}

export interface PlanningAuthorityStatuses {
  outcome?: AmendmentOutcomeSymbol;
  status: string;
}

export interface PlanningAuthorityDetails {
  stages: Stages;
  statuses: PlanningAuthorityStatuses;
}

export interface AssessmentParams {
  status: string;
  outcome?: AmendmentOutcomeSymbol;
  decisionRequired: boolean;
  submittedDate?: string;
}

export interface OutcomeDetails {
  outcome?: AmendmentOutcomeSymbol;
}

export interface GazettalDetails {
  gazettal?: GazettalStage;
  status: string;
}

export interface GazettalStage {
  date: string;
  operationalDate: string;
  explanatoryReport?: Document;
  instructionSheet?: Document;
  clausesAndSchedules: Array<Document>;
  mapSheets: Array<Document>;
  supportingDocuments: Array<Document>;
  incorporatedDocuments: Array<Document>;
  otherDocuments: Array<Document>;
}
