



































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplTabs from '@dpc-sdp/ripple-tabs';
import RplDataTable from '@dpc-sdp/ripple-data-table';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';

import PSOLink from '@/components/shared/PSOLink.vue';

import { HistoricalScheme } from '@/store/schemes/types';
import { SelectedScheme, Tab } from '@/models';
import { selectedVersionTitle } from './convert-from-domain';

@Component({
  components: {
    PSOLink,
    RplAlertBase,
    RplCol,
    RplRow,
    RplDataTable,
    RplTabs,
  },
})
export default class HistoricPDF extends Vue {
  public activeTab = 'pdfView';

  @Prop() previousRoute!: Location;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('historicalScheme', { namespace: 'schemes' })
  public historicalScheme!: HistoricalScheme;

  get selectedVersionTitle() {
    return selectedVersionTitle(this.historicalScheme);
  }

  get planningSchemeTitle(): string {
    return this.historicalScheme.title !== 'Victoria Planning Provisions'
      ? `${this.historicalScheme.title} historical planning scheme records`
      : `${this.historicalScheme.title} historical records`;
  }

  get tabs(): Tab[] {
    if (this.selectedScheme.schemeID.toLowerCase() === 'vpp') {
      return [
        {
          label: 'Ordinance view',
          key: 'pdfView',
          icon: 'list',
        },
      ];
    }

    return [
      {
        label: 'Ordinance view',
        key: 'pdfView',
        icon: 'list',
      },
      {
        label: 'Map view',
        key: 'mapView',
        icon: 'map_marker',
      },
    ];
  }

  public switchTab(tab) {
    if (this.activeTab === tab) return;

    if (tab === 'mapView') {
      this.$router.push({
        name: 'historicMapTab',
      });
    }
    if (tab === 'pdfView') {
      this.$router.push({
        name: 'historicPDFTab',
      });
    }
    this.activeTab = tab;
  }

  @Watch('$route.name', { immediate: true, deep: false })
  onUrlChange(routeName) {
    if (routeName === 'historicMapTab') {
      this.activeTab = 'mapView';
    }
  }
}
