import {
  filterByLegacyAmendmentId,
  filterHistories,
  filterHistoriesOutcomeStatusAndType,
  sortByLocaleCompare,
  sortByOperationalDate,
} from '@/components/histories/filter-params';
import { inDateRange, toDateOrNull } from '@/helpers/sortable';

import { GetterTree } from 'vuex';
import {
  AmendmentTypeFilterOptions,
  FilterOptions,
  HistoricalScheme,
  MapClause,
  MappedOrdinanceType,
  Navigation,
  OrdinanceContent,
  OrdinanceReference,
  PdfClause,
  PlanningSchemeVersion,
  SchemeState,
  SchemeStructure,
  SchemeVersionDateRange,
  SimpleOrdinance,
} from './types';

import { RootState } from '../types';

export const getters: GetterTree<SchemeState, RootState> = {
  focusedItem(state) {
    return state.focusedItem;
  },
  schemeId(state: any): string {
    return state?.scheme?.schemeID;
  },
  scheme({ scheme }: any): SchemeStructure {
    return scheme;
  },
  schemeNavigation({ scheme }): Navigation[] | undefined {
    return scheme?.nav;
  },
  historicOrdinance(state: any): OrdinanceContent {
    return state.historicOrdinance;
  },
  schemeVersions({
    schemeVersions,
    historicListSearchTerm,
    schemeVersionsFilters,
    sortParams,
  }: SchemeState): PlanningSchemeVersion[] {
    const { outcome, status, type, to, from, classification } =
      schemeVersionsFilters;
    const dateRangeChecker = inDateRange(toDateOrNull(from), toDateOrNull(to));

    return schemeVersions
      .filter(
        (item) =>
          (filterByLegacyAmendmentId(
            item.legacyAmendmentNumbers,
            item.id,
            historicListSearchTerm,
          ) ||
            filterHistories(item.description, historicListSearchTerm)) &&
          filterHistoriesOutcomeStatusAndType(item.status, status) &&
          filterHistoriesOutcomeStatusAndType(item.type, type) &&
          filterHistoriesOutcomeStatusAndType(item.outcome, outcome) &&
          filterHistoriesOutcomeStatusAndType(
            item.classification.key,
            classification,
          ),
      )
      .filter((x) => {
        const date = toDateOrNull(x.operationalDate as any);
        return date === null ? !(from || to) : dateRangeChecker(date);
      })
      .sort((a, b) => {
        if (sortParams) {
          if (sortParams.sortKey === 'amendmentNumber') {
            if (a.id && b.id) {
              return sortByLocaleCompare(sortParams.sortOrder, b.id, a.id);
            }
            return 0;
          }
          if (sortParams.sortKey === 'classification') {
            if (a.classification.key && b.classification.key) {
              return sortByLocaleCompare(
                sortParams.sortOrder,
                b.classification.key,
                a.classification.key,
              );
            }
            return 0;
          }
          if (sortParams.sortKey === 'operationalDate') {
            if (a.operationalDate && b.operationalDate) {
              return sortByOperationalDate(
                sortParams.sortOrder,
                a.operationalDate,
                b.operationalDate,
              );
            }
            return 0;
          }
          if (sortParams.sortKey === 'planningScheme') {
            if (a.planningScheme?.name && b.planningScheme?.name) {
              return sortByLocaleCompare(
                sortParams.sortOrder,
                a.planningScheme?.name,
                b.planningScheme?.name,
              );
            }
            return 0;
          }
          return 0;
        }
        return 0;
      });
  },
  historicalScheme(state: any): HistoricalScheme[] {
    return state.historicalScheme;
  },
  schemeTitle(state: any): string {
    return state?.scheme?.title;
  },
  redirectOrdinance(state: any): OrdinanceReference {
    return state.redirectOrdinance;
  },
  getSimpleOrdinances(state: any): SimpleOrdinance[] {
    return state.simpleOrdinances;
  },
  getBackToTopSignal(state: any): boolean {
    return state.backToTopSignal;
  },
  schemeVersionsFilters(state: any): SchemeVersionDateRange {
    return state.schemeVersionsFilters;
  },
  pdfClausesTableHeaders(state: any): string[] {
    return state.pdfClausesColumns;
  },
  pdfClausesTableItems(state: any): PdfClause[] {
    return state.historicalScheme.pdfClauses;
  },
  mapClausesTableRows(state: any): MapClause[] {
    return state.historicalScheme.mapClauses || [];
  },
  mapClausesTableHeaders(state: any): string[] {
    return state.mapClausesColumns;
  },
  isHistoricPDF(state: any): boolean {
    return state.historicalScheme.isPdfVersion;
  },
  isHistoricPDFMaps(state: any): boolean {
    return state.historicalScheme.isMapsPdfVersion;
  },
  historicPDFSearchTerm(state: any): string {
    return state.historicPDFSearchTerm;
  },
  historicMapPDFSearchTerm(state: any): string {
    return state.historicMapPDFSearchTerm;
  },
  historicHTMLMapPDFSearchTerm(state: any): string {
    return state.historicHTMLMapPDFSearchTerm;
  },
  historicListSearchTerm(state: any): string {
    return state.historicListSearchTerm;
  },
  getFlatHistoricalOrdinances(state) {
    return state.flatHistoricalOrdinances;
  },
  sortParams(state) {
    return state.sortParams;
  },
  hasPlanningSchemeColumn(state) {
    return state.hasPlanningSchemeColumn;
  },
  selectedHistoricListOutcomeOptions(state): string[] | null {
    return state.schemeVersionsFilters.outcome;
  },
  selectedHistoricListTypeOptions(state): string[] | null {
    return state.schemeVersionsFilters.type;
  },
  selectedHistoricListStatusOptions(state): string[] | null {
    return state.schemeVersionsFilters.status;
  },
  selectedHistoricClassificationOptions(state): string[] | null {
    return state.schemeVersionsFilters.classification;
  },
  historicListOutcomeOptions(state): FilterOptions[] {
    return state.historicListOutcomeOptions;
  },
  historicListStatusOptions(state): FilterOptions[] {
    return state.historicListStatusOptions;
  },
  historicListTypeOptions(state): AmendmentTypeFilterOptions[] | null {
    return state.historicListTypeOptions;
  },
  historicListClassificationOptions(
    state,
  ): AmendmentTypeFilterOptions[] | null {
    return state.historicListClassificationOptions;
  },
  historicalListCurrentPage(state): number {
    return state.historicalListCurrentPage;
  },
  mappedOrdinanceTypes(state): MappedOrdinanceType[] {
    return state.mappedOrdinanceTypes;
  },
};
