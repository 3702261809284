import { InitialStages, Stages } from './amendment.model';

const Abandoned: unique symbol = Symbol('Abandoned');
const Approved: unique symbol = Symbol('Approved');
const ApprovedByPlanningAuthority: unique symbol = Symbol(
  'ApprovedByPlanningAuthority',
);
const ApprovedWithChanges: unique symbol = Symbol('ApprovedWithChanges');
const ATSMigratedUnspecified: unique symbol = Symbol('ATSMigratedUnspecified');
const AuthorisationRefused: unique symbol = Symbol('AuthorisationRefused');
const Lapsed: unique symbol = Symbol('Lapsed');
const Refused: unique symbol = Symbol('Refused');
const Revoked: unique symbol = Symbol('Revoked');
const RevokingComplete: unique symbol = Symbol('RevokingComplete');
const SplitParent: unique symbol = Symbol('SplitParent');
const Withdrawn: unique symbol = Symbol('Withdrawn');
const Unknown: unique symbol = Symbol('Unknown');

export const AmendmentOutcome = {
  Abandoned,
  Approved,
  ApprovedByPlanningAuthority,
  ApprovedWithChanges,
  ATSMigratedUnspecified,
  AuthorisationRefused,
  Lapsed,
  Refused,
  Revoked,
  RevokingComplete,
  SplitParent,
  Withdrawn,
  Unknown,
} as const;

export type AmendmentOutcomeSymbol =
  typeof AmendmentOutcome[keyof typeof AmendmentOutcome];

export interface Scheme {
  code: string;
  name: string;
}

export interface Pager {
  initialStep: number;
  stepsAround: number;
  totalSteps: number;
}

export interface Amendment {
  schemes: Scheme[];
  amendmentNumber: string;
  description: string;
  outcome: AmendmentOutcomeSymbol;
  status: string;
  statusDate?: string; // FIXME: remove from API
  lastUpdated: string; // FIXME: remove when decoupled over DRY
  classification?: string;
}

export interface FilterQuery {
  status?: string;
  outcome?: string;
  after?: string;
  before?: string;
}

export interface SearchAmendmentFilter {
  status?: string[];
  outcome?: string[];
  amendmentType?: string[];
  scheme?: string[];
  from?: string;
  to?: string;
  classification?: string;
  dfp?: boolean;
}

export interface SearchAmendmentRequestOptions {
  limit?: string;
  offset?: number;
  page?: string;
  q?: string;
  schemeCode?: string;
  sortKey?: string;
  sortOrder?: string;
  filter?: SearchAmendmentFilter;
}

export interface HistoryRequestOptions {
  version?: string | undefined;
  from?: string | undefined;
  to?: string | undefined;
  sortOrder?: string;
  sortKey?: string;
  'filter.status'?: string[];
  'filter.outcome'?: string[];
}

export interface SearchAmendmentResponse {
  total: number;
  results: Amendment[];
}

export interface PlanningAuthorityContact {
  authority: string;
  fullAddress?: string;
  phone?: string;
}

export interface AmendmentDetail {
  amendmentNumber: string;
  description: string;
  status: string;
  schemes: Scheme[];
  statusDate?: string; // FIXME: remove from API
  lastUpdated?: string; // FIXME: remove when decoupled over DRY
  environmentalEffectsStatementNeeded?: boolean;
  planningAuthorityContact: PlanningAuthorityContact;
  stages: Stages;
  outcome?: AmendmentOutcomeSymbol;
}

export interface InitialAmendmentDetail {
  amendmentNumber: string;
  description: string;
  status: string;
  schemes: Scheme[];
  environmentalEffectsStatementNeeded?: boolean;
  planningAuthorityContact: PlanningAuthorityContact;
  stages: InitialStages;
  outcome?: string;
  statusDate?: string; // FIXME: remove from API
  lastUpdated?: string; // FIXME: remove when decoupled over DRY
}

export interface AmendmentDetailsParams {
  amendmentNumber: string;
  schemeCode: string;
}

export interface PlanningScheme {
  schemeID: string;
  title: string;
  state: State;
  amendmentNumber: string;
  gazettalDate: string;
}

export interface PlanningSchemeBySchemeIdClauses {
  metadata: PlanningSchemeBySchemeIdClausesMetadata[];
  orderIndex: number;
  ordinanceID: string;
  ordinanceLevel: string;
  ordinanceType: string;
  subClauses: Array<any>;
  title: string;
}

export interface PlanningSchemeBySchemeIdClausesMetadata {
  name: string;
  value: string;
}

export interface ParentOrdinance {
  title: string;
  ordinanceID: string;
  ordinanceType: string;
}

export interface State {
  activeFlag: boolean;
  alternateUri?: string;
}

export interface PlanningSchemeResultBySchemeIdAndOrdinanceId {
  content?: string;
  metadata?: PlanningSchemeBySchemeIdClausesMetadata[];
  orderIndex?: number;
  ordinanceID: string;
  ordinanceType?: string;
  planningScheme?: PlanningSchemeResultBySchemeIdAndOrdinanceIdPlanningScheme;
  status?: string;
  title: string;
  amendmentNumber?: string;
  gazettalDate?: string;
  parentOrdinance?: ParentOrdinance;
  message?: string;
  state?: State;
  ordinanceSections?: PlanningSchemeResultBySchemeIdAndOrdinanceId[];
}

// FIXME: merge usage with
interface PlanningSchemeResultBySchemeIdAndOrdinanceIdPlanningScheme {
  amendmentNumber: string;
  gazettalDate: string;
  schemeID: string;
  title: string;
}

export interface SearchSchemeRequestOptions {
  query?: string;
  pageSize?: number;
  page?: number;
  schemeId?: string;
}

export interface OrdinanceInfoSection {
  category: string;
  date: string;
  amendment: string;
}

export interface OrdinanceContentActiveState {
  active: boolean;
  altUri?: string;
}

export interface OrdinanceContent {
  id: string;
  number: string;
  gazettalDate?: Date;
  amendmentNumber: string;
  title: string;
  content: string;
  sections?: OrdinanceContent[];
  state?: OrdinanceContentActiveState;
  ordinancePdfUrl?: string;
}

export interface OrdinanceNumberParams {
  schemeCode?: string;
  clause?: string;
  subClause?: string;
  schedule?: string;
  section?: string;
  subSection?: string;
}

export interface SelectedScheme {
  schemeID: string;
  title: string;
  amendmentNumber?: string;
  gazettalDate?: string;
}

export interface OrdinanceSchemeDetails {
  amendmentNumber: string;
  gazettalDate: string;
  schemeID: string;
  state: { activeFlag: boolean };
  title: string;
}

export interface OrdinanceReference {
  ordinanceID: string;
  childOrdinanceID?: string;
  semanticNumber?: string;
}

export interface SchemeVersionDateRange {
  from: string;
  to: string;
  'filter.status': [];
  'filter.outcome': [];
}

export interface GroupedSchemes {
  [key: string]: OrdinanceSchemeDetails[];
}

export interface PlanningSchemeVersion {
  id: string;
  operationalDate?: Date;
  description?: string;
  latest: boolean;
  planningScheme?: Scheme;
  status?: string;
  outcome?: string;
  gazettalDate?: Date;
  legacyAmendmentNumbers: string[];
  type: string;
  classification: {
    key: string;
    label: string;
  };
}

export interface SchemeStructure {
  schemeID: string;
  title: string;
  amendmentNumber?: string;
  gazettalDate?: string;
  documentURL?: string;
  documentSize?: number;
  nav?: Navigation[];
  state?: SchemeActiveState;
}

export interface Navigation {
  children?: Navigation[];
  ordinanceID: string;
  ordinanceLevel: string;
  ordinanceMapCode: string;
  ordinanceType: string;
  semanticNumber: string;
  title: string;
}

export interface SchemeActiveState {
  active: boolean;
  altUri: string;
}

export interface SchemeAmendmentAndGazettalDate {
  amendmentNumber: string | undefined;
  gazettalDate: string | undefined;
}

export interface CheckboxModel {
  value: string;
  key: string;
  label: string;
  selected?: boolean;
}
