import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

const formatSchemeGazettalDate = (value) => {
  dayjs.extend(customParseFormat);

  let formatted = 'Date not available';

  if (!value) return formatted;

  const dateObj = dayjs(value, 'DD/MM/YYYY');

  if (dateObj.isValid()) {
    formatted = dateObj.format('dddd DD MMMM YYYY');
  }

  return formatted;
};

const formatDate = (value) => {
  let formatted = 'Date not available';
  if (!value) return formatted;

  const dateObj = dayjs(value);
  if (dateObj.isValid()) {
    formatted = dateObj.format('dddd DD MMMM YYYY');
  }

  return formatted;
};

const boolToYesNo = (value?: boolean) => {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  return '';
};

const formatBytes = (bytes: number | undefined, decimals = 2): string => {
  if (typeof bytes !== 'number' || bytes < 0) return 'Unknown size';
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const capitalizeFirstLetter = (text: string | null | undefined): string => {
  if (text === undefined || text === null) {
    return '';
  }

  const lowerCase = text.toLowerCase();
  const capitalizeFirst =
    lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  return capitalizeFirst;
};

export {
  boolToYesNo,
  capitalizeFirstLetter,
  formatBytes,
  formatDate,
  formatSchemeGazettalDate,
};
