var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title-container"},[(_vm.allSchemes && _vm.pageTitle)?_c('div',[_c('h1',{staticClass:"page-title",class:['page-title', { allSchemes: _vm.allSchemes }]},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('div',{staticClass:"page-subtitle"},[_vm._v(_vm._s(_vm.pageSubtitle))])]):_c('div',[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.pageTitle)+" "+_vm._s(_vm.pageSubtitle))]),(
        _vm.$route.name !== 'ordinanceSearch' &&
        _vm.$route.params.schemeName !== 'all schemes' &&
        _vm.schemeAmendmentAndDate.amendmentNumber
      )?_c('div',{staticClass:"updated"},[_vm._v(" Planning scheme last updated by "),_c('PSOLink',{attrs:{"linkTo":{
          name: 'amendmentDetail',
          params: {
            amendmentNumber: _vm.schemeAmendmentAndDate.amendmentNumber,
            schemeName: _vm.selectedScheme.title,
          },
        },"text":_vm.schemeAmendmentAndDate.amendmentNumber,"showBackButton":false}}),_c('span',[_vm._v(" on ")]),_vm._v(_vm._s(_vm.formattedDate)+" ")],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }