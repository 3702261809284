import { render, staticRenderFns } from "./DocumentsSidebar.vue?vue&type=template&id=f96e940a&scoped=true&"
import script from "./DocumentsSidebar.vue?vue&type=script&lang=ts&"
export * from "./DocumentsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentsSidebar.vue?vue&type=style&index=0&id=f96e940a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f96e940a",
  null
  
)

export default component.exports