import {
  Stages,
  StageHeaderData,
  StageStatus,
  StageStatusSymbol,
  StageDetailsType,
} from './stages.model';

export const getExhibitionStatus = (
  stages: Stages = {
    exhibitionRequired: false,
    panelRequested: false,
    decisionRequired: false,
  },
  now: () => Date = () => new Date(),
): StageStatusSymbol => {
  const { exhibitionRequired, exhibition } = stages;
  if (!exhibitionRequired) {
    return StageStatus.NOT_REQUIRED;
  }

  if (exhibition && new Date(exhibition.endDate) > now()) {
    return StageStatus.PENDING;
  }

  return StageStatus.COMPLETE;
};

export const exhibitionStartData = (
  model: Stages = {
    exhibitionRequired: false,
    panelRequested: false,
    decisionRequired: false,
  },
  now: () => Date = () => new Date(),
): StageHeaderData[] => {
  const stage = getExhibitionStatus(model, now);
  switch (stage) {
    case StageStatus.NOT_REQUIRED:
      return [{ value: 'Not required' }];
    default:
      return [
        {
          label: 'Exhibition start date',
          value: model?.exhibition?.startDate,
          type: StageDetailsType.Date,
        },
      ];
  }
};

export const exhibitionEndData = (
  model: Stages = {
    exhibitionRequired: false,
    panelRequested: false,
    decisionRequired: false,
  },
  now: () => Date = () => new Date(),
): StageHeaderData[] => {
  const stage = getExhibitionStatus(model, now);
  switch (stage) {
    case StageStatus.NOT_REQUIRED:
      return [{ value: 'Not required' }];
    default:
      return [
        {
          label: 'Submissions due by',
          value: model?.exhibition?.submissionsEndDate,
          type: StageDetailsType.Date,
        },
        {
          label: 'Exhibition end date',
          value: model?.exhibition?.endDate,
          type: StageDetailsType.Date,
        },
      ];
  }
};
