











import Vue from 'vue';

import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FilterSection from '@/components/filter/FilterSection.vue';
import MultipleSelectMixin from '@/mixins/MultipleSelectMixin.vue';
import MultipleSelect from '../shared/MultipleSelect.vue';

@Component({
  mixins: [MultipleSelectMixin],
  components: {
    FilterSection,
    MultipleSelect,
  },
})
export default class DocumentsStatusFilter extends Vue {
  @Getter('getStatusOptions', { namespace: 'incorporatedDocument' })
  private options!: string[];

  @Getter('getSelectedStatus', { namespace: 'incorporatedDocument' })
  private selected!: string[];

  public title = 'Status';

  public toggleCheckbox(options: string) {
    this.$emit('on-status-change', options);
  }
}
