import { api } from '@/config';
import { logger } from '@/helpers';
import { featureEnabled } from '@/helpers/feature-toggles';
import { LoaderStates } from '@/store/loading/types';
import {
  convertHistoricalSchemeVersion,
  convertHistoricalSchemeVersionMapClauses,
  convertLegacyOrdinanceContent,
  convertOrdinanceContent,
  convertSchemeVersions,
  convertToFlatHistoricalClauses,
  convertToOrdinanceIndexDetails,
  extractOrdinanceParams,
  isSemanticOrdinanceNumber,
} from '@/store/schemes/convert-to-domain';
import { ActionTree } from 'vuex';

import { RootState } from '../types';
import {
  OrdinanceContent,
  OrdinanceError,
  OrdinanceNumberParams,
  SchemeState,
} from './types';

export const actions: ActionTree<SchemeState, RootState> = {
  setFocusedItem({ commit }, payload) {
    commit('setFocused', payload);
  },
  async fetchSchemeDetail({
    commit,
    rootGetters: { selectedScheme },
  }): Promise<any> {
    const client = (await api).schemesClient;
    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    try {
      const { schemeID } = selectedScheme;
      const scheme = (await client.fetchScheme(schemeID)).data;
      commit('setScheme', scheme);
      commit('setSimpleOrdinances', convertToOrdinanceIndexDetails(scheme.nav));
      commit('loading/setState', LoaderStates.COMPLETED, { root: true });
    } catch (e) {
      logger.error('Error while fetching scheme detail: ', e.message);
      commit('loading/setState', LoaderStates.ERROR, { root: true });
    }
  },
  async getSchemeVersions({ commit }, { schemeCode, from, to }): Promise<void> {
    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    commit('setFilterParams', { from, to });
    const client = (await api).schemesClient;

    try {
      const res = await client.fetchSchemeHistory(schemeCode, '', '');

      if (res.status === 200) {
        commit('setSchemeVersions', {
          versions: convertSchemeVersions(res.data.versions),
          hasPlanningScheme: res.data.hasPlanningScheme || false,
        });
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    } catch (e) {
      commit('setSchemeVersions', undefined);
      commit('loading/setState', LoaderStates.ERROR, { root: true });
      logger.error('Error while fetching ordinance', e.message);
    }
  },
  async fetchHistoricalScheme(
    { commit, rootGetters: { selectedScheme } },
    version,
  ): Promise<void> {
    const { schemesClient } = await api;

    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    try {
      const { schemeID } = selectedScheme;
      const { data } = await schemesClient.fetchSchemeVersion(
        schemeID,
        version,
      );
      commit('setHistoricalScheme', convertHistoricalSchemeVersion(data));
      commit(
        'setHistoricalFlatOrdinances',
        convertToFlatHistoricalClauses(data.clauses),
      );
      commit('loading/setState', LoaderStates.COMPLETED, { root: true });
    } catch (e) {
      commit('setHistoricalScheme', {});
      logger.error('Error while fetching historical ordinance: ', e.message);
      if (e?.response?.status >= 500) {
        commit('loading/setState', LoaderStates.ERROR, { root: true });
      } else {
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    }
  },
  async fetchHistoricalSchemeMapData(
    { commit, rootGetters, state },
    version: string,
  ): Promise<void> {
    const { schemesClient } = await api;
    try {
      const { selectedScheme } = rootGetters;
      const { schemeID } = selectedScheme;

      const { data } = await schemesClient.fetchSchemeVersionVersionMapData(
        schemeID,
        version,
      );
      const mapClauses = convertHistoricalSchemeVersionMapClauses(data);
      const updatedState = {
        ...state.historicalScheme,
        isMapsPdfVersion: data.isPdfVersion,
        mapClauses,
      };
      commit('setHistoricalScheme', updatedState);
    } catch (e) {
      logger.error(
        'Error while fetching historical ordinance map pdf: ',
        e.message,
      );
      if (e?.response?.status >= 500) {
        commit('loading/setState', LoaderStates.ERROR, { root: true });
      } else {
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    }
  },
  async lookUpOrdinanceIdForMap(
    { commit },
    { schemeCode, level, mapCode },
  ): Promise<void> {
    const client = (await api).schemesClient;
    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    try {
      const ordinanceRef = schemeCode
        ? (await client.fetchOrdinanceIdForMap(schemeCode, level, mapCode)).data
        : {};
      commit('setRedirectOrdinance', ordinanceRef);
      commit('loading/setState', LoaderStates.COMPLETED, { root: true });
    } catch (e) {
      logger.error(
        'Error while looking for ordinance in map look up: ',
        e.message,
      );
      if (e?.response?.status >= 500) {
        commit('loading/setState', LoaderStates.ERROR, { root: true });
      } else {
        commit('setRedirectOrdinance', {});
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    }
  },
  async getOrdinanceById(
    { commit },
    { schemeId, ordinanceID },
  ): Promise<OrdinanceContent | OrdinanceError> {
    const client = (await api).schemesClient;
    const apiUrl = (await api).schemesBaseUrl;
    const pdfUrl = (await api).pdfGeneratorBaseUrl;

    try {
      const res = await client.fetchOrdinance(schemeId, ordinanceID);
      if (res.status === 200) {
        return convertLegacyOrdinanceContent(
          res.data,
          schemeId,
          apiUrl,
          pdfUrl,
        );
      }
      return {
        id: '',
        number: '',
        amendmentNumber: '',
        title: '',
        content: '',
      };
    } catch (e) {
      logger.error('Error while fetching ordinance', e.message);
      commit('error', undefined);
      return { error: true, id: ordinanceID, message: e.message };
    }
  },
  async getOrdinanceByNumber(
    { commit },
    ordinanceParams: OrdinanceNumberParams,
  ): Promise<OrdinanceContent | OrdinanceError> {
    const client = (await api).schemesClient;
    const apiUrl = (await api).schemesBaseUrl;
    const pdfUrl = (await api).pdfGeneratorBaseUrl;

    try {
      const res = await client.fetchOrdinanceByNumber(ordinanceParams);

      return convertOrdinanceContent(
        res.data,
        ordinanceParams.schemeCode,
        apiUrl,
        pdfUrl,
      );
    } catch (e) {
      commit('error', e.message);
      logger.error('Error while fetching ordinance by number', e.message);
      return {
        error: true,
        id: '',
        message: e.message,
      };
    }
  },
  async getOrdinance(
    { dispatch },
    { schemeId, ordinanceID },
  ): Promise<OrdinanceContent | OrdinanceError> {
    if (
      (await featureEnabled('ordinance.semanticUrls.available')) &&
      isSemanticOrdinanceNumber(ordinanceID)
    ) {
      const ordinanceParams: OrdinanceNumberParams =
        extractOrdinanceParams(ordinanceID);
      ordinanceParams.schemeCode = schemeId;
      return dispatch('getOrdinanceByNumber', ordinanceParams, ordinanceID);
    }
    return dispatch('getOrdinanceById', {
      schemeId,
      ordinanceID,
    });
  },
  async fetchHistoricOrdinance(
    { commit },
    { schemeId, ordinanceID },
  ): Promise<void> {
    const client = (await api).schemesClient;
    const apiUrl = (await api).schemesBaseUrl;
    const pdfUrl = (await api).pdfGeneratorBaseUrl;

    try {
      const res = await client.fetchHistoricOrdinance(schemeId, ordinanceID);
      if (res.status === 200) {
        commit(
          'setHistoricOrdinance',
          convertOrdinanceContent(res.data, schemeId, apiUrl, pdfUrl, true),
        );
      }
    } catch (e) {
      commit('setHistoricOrdinance', { error: true });
      logger.error('Error while fetching historic ordinance', e.message);
    }
  },
  async setBackToTopSignal({ commit }, payload): Promise<any> {
    await commit('setTopForContinuesScroll', payload);
  },
  setHistoricMapPDFSearchTerm({ commit }, payload): any {
    commit('historicMapPDFSearchTerm', payload);
  },
  setHistoricPDFSearchTerm({ commit }, payload): any {
    commit('historicPDFSearchTerm', payload);
  },
  setHistoricHTMLMapPDFSearchTerm({ commit }, payload): any {
    commit('historicHTMLMapPDFSearchTerm', payload);
  },
  setHistoricListSearchTerm({ commit }, payload): void {
    commit('historicListSearchTerm', payload);
  },
  setSortParams({ commit }, payload): void {
    commit('setSortParams', payload);
  },
  setHistoricalDateFilters({ commit }, payload) {
    commit('setFilterParams', payload);
  },
  clearHistoricalDateFilters({ commit }): void {
    commit('setClearHistoricalDateFilters');
  },
  setSelectedHistoricalAmendmentStatus({ commit }, payload): void {
    commit('historicListStatus', payload);
  },
  setSelectedHistoricalAmendmentOutcome({ commit }, payload): void {
    commit('historicListOutcome', payload);
  },
  setSelectedHistoricalAmendmentClassification({ commit }, payload): void {
    commit('historicListClassification', payload);
  },
  setSelectedHistoricalAmendmentType({ commit }, payload): void {
    commit('historicListType', payload);
  },
  setCurrentPageToStore({ commit }, payload): void {
    commit('currentPage', payload);
  },
};
