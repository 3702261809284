import { SortOrder, SortOrderType } from '@/store/schemes/types';
import dayjs from 'dayjs';

export const textSort = (x: string, y: string, sortOrder: SortOrderType) => {
  if (sortOrder === SortOrder.ASC) return x.localeCompare(y);
  if (sortOrder === SortOrder.DESC) return y.localeCompare(x);
  return 0;
};

export const dateSort = (
  x: Date | null,
  y: Date | null,
  sortOrder: SortOrderType,
) => {
  if (sortOrder === SortOrder.ASC) {
    if (x === null) return -1;
    if (y === null) return 1;
    return x.getTime() < y.getTime() ? -1 : 1;
  }
  if (sortOrder === SortOrder.DESC) {
    if (x === null) return 1;
    if (y === null) return -1;
    return x.getTime() > y.getTime() ? -1 : 1;
  }
  return 0;
};

export function inDateRange(from: Date | null, to: Date | null) {
  return (variableDate: Date) => {
    if (!variableDate) {
      return false;
    }
    const fromDate = from?.getTime();
    const toDate = to?.getTime();
    const opDate = variableDate.getTime();
    if (fromDate && toDate) {
      return fromDate <= opDate && opDate <= toDate;
    }
    if (fromDate) {
      return fromDate <= opDate;
    }
    if (toDate) {
      return opDate <= toDate;
    }
    return true;
  };
}

export function toDateOrNull(x: string | undefined | null) {
  if (x === undefined || x === null) {
    return null;
  }
  const d = dayjs(x);
  return d.isValid() ? d.toDate() : null;
}
