










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Schemes extends Vue {
  @Getter('affectedSchemes', { namespace: 'amendment' })
  private affectedSchemes!: any;
}
