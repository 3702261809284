





























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { PlanningSchemeVersion } from '@/models';

import { RplRow } from '@dpc-sdp/ripple-grid';

import ListResultInfo from '@/components/shared/ListResultInfo.vue';
import PSOPagination from '@/components/shared/PSOPagination.vue';
import { split } from '@/helpers/array-split';
import SortableButton from '@/components/shared/SortableButton.vue';
import { TableHeader } from '@/models/table-header';
import { Sorting } from '@/store/schemes/types';
import SortMobile from '@/components/shared/SortMobile.vue';
import FocusItem from '@/mixins/FocusItem.vue';
import HistoryListRow from './HistoryListRow.vue';

@Component({
  mixins: [FocusItem],
  components: {
    ListResultInfo,
    RplRow,
    HistoryListRow,
    PSOPagination,
    SortableButton,
    SortMobile,
  },
})
export default class HistoriesTable extends Vue {
  @Prop() headers!: TableHeader[];

  @Prop() searchTerm!: string;

  @Prop() planningSchemeCode!: string;

  @Prop() planningSchemeVersion!: PlanningSchemeVersion[];

  @Prop() totalCount!: number;

  @Prop() currentPage!: number;

  @Prop() pageSize!: number;

  @Prop() sortParams: Sorting | undefined;

  @Prop() hasPlanningSchemeColumn!: boolean;

  public chunked: PlanningSchemeVersion[][] = [];

  get startRecord(): number {
    return this.currentPage * this.pageSize;
  }

  get endRecord(): number {
    const start = this.currentPage * this.pageSize;
    const results = this.paginatedResults;
    return start + results.length;
  }

  get paginatedResults(): PlanningSchemeVersion[] {
    const val = this.chunked[this.currentPage];
    if (val) {
      return val;
    }
    return [];
  }

  mounted() {
    this.chunked = split(this.planningSchemeVersion, this.pageSize);
  }

  public paginationChanged(val: number) {
    this.$emit('onPaginationChange', val);
  }

  public handleSortChange(event: Sorting | undefined): void {
    this.$emit('on-sort-change', event);
  }

  @Watch('planningSchemeVersion')
  onPlanningSchemeVersionChange() {
    this.chunked = split(this.planningSchemeVersion, this.pageSize);
  }

  @Watch('pageSize')
  onPlanningSchemePageSizeChange() {
    this.chunked = split(this.planningSchemeVersion, this.pageSize);
  }
}
