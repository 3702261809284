
































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { boolToYesNo } from '@/filters';
import RplContact from '@dpc-sdp/ripple-contact';

const namespace = 'amendment';

@Component({
  components: {
    RplContact,
  },
  filters: {
    boolToYesNo,
  },
})
export default class Details extends Vue {
  @Getter('amendmentDetails', { namespace })
  private amendmentDetails!: any;

  @Getter('planningAuthorityContact', { namespace })
  private planningAuthorityContact!: any;

  get showEES() {
    return (
      this.amendmentDetails.environmentalEffectsStatementNeeded === true ||
      this.amendmentDetails.environmentalEffectsStatementNeeded === false
    );
  }

  get planningAuthorityPhone() {
    return [
      {
        title: '',
        number: this.planningAuthorityContact.phone,
      },
    ];
  }
}
