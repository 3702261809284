import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { api } from '@/config';
import { LoaderStates } from '@/store/loading';
import { logger } from '@/helpers';
import { AlertBannerState } from './types';

export const actions: ActionTree<AlertBannerState, RootState> = {
  async fetchAlertBannerDetails({
    commit,
    rootGetters: { selectedScheme },
  }): Promise<void> {
    const client = (await api).schemesClient;

    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    try {
      const res = await client.fetchAlertBannerDetails(selectedScheme.schemeID);
      if (res.status === 200) {
        commit('setAlertBanners', res.data.results);
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    } catch (error) {
      commit('loading/setState', LoaderStates.ERROR, { root: true });

      if (error instanceof Error) {
        logger.error('Error while fetching alert banners', error.message);
      } else {
        logger.error('Non fetch type error occurred');
      }
    }
  },
};
