var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:[
    'rpl-accordion__list-item',
    { 'rpl-accordion__list-item--expanded': _vm.expanded } ]},[_c('h3',{class:[
      'rpl-accordion__title',
      { 'rpl-accordion__title--expanded': _vm.expanded } ]},[_c('button',{class:[
        'rpl-accordion__button',
        { 'rpl-accordion__button--expanded': _vm.expanded } ],attrs:{"aria-expanded":_vm.expanded},on:{"click":_vm.toggleClasses}},[_c('span',{staticClass:"rpl-accordion__button-text"},[_vm._v("Grid area "+_vm._s(_vm.accordion[0].gridReference))]),_c('svg',{class:[
          ' rpl-accordion__icon',
          ' rpl-icon',
          'rpl-icon--arrow_down_tertiary',
          'rpl-icon--color_primary',
          { 'rpl-accordion__icon--expanded': _vm.expanded } ],staticStyle:{"width":"15px","height":"9px"},attrs:{"aria-label":_vm.expanded ? 'Collapse accordion' : 'Expand accordion',"viewBox":"0 0 15 9","aria-hidden":"true","overflow":"visible"}},[_c('path',{attrs:{"d":"M.44.44a1.5 1.5 0 012.12 0L7.5 5.378l4.94-4.94a1.5 1.5 0 012.12 2.122l-6 6a1.5 1.5 0 01-2.12 0l-6-6a1.5 1.5 0 010-2.122z"}})])])]),_vm._l((_vm.accordion),function(map,index){return _c('div',{key:index,class:['accordion__content', { expanded: _vm.expanded }]},[_c('RplDocumentLink',{staticClass:"map-content",attrs:{"aria-label":"PDF map link","name":map.mapTitle,"url":map.url,"extension":map.fileType.toLowerCase(),"filesize":_vm._f("formatBytes")(map.fileSize),"forceNewTab":true}}),_c('div',{staticClass:"introduced-by"},[_vm._v(" Last updated by: "),_c('PSOLink',{attrs:{"linkTo":("amendments/" + (map.introducedByAmendment)),"text":map.introducedByAmendment,"showBackButton":false}}),_vm._v(" | "),_c('span',{staticClass:"gazettal-date"},[_vm._v(_vm._s(_vm._f("formatDate")(map.introducedByDate)))])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }