













import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({
  name: 'menu-item-number-span',
})
export default class MenuItemNumberSpan extends Vue {
  @Prop() identifier;

  @Prop() ordinanceType;

  @Prop() isScheduleSection;

  schedulePattern = /(\d+(\.\d+)?)/g;

  get isSchedule() {
    return (
      this.ordinanceType === 'Schedule Section' ||
      this.ordinanceType === 'Schedule'
    );
  }

  get scheduleText() {
    const matches = this.identifier.match(this.schedulePattern);
    return matches ? matches[matches.length - 1] : null;
  }
}
