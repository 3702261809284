














import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RplSiteFooter } from '@dpc-sdp/ripple-site-footer';

export interface MyHTMLElement extends HTMLFormElement {
  $el: HTMLElement;
}

@Component({
  components: {
    RplSiteFooter,
  },
})
export default class Footer extends Vue {
  contactUs = {
    text: 'Contact us',
    children: [
      {
        text: 'Planning general enquiries',
        url: 'https://www.planning.vic.gov.au/contact-us',
        target: '_blank',
      },
    ],
  };

  departmentOfTransportAndPlanning = {
    text: 'Department of Transport and Planning',
    children: [
      {
        text: 'Planning',
        url: 'https://www.planning.vic.gov.au',
        target: '_blank',
      },
      {
        text: 'Heritage',
        url: 'https://www.heritage.vic.gov.au',
        target: '_blank',
      },
      {
        text: 'Building',
        url: 'https://www.building.vic.gov.au',
        target: '_blank',
      },
      {
        text: 'Planning Panels',
        url: 'https://www.planningpanels.vic.gov.au',
        target: '_blank',
      },
    ],
  };

  connectWithUs = {
    title: 'Connect with us',
    children: [
      {
        title: 'Twitter',
        uri: 'https://twitter.com/VicGovDTP',
        icon: 'twitter',
        target: '_blank',
      },
      {
        title: 'LinkedIn',
        uri: 'https://www.linkedin.com/company/department-of-transport-and-planning/',
        icon: 'linkedin',
        target: '_blank',
      },
      {
        title: 'Youtube',
        uri: 'https://www.youtube.com/@vicgovdtp',
        icon: 'youtube_channel',
        target: '_blank',
      },
    ],
  };

  traditionalOwners =
    'We acknowledge and respect Victorian Traditional Owners as the original custodians of Victoria’s land and waters, their unique ability to care for Country and deep spiritual connection to it.';

  elders =
    'We honour Elders past and present whose knowledge and wisdom has ensured the continuation of culture and traditional practices.';

  commitment =
    'We are committed to genuinely partner, and meaningfully engage, with Victoria’s Traditional Owners and Aboriginal communities to support the protection of Country, the maintenance of spiritual and cultural practices and their broader aspirations in the 21st century and beyond.';

  acknowledgement = `${this.traditionalOwners} ${this.elders}\n\n${this.commitment}`;

  links = [
    {
      text: 'Terms and conditions',
      url: 'https://dtp.vic.gov.au/terms-and-conditions',
      target: '_blank',
    },
    {
      text: 'Accessibility',
      url: 'https://dtp.vic.gov.au/accessibility',
      target: '_blank',
    },
    {
      text: 'Privacy',
      url: 'https://dtp.vic.gov.au/privacy',
      target: '_blank',
    },
  ];

  speachImpared = {
    text: 'Deaf, hearing or speech impaired? Please contact the National Relay Service:',
    children: [
      {
        text: '133 677',
        url: 'tel:133 677',
        target: '_blank',
      },
      {
        text: 'www.relayservice.gov.au',
        url: 'https://www.infrastructure.gov.au/media-communications-arts/phone/services-people-disability/accesshub',
        target: '_blank',
      },
    ],
  };

  navs = [
    this.departmentOfTransportAndPlanning,
    this.speachImpared,
    this.contactUs,
  ];

  copyright = '© Copyright State Government of Victoria';

  setUpFormAccessibilityOptions = () => {
    const simulateClick = (element) => {
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      element.dispatchEvent(event);
    };

    const links = document.getElementsByClassName('rpl-footer-nav__heading');

    for (let i = 0; i < links.length; i += 1) {
      const link = links[i];

      link.setAttribute('tabindex', '0');
      link.addEventListener('keydown', (event) => {
        if ((event as KeyboardEvent).key === 'Enter') {
          simulateClick(link);
        }
      });
    }
  };

  mounted() {
    this.$nextTick(() => {
      this.setUpFormAccessibilityOptions();
    });
  }
}
