






















import { RplDivider } from '@dpc-sdp/ripple-global';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { isDate } from '@/helpers/validation-utils';
import dayjs from 'dayjs';
import Router from 'vue-router';
import HistoricDateFilter from './HistoricDateFilter.vue';
import HistoricOutcomeFilter from './HistoricOutcomeFilter.vue';
import HistoricStatusFilter from './HistoricStatusFilter.vue';
import HistoricTypeFilter from './HistoricTypeFilter.vue';
import HistoricClassificationFilter from './HistoricClassificationFilter.vue';

const { isNavigationFailure } = Router;
@Component({
  components: {
    HistoricDateFilter,
    HistoricStatusFilter,
    HistoricClassificationFilter,
    HistoricTypeFilter,
    HistoricOutcomeFilter,
    RplDivider,
  },
})
export default class HistoricFilters extends Vue {
  @Action('clearHistoricalDateFilters', { namespace: 'schemes' })
  private clearDateFilters;

  @Action('setSelectedHistoricalAmendmentStatus', { namespace: 'schemes' })
  private setStatusFilter;

  @Action('setSelectedHistoricalAmendmentType', { namespace: 'schemes' })
  private setTypeFilter;

  @Action('setSelectedHistoricalAmendmentOutcome', { namespace: 'schemes' })
  private setOutcomeFilter;

  @Action('setSelectedHistoricalAmendmentClassification', {
    namespace: 'schemes',
  })
  private setClassificationFilter;

  public resetFilters() {
    this.setQueryParams({
      'filter.status': 'Gazetted,Finished',
      'filter.outcome':
        'Approved,ApprovedWithChanges,Revoked,ApprovedByPlanningAuthority',
      'filter.type': '',
      'filter.classification': '',
      to: '',
      from: '',
      currentPage: '0',
    });
  }

  public handleDateChange(payload) {
    if (payload) {
      const { from, to } = payload;
      const params: Map<string, string> = new Map<string, string>();
      if (typeof from === 'string' && isDate(from)) {
        params.set('from', dayjs(from).format('YYYY-MM-DD'));
      } else {
        params.set('from', '');
      }
      if (typeof to === 'string' && isDate(to)) {
        params.set('to', dayjs(to).format('YYYY-MM-DD'));
      } else {
        params.set('to', '');
      }
      params.set('currentPage', '0');
      if (params.size > 0) {
        this.setQueryParams(Object.fromEntries(params));
      }
    }
  }

  public handleStatusChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({
        'filter.status': payload,
      });
    } else {
      this.removeQueryParams(['filter.status']);
    }
    if (payload === '') {
      this.setStatusFilter([]);
    } else {
      this.setStatusFilter(payload.split(','));
    }
  }

  public handleTypeChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({
        'filter.type': payload,
      });
    } else {
      this.removeQueryParams(['filter.type']);
    }

    if (payload === '') {
      this.setTypeFilter([]);
    } else {
      this.setTypeFilter(payload.split(','));
    }
  }

  public handleClassificationChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({
        'filter.classification': payload,
      });
    } else {
      this.removeQueryParams(['filter.classification']);
    }

    if (payload === '') {
      this.setClassificationFilter([]);
    } else {
      this.setClassificationFilter(payload.split(','));
    }
  }

  public handleOutcomeChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({
        'filter.outcome': payload,
      });
    } else {
      this.removeQueryParams(['filter.outcome']);
    }
    if (payload === '') {
      this.setOutcomeFilter([]);
    } else {
      this.setOutcomeFilter(payload.split(','));
    }
  }

  setQueryParams(params: Record<string, string>): void {
    this.$router
      .push({
        query: {
          ...this.$route.query,
          ...params,
          currentPage: '0',
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  removeQueryParams(params: string[]): void {
    const opts = { ...this.$route.query };
    params.forEach((param) => delete opts[param]);
    this.$router.push({ query: opts }).catch((error) => {
      if (!isNavigationFailure(error)) {
        throw error;
      }
    });
  }
}
