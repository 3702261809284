








































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RplDocumentLink from '@/components/shared/DocumentLink.vue';
import PSOLink from '@/components/shared/PSOLink.vue';

@Component({
  components: { RplDocumentLink, PSOLink },
})
export default class Accordion extends Vue {
  @Prop() accordion!: [];

  @Prop() gridReference!: string;

  public expanded = this.gridReference === '01';

  public toggleClasses() {
    this.expanded = !this.expanded;
  }
}
