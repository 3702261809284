









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { loadIcons } from '@/load-resources';

loadIcons();

@Component
export default class SvgIcon extends Vue {
  @Prop() public iconName;

  @Prop() public className;

  get name() {
    const icon = this.iconName;
    return icon ? `#icon-${icon}` : '';
  }

  get svgClass() {
    const { className } = this;
    return className ? `svg-icon ${className}` : 'svg-icon';
  }
}
