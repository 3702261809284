





























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Redirect from '@/components/shared/Redirect.vue';
import { RplCol, RplContainer, RplRow } from '@dpc-sdp/ripple-grid';
import { RplDivider } from '@dpc-sdp/ripple-global';

import { SelectedScheme } from '@/models';
import SearchSimpleComponent from '@/components/search/SearchSimple.vue';
import { SectionWithTags } from '@/store/schemes/types';
import { split } from '@/helpers/array-split';
import ListResultInfo from '@/components/shared/ListResultInfo.vue';
import { filterHistories } from '@/components/histories/filter-params';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PSOPagination from '@/components/shared/PSOPagination.vue';
import { scrollToElement } from '@/helpers/scroll-to-element';
import FocusItem from '@/mixins/FocusItem.vue';
import HistoricSearchResultRow from './HistoricSearchResultRow.vue';
import {
  historicPlanningSchemeTitle,
  selectedVersionTitle,
} from './convert-from-domain';

dayjs.extend(customParseFormat);

@Component({
  mixins: [FocusItem],
  components: {
    SearchSimpleComponent,
    Redirect,
    RplContainer,
    RplDivider,
    RplRow,
    RplCol,
    PSOPagination,
    HistoricSearchResultRow,
    ListResultInfo,
  },
})
export default class HistoricSearchHTML extends Vue {
  @Prop() schemeName;

  @Prop() versionId;

  @Prop() historicalScheme;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('getFlatHistoricalOrdinances', { namespace: 'schemes' })
  private flatOrdinances!: SectionWithTags[];

  public pageSize = 10;

  // public filteredItems: SectionWithTags[] = [];

  public currentPage = 0;

  // private chunked: SectionWithTags[][] = [];

  get selectedVersionTitle() {
    return selectedVersionTitle(this.historicalScheme);
  }

  get searchTerm(): string {
    return (
      (Array.isArray(this.$route?.query?.q)
        ? this.$route?.query?.q[0]
        : this.$route?.query?.q) || ''
    );
  }

  get planningSchemeTitle(): string {
    return historicPlanningSchemeTitle(this.selectedScheme.title);
  }

  get totalCount() {
    return this.filteredItems.length;
  }

  get startRecord(): number {
    return this.currentPage * this.pageSize;
  }

  get endRecord(): number {
    const start = this.currentPage * this.pageSize;
    const results = this.paginatedResults;
    return start + results.length;
  }

  get paginatedResults() {
    const val = this.chunked[this.currentPage];
    if (val) {
      return val.map((item) => ({
        ...item,
        gazettalDate: dayjs(item.gazettalDate, 'DD/MM/YYYY').toDate(),
        route: {
          name: 'historicOrdinanceDetail',
          params: {
            ordinanceID: item.ordinanceID,
            schemeName: this.$route.params.schemeName,
            versionId: this.$route.params.versionId,
          },
          meta: {
            ordinanceID: item.ordinanceID,
          },
        },
      }));
    }
    return [];
  }

  public handlePageChange(page: number) {
    this.currentPage = page;
    scrollToElement('.search-historic');
  }

  public triggerSearchChanged(query: string): void {
    this.handlePageChange(0);
    if (query !== this.searchTerm) {
      this.$router.push({ path: this.$route.path, query: { q: query } });
    }
  }

  get filteredItems(): SectionWithTags[] {
    return this.flatOrdinances.filter(
      (item) =>
        filterHistories(item.title, this.searchTerm) ||
        filterHistories(item.ordinanceNumber, this.searchTerm) ||
        filterHistories(item.amendmentNumber, this.searchTerm) ||
        filterHistories(item.ordinanceMapCode, this.searchTerm),
    );
  }

  get chunked() {
    return split(this.filteredItems, this.pageSize);
  }

  async mounted() {
    this.handlePageChange(0);
  }
}
