import { AmendmentOutcome, AmendmentOutcomeSymbol } from '@/models';

export const outcomeToString = (outcome: AmendmentOutcomeSymbol): string => {
  switch (outcome) {
    case AmendmentOutcome.Abandoned:
      return 'Abandoned';
    case AmendmentOutcome.Approved:
      return 'Approved';
    case AmendmentOutcome.ApprovedByPlanningAuthority:
      return 'Approved by planning authority';
    case AmendmentOutcome.ApprovedWithChanges:
      return 'Approved with changes';
    case AmendmentOutcome.ATSMigratedUnspecified:
      return 'ATS migrated unspecified';
    case AmendmentOutcome.AuthorisationRefused:
      return 'Authorisation refused';
    case AmendmentOutcome.Lapsed:
      return 'Lapsed';
    case AmendmentOutcome.Refused:
      return 'Refused';
    case AmendmentOutcome.Revoked:
      return 'Revoked';
    case AmendmentOutcome.RevokingComplete:
      return 'Revoking complete';
    case AmendmentOutcome.SplitParent:
      return 'Split parent';
    case AmendmentOutcome.Withdrawn:
      return 'Withdrawn';
    default:
      return '';
  }
};
