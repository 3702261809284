


























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplDataTable from '@dpc-sdp/ripple-data-table';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import PSOLink from '@/components/shared/PSOLink.vue';
import { Location } from 'vue-router';
import { previousRouteMixin } from '@/mixins/previousRouteMixin';
import FocusItem from '@/mixins/FocusItem.vue';
import prePdfData from './pre-1997';
import { constructLink } from './historic-links';
import { PrePdfDataModel } from './models';
import AlertBanner from '../shared/AlertBanner.vue';

@Component({
  mixins: [previousRouteMixin, FocusItem],
  components: {
    AlertBanner,
    RplCol,
    RplRow,
    RplDataTable,
    RplIcon,
    PSOLink,
  },
})
export default class PrePdfRecords extends Vue {
  public openInNewTab = true;

  previousRoute: Location = { name: 'histories' };

  get linkToRegister() {
    return {
      path: 'https://id.cp.prov.vic.gov.au/auth/realms/prov/protocol/openid-connect/auth?client_id=website&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A//prov.vic.gov.au/openid-connect/keycloak&state=L7R0EVwUiozfvAm1jrNT7jfCbvMku7V3pQ88PtlGnHo',
    };
  }

  get prePdfColumns(): string[] {
    return ['Series Number', 'Title'];
  }

  get prePdfItems() {
    return prePdfData.map((item: PrePdfDataModel) => [
      constructLink(item.link, item.name, this.openInNewTab),
      item.title,
    ]);
  }

  get items() {
    return [this.prePdfColumns].concat(this.prePdfItems);
  }
}
