import { isAllSchemes } from '@/helpers/all-schemes';

const buildPageTitle: (args: {
  routeName: string;
  schemeName: string;
}) => string = ({ routeName, schemeName }) => {
  switch (routeName) {
    case 'amendments':
    case 'amendmentDetail':
      if (isAllSchemes(schemeName)) {
        return 'Browse amendments';
      }
      return schemeName;
    case 'ordinanceSearch':
      return `Search in`;
    case 'home':
      return '';
    default:
      return schemeName;
  }
};

const buildPageSubtitle: (args: {
  routeName: string;
  schemeName: string;
}) => string | null = ({ routeName, schemeName }) => {
  if (
    isAllSchemes(schemeName) &&
    ['amendments', 'amendmentDetail'].includes(routeName)
  ) {
    return 'Search for and view details of amendments to planning schemes';
  }
  if (routeName === 'ordinanceSearch') {
    return 'planning schemes';
  }
  if (routeName !== 'home' && schemeName !== 'Victoria Planning Provisions') {
    return 'planning scheme';
  }
  return null;
};

export { buildPageTitle, buildPageSubtitle };
