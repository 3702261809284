const historicText = (historic: boolean): string =>
  historic ? 'Historic ' : '';

export const planningSchemePdfDocName = (
  schemeName: string,
  historic = false,
): string => `Download ${historicText(historic)}${schemeName} PDF Scheme`;

export const planningSchemePdfDownloadName = (
  schemeName: string,
  historic = false,
): string => {
  const prefix =
    schemeName.toLowerCase() === 'victoria planning provisions'
      ? schemeName
      : `${schemeName} PS`;
  return `${historicText(historic)}${prefix} All Ordinance`;
};

export const calculateFileSize = (documentSize: number): string =>
  `${Math.ceil(documentSize / (1024 * 1024))} MB`;
