








































import Vue from 'vue';
import { SelectedScheme } from '@/models';
import { Getter } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { buildPageTitle, buildPageSubtitle } from '@/helpers/header-titles';
import { uppercaseFirstChar } from '@/helpers/render.helper';
import { SchemeAmendmentAndGazettalDate } from '@/models/planning-scheme.model';
import { formatSchemeGazettalDate } from '@/filters';

import PSOLink from '@/components/shared/PSOLink.vue';

@Component({ components: { PSOLink } })
class PageTitle extends Vue {
  @Getter('selectedScheme')
  public selectedScheme!: SelectedScheme;

  @Getter('schemeAmendmentAndDate')
  public schemeAmendmentAndDate!: SchemeAmendmentAndGazettalDate;

  public pageTitle = '';

  public pageSubtitle: string | null = '';

  public createPageTitleSubtitle(routeName, schemeName) {
    this.pageTitle = uppercaseFirstChar(
      buildPageTitle({
        routeName,
        schemeName,
      }),
    );
    this.pageSubtitle = buildPageSubtitle({
      routeName,
      schemeName,
    });
  }

  get formattedDate() {
    return formatSchemeGazettalDate(this.schemeAmendmentAndDate.gazettalDate);
  }

  get allSchemes() {
    const { schemeName } = this.$route.params;

    if (schemeName) {
      return schemeName.toLowerCase() === 'all schemes';
    }
    return false;
  }

  @Watch('$route.name', { immediate: true, deep: false })
  onUrlChange(routeName) {
    if (routeName)
      this.createPageTitleSubtitle(routeName, this.selectedScheme.title);
  }
}

export default PageTitle;
