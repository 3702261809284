































































import Vue from 'vue';

import { Component, Watch } from 'vue-property-decorator';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import displayHeader from '@/helpers/display-header';

import BannerIcon from '../icons/TopBanner.vue';
import PageTitle from './PageTitle.vue';
import Banner from './Banner.vue';

@Component({
  components: {
    Banner,
    BannerIcon,
    PageTitle,
    RplCol,
    RplContainer,
    RplRow,
  },
})
export default class Header extends Vue {
  public showSchemeHeader = false;

  get allSchemes() {
    const { schemeName } = this.$route.params;

    if (schemeName) {
      return schemeName.toLowerCase() === 'all schemes';
    }
    return false;
  }

  @Watch('$route.name', { immediate: true, deep: false })
  onUrlChange() {
    this.showSchemeHeader = displayHeader(this.$route?.name);
  }
}
