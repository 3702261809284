










import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

import ChangeInfo from '@/components/ChangeInfo.vue';
import IncorporatedDocuments from '@/components/incorporatedDocuments/IncorporatedDocuments.vue';
import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import Redirect from '@/components/shared/Redirect.vue';

import { SelectedScheme } from '@/models';

import { planningSchemeTitle } from '@/helpers/all-schemes';
import AlertBanner from '@/components/shared/AlertBanner.vue';

@Component({
  components: {
    AlertBanner,
    ChangeInfo,
    IncorporatedDocuments,
    FeatureToggled,
    Redirect,
  },
})
export default class IncorporatedDocumentsTab extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  schemeName;

  @Getter('selectedScheme')
  private selectedScheme?: SelectedScheme;

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.selectedScheme?.title || '');
  }

  get selectedSchemeExists(): boolean {
    return this.selectedScheme !== undefined;
  }

  get notFoundUrl(): string {
    return `/404/${this.planningSchemeTitle}`;
  }
}
