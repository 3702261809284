































































import { logger } from '@/helpers';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import { RplDivider } from '@dpc-sdp/ripple-global';
import provideChildCols from '@dpc-sdp/ripple-global/mixins/ProvideChildCols';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplPagination from '@dpc-sdp/ripple-pagination';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Spinner from '../shared/Spinner.vue';
import SearchResult from './SearchResult.vue';

@Component({
  components: {
    RplDivider,
    RplPagination,
    RplRow,
    RplCol,
    Spinner,
    SearchResult,
    RplAlertBase,
  },
  mixins: [provideChildCols],
})
export default class SearchResultList extends Vue {
  public schemesLength = 0;

  @Prop() searchResults;

  @Prop() error;

  @Prop() count;

  @Prop({ default: false }) loading;

  @Prop({
    default: "Search isn't working right now, please try again later.",
  })
  errorMsg;

  @Prop({
    default: () => ({
      totalSteps: 8,
      initialStep: 1,
      stepsAround: 2,
    }),
  })
  pager: any;

  @Prop({ default: 10 }) perPage;

  public paginationChanged($event) {
    this.$emit('pager-change', $event);
  }

  public setCurrentPageToStore() {
    logger.debug('setCurrentPageToStore =', this.pager.initialStep);
    this.$store.dispatch('SET_CURRENTPAGE_ACTION', this.pager.initialStep);
  }

  public showParent(row) {
    return row.type !== 'Schedule' && row.type !== 'Clause';
  }

  public get range() {
    if (this.pager && this.count && this.count > 0) {
      const from =
        this.pager.initialStep < 2
          ? 1
          : this.perPage * (this.pager.initialStep - 1) + 1;
      const total = () => {
        const byPage = this.perPage * this.pager.initialStep;
        if (byPage > this.count) {
          return this.count;
        }
        return byPage;
      };
      return `${from}-${total()}`;
    }
    return false;
  }

  @Watch('$route.query.schemes', { immediate: true, deep: false })
  onUrlChange() {
    let schemes;
    if (typeof this.$route.query.schemes === 'string') {
      schemes = this.$route.query.schemes.split(',');
    } else {
      schemes = this.$route.query.schemes;
    }

    this.schemesLength = schemes ? schemes.length : 0;
  }
}
