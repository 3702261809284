






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplDataTable from '@dpc-sdp/ripple-data-table';

import { RplTextIcon } from '@dpc-sdp/ripple-icon';

import { constructPdfLink } from '@/components/histories/historic-links';
import { historicPdfUrl } from '@/config';
import { formatBytes } from '@/filters';

import { scrollToElement } from '@/helpers/scroll-to-element';
import HistoricInfoMessage from './HistoricInfoMessage.vue';
import HistoricSearchFilter from './HistoricSearchFilter.vue';
import { filterHistories } from './filter-params';

@Component({
  components: {
    HistoricInfoMessage,
    HistoricSearchFilter,
    RplCol,
    RplRow,
    RplDataTable,
    RplTextIcon,
  },
})
export default class HistoricPDFTab extends Vue {
  @Getter('historicPDFSearchTerm', { namespace: 'schemes' })
  private searchTerm!: string;

  @Getter('pdfClausesTableItems', { namespace: 'schemes' })
  private pdfClausesTableItems;

  @Getter('pdfClausesTableHeaders', { namespace: 'schemes' })
  private pdfClausesTableHeaders;

  @Action('setHistoricPDFSearchTerm', { namespace: 'schemes' })
  private setPDFSearchTerm;

  public componentKey = 0;

  public filteredItems: any = [];

  public baseUrl = '';

  public popUpText = 'Press [Enter] to search historical PDF content.';

  public helpText = "For example: 'clause number or clause title'";

  public enterClicked(val) {
    if (val === '') {
      this.filteredItems = this.pdfClausesTableItems;
    } else {
      this.filteredItems = this.pdfClausesTableItems.filter(
        (item) =>
          filterHistories(item.clause, val) ||
          filterHistories(item.clauseType, val),
      );
    }
    this.setPDFSearchTerm(val);
    this.componentKey += 1;
  }

  get filteredItemsWithLinks(): [string, string, string][] {
    const filteredValues = this.filteredItems.map((item): any => [
      constructPdfLink(
        item.fileName ? this.baseUrl + item.fileName : '#',
        item.clause || 'Ordinance (unknown number)',
        true,
      ),
      item.clauseType || 'Ordinance (unknown clause)',
      formatBytes(item.size || 0),
    ]);

    if (filteredValues.length === 0) {
      filteredValues.push(["Sorry! We couldn't find any matches.", '', '']);
    }

    return [this.pdfClausesTableHeaders, ...filteredValues];
  }

  public clearSearch() {
    this.setPDFSearchTerm('');
    this.enterClicked('');
  }

  async mounted() {
    this.baseUrl = await historicPdfUrl;
    this.componentKey += 1;

    this.filteredItems = this.pdfClausesTableItems.filter(
      (item) =>
        filterHistories(item.clause, this.searchTerm) ||
        filterHistories(item.clauseType, this.searchTerm),
    );
  }

  created() {
    scrollToElement('.app');
  }

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'historicMapTab') {
      this.setPDFSearchTerm('');
    }
    next();
  }
}
