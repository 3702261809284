export const allSchemeTitles = [
  'all schemes',
  'alpine',
  'alpine resorts',
  'ararat',
  'ballarat',
  'banyule',
  'bass coast',
  'baw baw',
  'bayside',
  'benalla',
  'boroondara',
  'brimbank',
  'buloke',
  'campaspe',
  'cardinia',
  'casey',
  'central goldfields',
  'colac otway',
  'corangamite',
  'darebin',
  'east gippsland',
  'frankston',
  'french island and sandstone island',
  'gannawarra',
  'glen eira',
  'glenelg',
  'golden plains',
  'greater bendigo',
  'greater dandenong',
  'greater geelong',
  'greater shepparton',
  'gumnut',
  'hepburn',
  'hindmarsh',
  'hobsons bay',
  'horsham',
  'hume',
  'indigo',
  'kingston',
  'knox',
  'latrobe',
  'loddon',
  'macedon ranges',
  'manningham',
  'mansfield',
  'maribyrnong',
  'maroondah',
  'melbourne',
  'melton',
  'merri-bek',
  'mildura',
  'mitchell',
  'moira',
  'monash',
  'moonee valley',
  'moorabool',
  'mornington peninsula',
  'mount alexander',
  'moyne',
  'murrindindi',
  'nillumbik',
  'northern grampians',
  'port of melbourne',
  'port phillip',
  'pyrenees',
  'queenscliffe',
  'south gippsland',
  'southern grampians',
  'stonnington',
  'strathbogie',
  'surf coast',
  'swan hill',
  'test lpp',
  'test vpp',
  'towong',
  'victoria planning provisions',
  'wangaratta',
  'warrnambool',
  'wellington',
  'west wimmera',
  'whitehorse',
  'whittlesea',
  'wodonga',
  'wyndham',
  'yarra',
  'yarra ranges',
  'yarriambiack',
];
