import { IncorporatedDocumentApi } from '@/api/types';
import { IncorporatedDocument } from './types';

export const convertToIncorporatedDocuments = (
  list: IncorporatedDocumentApi[],
): IncorporatedDocument[] =>
  list.map((item) => ({
    ...item,
    fileSize: item.fileSize ? item.fileSize : undefined,
    type: item.isVpp ? 'State' : 'Local',
  }));
