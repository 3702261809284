




































































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';

import provideChildCols from '@dpc-sdp/ripple-global/mixins/ProvideChildCols';
import SortableButton from '@/components/shared/SortableButton.vue';
import SortAmendment from '@/components/amendments/SortAmendment.vue';
import { SearchAmendmentRequestOptions, SelectedScheme } from '@/models';
import ResultsPerPage from '@/components/shared/ResultsPerPage.vue';
import ListResultInfo from '@/components/shared/ListResultInfo.vue';
import { RplCol, RplRow, RplContainer } from '@dpc-sdp/ripple-grid';
import FocusItem from '@/mixins/FocusItem.vue';
import SearchAmendmentRow from './SearchAmendmentRow.vue';
import { updateAmendmentSearchParams } from '../../helpers/search-params';

@Component({
  mixins: [provideChildCols, FocusItem],
  components: {
    RplCol,
    ListResultInfo,
    SortableButton,
    SortAmendment,
    SearchAmendmentRow,
    ResultsPerPage,
    RplRow,
    RplContainer,
  },
})
export default class SearchAmendmentResultTable extends Vue {
  @Getter('isAllSchemesView')
  public isAllSchemesView!: boolean;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('searchParams', { namespace: 'amendment' })
  private searchParams!: SearchAmendmentRequestOptions;

  @Getter('totalAmendments', { namespace: 'amendment' })
  public total!: number;

  @Prop() searchResults; // @/models/Amendment[]

  public perPageOptions = [20, 50, 100];

  private updateSearchParams;

  public sortKey = 'lastUpdated';

  public sortOrder = 'desc';

  public currentPage = 0;

  public pageSize = 20;

  public headers = [
    {
      id: 'amendmentNumber',
      text: 'Amendment no.',
      enableSort: true,
      sortOrder: 'none',
      visible: true,
      isSortActive: false,
    },
    {
      id: 'ps',
      text: 'Planning scheme',
      enableSort: true,
      sortOrder: 'asc',
      visible: false,
      isSortActive: false,
    },
    {
      id: 'classification',
      text: 'Classification',
      enableSort: true,
      sortOrder: 'asc',
      visible: true,
      isSortActive: false,
    },
    {
      id: 'status',
      text: 'Status',
      enableSort: true,
      sortOrder: 'none',
      visible: true,
      isSortActive: false,
    },
    {
      id: 'lastUpdated',
      text: 'Last updated',
      enableSort: true,
      sortOrder: 'desc',
      visible: true,
      isSortActive: true,
    },
    {
      id: 'outcome',
      text: 'Outcome',
      enableSort: true,
      sortOrder: 'asc',
      visible: true,
      isSortActive: false,
    },
  ];

  get startRecord(): number {
    return this.currentPage * this.pageSize;
  }

  get endRecord(): number {
    return this.startRecord + this.searchResults.length;
  }

  get selectedSortOrder() {
    return this.searchParams?.sortOrder || this.sortOrder;
  }

  get selectedSortKey() {
    return this.searchParams?.sortKey || this.sortKey;
  }

  get anySearchQuery(): boolean {
    return !!this.searchParams?.q;
  }

  get visibleHeaders() {
    return this.headers
      .map((header) => {
        if (header.id === 'ps') {
          return {
            ...header,
            visible: this.isAllSchemesView,
            enableSort: header.enableSort && !this.$route.query.q,
          };
        }
        return {
          ...header,
          enableSort: header.enableSort && !this.$route.query.q,
        };
      })
      .filter((header) => header.visible);
  }

  get psoColsBp() {
    return {
      s: 2,
      m: 2,
      l: 2,
      xl: 2,
      xxl: 2,
    };
  }

  public setSortOrder({ sortOrder, sortKey }) {
    this.updateSearchParams({
      ...this.searchParams,
      sortOrder,
      sortKey,
      page: undefined,
    });
  }

  mounted() {
    this.updateSearchParams = updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme?.title,
    );
  }

  @Watch('$route.query', { immediate: true, deep: false })
  onUrlChange(query) {
    if (query && query.sortKey && query.sortOrder) {
      this.headers = this.headers.map((x) => {
        if (x.id === query.sortKey) {
          return { ...x, isSortActive: true, sortOrder: query.sortOrder };
        }
        return { ...x, isSortActive: false, sortOrder: 'none' };
      });
    }

    if (query && query.page) {
      this.currentPage = Number(query.page - 1);
    }

    if (query && query.limit) {
      this.pageSize = Number(query.limit);
    }
  }
}
