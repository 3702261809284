import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { LoaderStates } from '@/store/loading';
import { api } from '@/config';
import { logger } from '@/helpers';
import {
  DateFilter,
  IncorporatedDocumentState,
} from '@/store/incorporated-documents/types';
import { convertToIncorporatedDocuments } from '@/store/incorporated-documents/convert-to-domain';
import { Sorting } from '@/store/schemes/types';

export const actions: ActionTree<IncorporatedDocumentState, RootState> = {
  async fetchDocumentsList({ commit }, { schemeCode }): Promise<void> {
    const client = (await api).schemesClient;
    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    try {
      const res = await client.fetchSchemeIncorporatedDocuments(schemeCode);
      if (res.status === 200) {
        commit('setDocuments', convertToIncorporatedDocuments(res.data.docs));
        commit('loading/setState', LoaderStates.COMPLETED, { root: true });
      }
    } catch (error) {
      logger.error(
        'Error while fetching incorporated documents',
        error.message,
      );
      commit('loading/setState', LoaderStates.ERROR, { root: true });
    }
  },
  async setSearchTerm({ commit }, payload: string): Promise<void> {
    commit('setSearchTerm', payload);
  },
  async setDateFilter({ commit }, payload: DateFilter): Promise<void> {
    commit('setDateFilter', payload);
  },
  async setPageSize({ commit }, payload: number): Promise<void> {
    commit('setPageSize', payload);
  },
  async setSortParams({ commit }, payload: Sorting | undefined): Promise<void> {
    commit('setSortParams', payload);
  },
  async setStatusFilter({ commit }, payload: string[]): Promise<void> {
    commit('setSelectedStatusOptions', payload);
  },
  async setTypeFilter({ commit }, payload: string[]): Promise<void> {
    commit('setSelectedTypeOptions', payload);
  },
};
