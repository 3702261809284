























































































import Vue from 'vue';
import { Action } from 'vuex-class';

import { RplCol } from '@dpc-sdp/ripple-grid';
import { Component, Prop } from 'vue-property-decorator';

import RplDocumentLink from '@/components/shared/DocumentLink.vue';
import { setUpFormAccessibilityOptions } from '@/helpers/accessibilityOptions';
import RplForm from '@dpc-sdp/ripple-form';
import { RplDivider } from '@dpc-sdp/ripple-global';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplTextLink } from '@dpc-sdp/ripple-link';

@Component({
  components: {
    RplDivider,
    RplForm,
    RplCol,
    RplTextLink,
    RplDocumentLink,
    RplIcon,
  },
})
export default class SchemeSearchAndDocuments extends Vue {
  @Action('setSelectedHistoricalAmendmentStatus', { namespace: 'schemes' })
  private setSelectedStatus;

  @Action('setSelectedHistoricalAmendmentOutcome', { namespace: 'schemes' })
  private setSelectedOutcome;

  private defaultOutcomeFilters = [
    'Approved',
    'ApprovedWithChanges',
    'Revoked',
    'ApprovedByPlanningAuthority',
  ];

  private defaultStatusFilters = ['Gazetted', 'Finished'];

  public searchQuery = {
    model: {
      text: '',
    },
    schema: {
      fields: [
        {
          label: 'Search',
          attributes: {
            'aria-label': 'search',
            autocomplete: 'off',
          },
          type: 'input',
          inputType: 'text',
          styleClasses: [
            'rpl-form__prepend-icon',
            'rpl-form__prepend-icon--search',
          ],
          placeholder: 'keyword, clause number or amendment number',
          model: 'text',
        },
      ],
    },
    tag: 'rpl-fieldset',
    formState: {},
  };

  public isOpen = false;

  @Prop() public amListPDF;

  @Prop() public schemePDF;

  @Prop() public pdfDownloadName;

  @Prop() public scheme;

  get searchDestination() {
    return this.$route.name === 'histories'
      ? 'historiesSearch'
      : 'ordinanceSearch';
  }

  public toggleOpen(e) {
    e.preventDefault();
    this.isOpen = !this.isOpen;
  }

  public clearSearch() {
    this.searchQuery.model.text = '';
  }

  public enterClicked(): void {
    const searchQuery = this.searchQuery?.model?.text;
    if (searchQuery) {
      const trimmedQuery = searchQuery.trim();

      this.$store.dispatch('SET_CURRENTPAGE_ACTION', null);
      this.$router.push({
        name: this.searchDestination,
        params: {
          schemeName: this.scheme.title,
        },
        query: {
          schemes: this.scheme.schemeID,
          q: trimmedQuery,
        },
      });
    }
  }

  public goToHistories() {
    this.setSelectedStatus(this.defaultStatusFilters);
    this.setSelectedOutcome(this.defaultOutcomeFilters);
    this.$router.push({
      name: 'histories',
    });
  }

  mounted() {
    this.$nextTick(() => {
      setUpFormAccessibilityOptions(
        this.$refs.searchForm,
        'search form',
        'clear search',
      );
    });
  }
}
