

















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';

import SchemeAutocomplete from '@/components/header-footer/SchemeAutocomplete.vue';
import { OrdinanceSchemeDetails } from '@/models';

const stageExpand = 'Expand';
const stageCollapse = 'Collapse';

@Component({
  components: {
    RplIcon,
    SchemeAutocomplete,
  },
})
export default class SchemeSelector extends Vue {
  private isOpen = false;

  get accordionCollapsed() {
    return {
      collapsed: !this.isOpen,
    };
  }

  get expandAction() {
    return this.isOpen ? stageCollapse : stageExpand;
  }

  toggleAccordion(e) {
    e.preventDefault();

    this.isOpen = !this.isOpen;
  }

  @Getter('allSchemes')
  private allSchemes!: Array<OrdinanceSchemeDetails>;

  get schemes(): Array<string> {
    return this.allSchemes.map((it) => it.title);
  }
}
