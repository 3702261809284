export const statusOptionsWithLabels = [
  {
    key: 'Under Exhibition',
    label: 'Under exhibition',
  },
  {
    key: 'Submissions Under Assessment',
    label: 'Submissions under assessment',
  },
  {
    key: 'Panel Requested',
    label: 'Panel requested',
  },
  {
    key: 'Panel Appointed',
    label: 'Panel appointed',
  },
  {
    key: 'Panel Hearing',
    label: 'Panel hearing',
  },
  {
    key: 'Split Parent',
    label: 'Split parent',
  },
  {
    key: 'Adoption Under Consideration',
    label: 'Adoption under consideration',
  },
  {
    key: 'Adopted',
    label: 'Adopted',
  },
  {
    key: 'Approval Under Consideration',
    label: 'Approval under consideration',
  },
  {
    key: 'PreGazettal',
    label: 'Pre-gazettal',
  },
  {
    key: 'Gazetted',
    label: 'Gazetted',
  },
  {
    key: 'Finished',
    label: 'Finished',
  },
];

export const outcomeOptions = [
  {
    key: 'Abandoned',
    label: 'Abandoned',
  },
  {
    key: 'Approved',
    label: 'Approved',
  },
  {
    key: 'ApprovedByPlanningAuthority',
    label: 'Approved by planning authority',
  },
  {
    key: 'ApprovedWithChanges',
    label: 'Approved with changes',
  },
  {
    key: 'Lapsed',
    label: 'Lapsed',
  },
  {
    key: 'Refused',
    label: 'Refused',
  },
  {
    key: 'Revoked',
    label: 'Revoked',
  },
  {
    key: 'SplitParent',
    label: 'Split parent',
  },
];

export const amendmentType = [
  {
    key: 'C',
    label: 'C - Council',
  },
  {
    key: 'GC',
    label: 'GC - Group of councils',
  },
  {
    key: 'V',
    label: 'V - Victoria Planning Provisions',
  },
  {
    key: 'VC',
    label: 'VC - Victoria Planning Provisions and one or more planning schemes',
  },
  {
    key: 'L',
    label: 'L - Local',
  },
  {
    key: 'SL',
    label: 'SL - State and Local',
  },
  {
    key: 'AMA',
    label: 'AMA - Amalgamated planning schemes',
  },
  {
    key: 'NPS',
    label: 'NPS - New format planning schemes',
  },
];

const classificationOptions = [
  {
    key: 'Exhibited',
    label: 'Exhibited',
  },
  {
    key: 'Ministerial',
    label: 'Ministerial - S20(4)',
  },
  {
    key: 'Prescribed',
    label: 'Prescribed - S20(A)',
  },
];

export const optionsWithLabels = {
  amendment: amendmentType,
  category: [],
  outcome: outcomeOptions,
  status: statusOptionsWithLabels,
  classification: classificationOptions,
};
