














import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CopyHistorical extends Vue {
  @Prop()
  historical;

  get color() {
    return this.historical ? '#017d7d' : '#00B2A9';
  }
}
