











import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class Tabs extends Vue {
  @Prop() public schemeName;

  public mobileBreakPoint = 992;

  public openState = false;

  get tabs(): { name: string; route: object }[] {
    const { schemeName } = this;
    return schemeName
      ? [
          {
            name: 'Ordinance',
            route: { name: 'ordinance', params: { schemeName } },
          },
          { name: 'Maps', route: { name: 'maps', params: { schemeName } } },
          {
            name: 'Incorporated documents',
            route: {
              name: 'docs',
              params: { schemeName },
              query: { page: 1, status: 'current' },
            },
          },
          {
            name: 'Amendments',
            route: {
              name: 'amendments',
              params: { schemeName },
            },
          },
          {
            name: 'Histories',
            route: {
              name: 'histories',
              params: { schemeName },
            },
          },
        ].filter((tab) =>
          schemeName === 'Victoria Planning Provisions'
            ? tab.name !== 'Maps'
            : true,
        )
      : [];
  }

  public isMobile() {
    const clientWidth = window.innerWidth;

    if (clientWidth > this.mobileBreakPoint) {
      this.openState = true;
    } else {
      this.openState = false;
    }
  }

  public setUpObserver() {
    const el = document.querySelector('.pso-tabs');
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { rootMargin: '-1px 0px 0px 0px', threshold: [1] },
    );

    if (el) {
      observer.observe(el);
    }
  }

  get isOnHistory() {
    return this.$route.path.includes('histories');
  }

  mounted() {
    this.setUpObserver();
    window.addEventListener('resize', this.isMobile);
    this.isMobile();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.isMobile);
  }

  @Watch('$route', { immediate: false, deep: false })
  onUrlChange() {
    this.setUpObserver();
  }
}
