






















































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import CloseButton from '@/components/shared/CloseButton.vue';
import { MultiSelectConfig, SelectOption } from '@/models';
import RplCheckbox from '@dpc-sdp/ripple-form/Checkbox.vue';
import RplIcon from '@dpc-sdp/ripple-icon';

@Component({
  components: {
    CloseButton,
    RplIcon,
    RplCheckbox,
  },
})
export default class MultipleSelect extends Vue {
  @Prop() items!: SelectOption[];

  @Prop() config!: MultiSelectConfig;

  public isOpen = false;

  public focussed: SelectOption = {
    id: '',
    name: '',
    selected: false,
    focussed: false,
    uuid: '',
    disabled: false,
  };

  public clickOutsideCB: (() => void) | undefined;

  public value: string[] = [];

  public text = '';

  public state = [];

  get options(): SelectOption[] {
    return this.items
      .map((opt) => ({
        ...opt,
        focussed: this.focussed ? opt.id === this.focussed.id : false,
        uuid: this.createUniqueId(opt) as string,
      }))
      .filter((item) =>
        item
          ? item.name.toLowerCase().indexOf(this.text.toLowerCase()) > -1
          : false,
      );
  }

  get selectedNoAllLength() {
    return this.selectedItems.filter((i) => i.id !== 'all').length;
  }

  get activedescendant() {
    return this.focussed && this.createUniqueId(this.focussed);
  }

  get selectedItems() {
    return this.options.filter((opt) => opt.selected);
  }

  get selectedTitles() {
    return this.value
      .slice(
        0,
        this.selectedItems.length > this.config.showItems
          ? 1
          : this.selectedItems.length,
      )
      .map((itm) => {
        const itemName = this.options.find((z) => z.id === itm);
        return itemName?.name;
      })
      .join(', ');
  }

  public isAriaSelected(item) {
    if (item.selected) {
      return 'true';
    }
    return 'false';
  }

  public createUniqueId(opt) {
    if (opt) {
      return `${opt.id.replace(/ /g, '-')}`;
    }
    return null;
  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.onClickOutside(this.close);
      this.$nextTick(() => {
        if (this.$refs.input as HTMLInputElement) {
          (this.$refs.input as HTMLInputElement).focus();
        }
      });
    } else {
      this.removeOutsideTest();
    }
  }

  public close() {
    this.isOpen = false;
  }

  public selectItem(item) {
    if (item.id === 'all') {
      if (
        this.value.filter((x) => x !== 'all').length ===
        this.options.filter((z) => z.id !== 'all').length
      ) {
        this.value = [];
      } else {
        this.value = this.options.map((a) => a.id);
      }
    } else {
      const index = this.value.findIndex(
        (selectedItem) => selectedItem === item.id,
      );

      if (index > -1) {
        this.value.splice(index, 1);
        const allIndex = this.value.findIndex(
          (selectedItem) => selectedItem === 'all',
        );
        if (allIndex > -1) {
          this.value.splice(allIndex, 1);
          const all = this.options.find((i) => i.id === 'all');
          all!.selected = false;
        }
      } else {
        this.value.push(item.id);
      }
    }
    const selectedSchemeCodes = this.value
      .filter((a) => a !== 'all')
      .toString();

    this.$emit('multipleSelection', selectedSchemeCodes);
  }

  public focusItem(selected) {
    if (selected) {
      let item;
      this.focussed = selected;
      const listbox = this.$refs.listbox as HTMLElement;

      if (selected && selected.uuid) {
        item = this.$el.querySelector(`#${selected.uuid}`) as HTMLElement;
      }

      if (listbox.scrollHeight > listbox.clientHeight && item) {
        const scrollBottom = listbox.clientHeight + listbox.scrollTop;
        const elementBottom = item.offsetTop + item.offsetHeight;

        if (elementBottom > scrollBottom) {
          listbox.scrollTop = elementBottom - listbox.clientHeight;
        } else if (item.offsetTop < listbox.scrollTop) {
          listbox.scrollTop = item.offsetTop + listbox.clientHeight;
        }
      }

      if (item) {
        this.$nextTick(() => {
          item.focus();
        });
      }
    }
  }

  public handleKeys(e) {
    let selected: SelectOption;

    const selectedIdx = this.options.findIndex((opt) => opt.focussed);

    switch (e.keyCode) {
      case 38: // Up key
        selected = this.options[selectedIdx - 1];
        if (selectedIdx === 0) {
          (this.$refs.input as HTMLInputElement).focus();
        }
        this.focusItem(selected);

        break;
      case 40: // Down key
        if (this.options.length > selectedIdx + 1) {
          selected = this.options[selectedIdx + 1];

          this.focusItem(selected);
        }
        break;
      default:
        this.focusItem({});
    }
  }

  public preventKeyboardScroll(e) {
    const keys = ['Up', 'ArrowUp', 'Down', 'ArrowDown', ' ', 'Space', 'Tab'];

    if (this.isOpen && keys.includes(e.key)) {
      e.preventDefault();
    } else if (
      document.activeElement ===
        this.$el.querySelector('.rpl-select__trigger') &&
      [' ', 'Space'].includes(e.key)
    ) {
      e.preventDefault();
    }
  }

  public onTab(evt) {
    if (this.text) {
      evt.stopPropagation();
    }
  }

  public onClear() {
    this.text = '';
    (this.$refs.input as HTMLInputElement).focus();
  }

  public onClickOutside(cbFunc) {
    this.clickOutsideCB = cbFunc;
    this.addOutsideTest();
  }

  public addOutsideTest() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.testOutside);
    }
  }

  public testOutside(event) {
    if (typeof window !== 'undefined') {
      if (!this.$el.contains(event.target)) {
        if (this.clickOutsideCB && typeof this.clickOutsideCB === 'function') {
          this.clickOutsideCB();
        }
        this.removeOutsideTest();
      }
    }
  }

  public removeOutsideTest() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.testOutside);
    }
  }

  mounted() {
    document.body.addEventListener('keydown', this.preventKeyboardScroll);
  }

  beforeDestroy() {
    document.body.removeEventListener('keydown', this.preventKeyboardScroll);
  }

  @Watch('items', { immediate: true, deep: true })
  setupSelected(items) {
    this.value = items.filter((item) => item.selected).map((item) => item.id);
  }
}
