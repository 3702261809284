import { features } from '@/config';

function get<T>(sub: T, key: string): any {
  const parts = key.split('.');
  parts.forEach((part) => {
    // eslint-disable-next-line no-param-reassign
    sub = sub[part];
  });
  return sub;
}

const featureEnabled = async (key: string): Promise<boolean> => {
  try {
    const sub = await features;
    const result = get(sub, key);
    return result === true;
  } catch {
    return false;
  }
};

export { featureEnabled };
