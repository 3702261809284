














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import StageHeader from './StageHeader.vue';
import StageDetails from './StageDetails.vue';

import {
  getStageDetails,
  getStageIcon,
  mapOtherDocuments,
} from './stage-details';
import { exhibitionAndGazettalLabels } from './stage-details-labels';
import { GazettalDetails, StageStatus } from './stages.model';
import { getGazettalStatus, getGazettalData } from './gazettal-data';

@Component({
  components: {
    StageHeader,
    StageDetails,
  },
})
export default class Gazettal extends Vue {
  @Prop() model!: GazettalDetails;

  get gazettalStatus() {
    return getStageIcon(getGazettalStatus(this.model));
  }

  get isGazetted() {
    return getGazettalStatus(this.model) === StageStatus.COMPLETE;
  }

  get gazettalData() {
    return getGazettalData(this.model);
  }

  get gazettalDocuments() {
    return getStageDetails(this.model.gazettal!, exhibitionAndGazettalLabels);
  }

  get mappedOtherDocumentsDetails() {
    return mapOtherDocuments(this.model.gazettal!);
  }

  get allGazettalDetails() {
    return [...this.gazettalDocuments, ...this.mappedOtherDocumentsDetails];
  }
}
