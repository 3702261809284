
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {},
})
export default class FocusItem extends Vue {
  @Getter('focusedItem', { namespace: 'schemes' })
  private focusedItem;

  @Action('setFocusedItem', { namespace: 'schemes' })
  private setFocusedItem;

  @Watch('focusedItem', { immediate: true, deep: false })
  changeFocus(focusToElement) {
    if (focusToElement && focusToElement !== '') {
      Vue.nextTick(() => {
        const focusElement = document.querySelector(
          focusToElement,
        ) as HTMLElement;

        if (focusElement) {
          focusElement.focus();
          this.setFocusedItem('');
        }
      });
    }
  }
}
