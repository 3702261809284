













import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplPagination from '@dpc-sdp/ripple-pagination';
import { Action } from 'vuex-class';

@Component({
  components: {
    RplRow,
    RplCol,
    RplPagination,
  },
})
export default class PSOPagination extends Vue {
  @Prop({ default: 0 }) pageCount!: number;

  @Prop({ default: 0 }) currentPage!: number;

  @Prop({ default: 2 }) stepsAround!: number;

  @Action('setFocusedItem', { namespace: 'schemes' })
  private setFocused;

  firstStep = 1;

  public handleStepChange(step: number): void {
    this.$emit('onPageChange', step - 1);

    this.setFocused('#item-0');
  }

  mounted() {
    this.firstStep = this.currentPage + 1;
  }

  @Watch('currentPage')
  handleCurrentPageChange(newPage: number): void {
    this.firstStep = newPage;
    this.$nextTick(() => {
      this.firstStep = newPage + 1;
      this.setFocused('');
    });
  }
}
