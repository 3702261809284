
















import Vue from 'vue';

import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FilterSection from '@/components/filter/FilterSection.vue';
import { DateFilter } from '@/store/incorporated-documents/types';
import PSODatePicker from '../shared/dates/PSODatePicker.vue';

@Component({
  components: {
    FilterSection,
    PSODatePicker,
  },
})
export default class DocumentsDateFilter extends Vue {
  @Getter('getDateFilter', { namespace: 'incorporatedDocument' })
  dates!: DateFilter;

  get fromStoreFrom() {
    return this.dates.from;
  }

  get fromStoreTo() {
    return this.dates.to;
  }

  public submitFrom({ from }): void {
    this.$emit('on-date-change', {
      from,
      to: this.fromStoreTo,
    });
  }

  public submitTo({ to }): void {
    this.$emit('on-date-change', {
      from: this.fromStoreFrom,
      to,
    });
  }
}
