

























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';

import ListResultInfo from '@/components/shared/ListResultInfo.vue';
import PSOPagination from '@/components/shared/PSOPagination.vue';
import { split } from '@/helpers/array-split';
import SortableButton from '@/components/shared/SortableButton.vue';
import { Sorting } from '@/store/schemes/types';
import { TableHeader } from '@/models/table-header';
import SortMobile from '@/components/shared/SortMobile.vue';
import { IncorporatedDocument } from '@/store/incorporated-documents/types';
import { scrollToElement } from '@/helpers/scroll-to-element';
import DocumentListRow from './DocumentListRow.vue';

@Component({
  components: {
    SortableButton,
    ListResultInfo,
    RplCol,
    RplRow,
    DocumentListRow,
    PSOPagination,
    SortMobile,
  },
})
export default class DocumentsList extends Vue {
  @Prop() searchTerm!: string;

  @Prop() headers!: TableHeader[];

  @Prop() list!: IncorporatedDocument[];

  @Prop() totalCount!: number;

  @Prop() currentPage!: number;

  @Prop() pageSize!: number;

  @Prop() sortParams!: Sorting | undefined;

  public chunked: IncorporatedDocument[][] = [];

  get startRecord(): number {
    return this.currentPage * this.pageSize;
  }

  get endRecord(): number {
    const start = this.currentPage * this.pageSize;
    const results = this.paginatedResults;
    return start + results.length;
  }

  get paginatedResults(): IncorporatedDocument[] {
    const val = this.chunked[this.currentPage];
    if (val) {
      return val;
    }
    return [];
  }

  public getColumnWidth(index) {
    return {
      l: index === 0 ? 4 : 2,
      m: index === 0 ? 4 : 2,
      s: index === 0 ? 4 : 2,
    };
  }

  public paginationChanged(val: number) {
    this.$emit('onPaginationChange', val);
    scrollToElement('.app');
  }

  onSortChange(event: Sorting | undefined) {
    this.$emit('on-sort-change', event);
  }

  mounted() {
    this.chunked = split(this.list, this.pageSize);
  }

  @Watch('list')
  onListChange() {
    this.chunked = split(this.list, this.pageSize);
  }
}
