import { ActionTree } from 'vuex';
import { api } from '@/config';
import { logger } from '@/helpers';
import { convertToSchemePDFMaps } from '@/store/schemes/convert-to-domain';
import { MapState } from './types';
import { RootState } from '../types';

export const actions: ActionTree<MapState, RootState> = {
  async fetchSchemePDFMaps({ commit }, schemeId): Promise<void> {
    const client = (await api).schemesClient;
    try {
      const res = await client.fetchSchemePDFMaps(schemeId);
      if (res.status === 200) {
        commit('setSchemePDFMaps', convertToSchemePDFMaps(res.data.maps));
      }
    } catch (e) {
      commit('setSchemePDFMaps', { error: true });
      logger.error('Error while fetching historic ordinance', e.message);
    }
  },
};
