export const searchResultStyle = (): object => ({
  color: 'black',
  fontFamily: 'VIC-regular, Arial, Helvetica, sans-serif',
  backgroundColor: '#CEDC00',
});

export const isQuoted = (value: string): boolean =>
  value.startsWith('"') && value.endsWith('"');

export const dropQuotes = (value: string): string =>
  value.substring(1, value.length - 1);

export const transformKeywords = (value: string): string[] =>
  (value || '')
    .toLowerCase()
    .split(/\s+/)
    .filter((v) => v !== '');

export const extractSearchKeywords = (queryString: string): string[] =>
  isQuoted(queryString)
    ? [dropQuotes(queryString)]
    : transformKeywords(queryString);
