













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { StageHeaderData, StageDetailsType } from './stages.model';

@Component({
  components: {
    SvgIcon,
  },
})
export default class StageHeader extends Vue {
  @Prop() data!: StageHeaderData[];

  @Prop() status!: string;

  @Prop() title!: string;

  get dateType() {
    return StageDetailsType.Date;
  }

  get iconName() {
    /* FIXME: the initial implementation is to pass Symbol to status prop.
    However, the test is broken due to a known bug in vue-test-util.
    https://github.com/vuejs/vue-test-utils/issues/1833

    return getStageIcon(this.status);
     */
    return this.status;
  }
}
