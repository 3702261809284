




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { exhibitionAndGazettalLabels } from './stage-details-labels';
import {
  getStageDetails,
  mapOtherDocuments,
  getStageIcon,
} from './stage-details';
import StageDetails from './StageDetails.vue';
import StageHeader from './StageHeader.vue';

import {
  exhibitionEndData,
  exhibitionStartData,
  getExhibitionStatus,
} from './exhibition';

import { StageStatus } from './stages.model';

@Component({
  components: {
    StageDetails,
    StageHeader,
  },
})
export default class Exhibition extends Vue {
  @Prop() model;

  get exhibitionStartStatus() {
    switch (getExhibitionStatus(this.model)) {
      case StageStatus.NOT_REQUIRED:
        return getStageIcon(StageStatus.NOT_REQUIRED);
      default:
        return getStageIcon(StageStatus.COMPLETE);
    }
  }

  get exhibitionEndStatus() {
    return getStageIcon(getExhibitionStatus(this.model));
  }

  get isExhibitionRequired() {
    return getExhibitionStatus(this.model) !== StageStatus.NOT_REQUIRED;
  }

  get exhibitionDetails() {
    return getStageDetails(this.model.exhibition, exhibitionAndGazettalLabels);
  }

  get mappedOtherDocumentsDetails() {
    return mapOtherDocuments(this.model.exhibition);
  }

  get allExhibitionDetails() {
    return [...this.exhibitionDetails, ...this.mappedOtherDocumentsDetails];
  }

  get exhibitionStartData() {
    return exhibitionStartData(this.model);
  }

  get exhibitionEndData() {
    return exhibitionEndData(this.model);
  }
}
