/**
 * split([1, 2, 3, 4, 5, 6, 7, 8, 9], 3); // [[1, 2, 3], [4, 5, 6], [7, 8, 9]]
 * @param arr
 * @param at
 */
export function split<T>(arr: T[], at: number): T[][] {
  const len = arr.length;
  const groups: T[][] = [];
  for (let i = 0; i < len; i += at) {
    groups.push(arr.slice(i, i + at));
  }
  return groups;
}
