












































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { SelectedScheme } from '@/models';
import { RplDivider } from '@dpc-sdp/ripple-global';

import ContentLayout from '@/components/shared/ContentLayout.vue';
import Loader from '@/components/shared/Loader.vue';
import { isDate, isNumber, validateString } from '@/helpers/validation-utils';
import FocusItem from '@/mixins/FocusItem.vue';
import { TableHeader } from '@/models/table-header';
import {
  DateFilter,
  IncorporatedDocument,
} from '@/store/incorporated-documents/types';
import { SortOrder, Sorting } from '@/store/schemes/types';
import { RplCol } from '@dpc-sdp/ripple-grid';
import dayjs from 'dayjs';
import Router, { Route } from 'vue-router';
import DocumentsList from './DocumentsList.vue';
import DocumentsSearchFilter from './DocumentsSearchFilter.vue';
import DocumentsSidebar from './DocumentsSidebar.vue';

const { isNavigationFailure } = Router;

@Component({
  mixins: [FocusItem],
  components: {
    ContentLayout,
    DocumentsList,
    DocumentsSearchFilter,
    Loader,
    DocumentsSidebar,
    RplDivider,
    RplCol,
  },
})
export default class IncorporatedDocuments extends Vue {
  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('getDateFilter', { namespace: 'incorporatedDocument' })
  dateFilter!: DateFilter;

  @Getter('getFilteredDocuments', { namespace: 'incorporatedDocument' })
  private documents!: IncorporatedDocument[];

  @Getter('getSearchTerm', { namespace: 'incorporatedDocument' })
  private searchTerm?: string;

  @Getter('getSortParams', { namespace: 'incorporatedDocument' })
  private sortParams!: Sorting | undefined;

  @Getter('getPageSize', { namespace: 'incorporatedDocument' })
  pageSize!: number;

  @Getter('getSelectedStatus', { namespace: 'incorporatedDocument' })
  private selectedStatus!: string[];

  @Action('fetchDocumentsList', { namespace: 'incorporatedDocument' })
  private fetchDocumentsList!: (payload: { schemeCode: string }) => void;

  @Action('setSearchTerm', { namespace: 'incorporatedDocument' })
  private setSearchTerm!: (query: string) => void;

  @Action('setSortParams', { namespace: 'incorporatedDocument' })
  private setSortParams!: (sortParams: Sorting | undefined) => void;

  @Action('setDateFilter', { namespace: 'incorporatedDocument' })
  private setDateFilters!: (payload: DateFilter | undefined) => void;

  @Action('setPageSize', { namespace: 'incorporatedDocument' })
  private setPageSize!: (payload: number) => void;

  @Action('setTypeFilter', { namespace: 'incorporatedDocument' })
  private setSelectedType!: (payload: string[]) => void;

  @Action('setStatusFilter', { namespace: 'incorporatedDocument' })
  private setSelectedStatus!: (payload: string[]) => void;

  private popUpText = 'Press [Enter] to filter out document list.';

  private helpText = 'For example: Amendment number or document name';

  currentPage = 0;

  public perPageOptions = [20, 50, 100];

  public headers: TableHeader[] = [
    {
      id: 'documentName',
      text: 'Incorporated document',
      enableSort: true,
      sortOrder: SortOrder.DESC,
      isSortActive: false,
    },
    {
      id: 'amendmentNo',
      text: 'Amendment No',
      enableSort: true,
      sortOrder: SortOrder.DESC,
      isSortActive: false,
    },
    {
      id: 'operationalDate',
      text: 'Date of operation',
      enableSort: true,
      sortOrder: SortOrder.DESC,
      isSortActive: true,
    },
    {
      id: 'type',
      text: 'Document type',
      enableSort: true,
      isSortActive: false,
    },
    {
      id: 'status',
      text: 'Status',
      enableSort: true,
      isSortActive: false,
    },
  ];

  get count(): number {
    return this.documents.length;
  }

  get schemeName() {
    return this.selectedScheme.title;
  }

  get isSearchResultEmpty() {
    return this.documents.length === 0;
  }

  public clearSearch(): void {
    this.removeQueryParams(['query', 'page']);
  }

  public enterClicked(query: string): void {
    if (query !== '') this.setQueryParams({ query, page: '1' });
  }

  public onPaginationChange(page: number): void {
    this.setQueryParams({ page: (page + 1).toString() });
  }

  public handleStatusChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({ page: '1', status: payload });
    } else {
      this.removeQueryParams(['page', 'status']);
    }
  }

  public handleTypeChange(payload: string) {
    if (payload.length !== 0) {
      this.setQueryParams({ page: '1', type: payload });
    } else {
      this.removeQueryParams(['page', 'type']);
    }
  }

  public handleFiltersReset() {
    this.removeQueryParams(['from', 'to', 'type', 'status', 'page']);
    // this.setQueryParams({ status: 'current' });
  }

  public handleDateChange(payload: DateFilter | undefined) {
    if (payload) {
      const { from, to } = payload;
      const params: Map<string, string> = new Map<string, string>();
      if (typeof from === 'string' && isDate(from)) {
        params.set('from', dayjs(from).format('YYYY-MM-DD'));
      } else {
        params.set('from', '');
      }
      if (typeof to === 'string' && isDate(to)) {
        params.set('to', dayjs(to).format('YYYY-MM-DD'));
      } else {
        params.set('to', '');
      }
      if (params.size > 0) {
        params.set('page', '1');
        this.setQueryParams(Object.fromEntries(params));
      }
    }
  }

  public handlePageSize(limit: number) {
    this.setQueryParams({ page: '1', limit: limit.toString() });
  }

  setQueryParams(params: Record<string, string>): void {
    this.$router
      .push({
        query: {
          ...this.$route.query,
          ...params,
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  removeQueryParams(params: string[]): void {
    const opts = { ...this.$route.query };
    params.forEach((param) => delete opts[param]);
    this.$router
      .replace({ query: { status: 'current', page: '1', ...opts } })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  updateTableHeaders(event: Sorting) {
    this.headers = this.headers.map((x) => {
      if (x.id === event.sortKey) {
        return { ...x, sortOrder: event.sortOrder, isSortActive: true };
      }
      return { ...x, sortOrder: SortOrder.NONE, isSortActive: false };
    });
  }

  onSortChange(event: Sorting | undefined) {
    if (event) {
      const params = {
        ...event,
        page: '1',
      };
      this.setQueryParams(params);
    } else {
      this.removeQueryParams(['sortKey', 'sortOrder', 'page']);
    }
  }

  onMountSetSort(
    sortKey: string | (string | null)[],
    sortOrder: string | (string | null)[],
  ) {
    const possiblesSortKeys = this.headers
      .filter((x) => x.enableSort)
      .map((x) => x.id);
    if (
      validateString(possiblesSortKeys, sortKey) &&
      validateString([SortOrder.ASC, SortOrder.DESC], sortOrder)
    ) {
      const sorting = {
        sortKey,
        sortOrder,
      } as Sorting;
      this.updateTableHeaders(sorting);
      this.setSortParams(sorting);
    } else {
      const event = {
        sortKey: 'operationalDate',
        sortOrder: SortOrder.DESC,
      } as Sorting;
      this.updateTableHeaders(event);
      this.setSortParams(event);
    }
  }

  onMountSetSearchQuery(query: string | (string | null)[]) {
    if (query && typeof query === 'string' && query !== '') {
      this.setSearchTerm(query);
    } else {
      this.setSearchTerm('');
    }
  }

  onMountSetPagination(page: string | (string | null)[]) {
    if (page && typeof page === 'string' && isNumber(page)) {
      const number = parseInt(page as string, 10);
      if (number >= 1) {
        this.currentPage = number - 1;
        return;
      }
    }
    this.resetCurrentPage();
  }

  onMountSetPageSize(pageSize: string | (string | null)[]) {
    if (pageSize && typeof pageSize === 'string' && isNumber(pageSize)) {
      const number = parseInt(pageSize as string, 10);
      if (number >= this.perPageOptions[0]) {
        this.setPageSize(number);
        return;
      }
    }
    this.setPageSize(this.perPageOptions[0]);
  }

  onMountSetDate(payload: {
    from: string | (string | null)[];
    to: string | (string | null)[];
  }) {
    if (payload.from || payload.to) {
      const { from, to } = payload;
      const params: Map<string, string> = new Map<string, string>();

      if (typeof from === 'string' && isDate(from)) {
        params.set('from', dayjs(from).format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        params.set('from', '');
      }
      if (typeof to === 'string' && isDate(to)) {
        params.set('to', dayjs(to).format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        params.set('to', '');
      }
      this.setDateFilters(payload as DateFilter);
    } else {
      this.setDateFilters({ from: '', to: '' });
    }
  }

  onMountSetType(type: string | (string | null)[]) {
    if (type && type.length > 0 && typeof type === 'string') {
      this.setSelectedType(type.split(','));
    } else {
      this.setSelectedType([]);
    }
  }

  onMountSetStatus(statuses: string | (string | null)[]) {
    if (statuses && statuses.length > 0 && typeof statuses === 'string') {
      this.setSelectedStatus(statuses.split(','));
    } else {
      this.setSelectedStatus(['']);
    }
  }

  resetAllConfig() {
    const event = {
      sortKey: 'operationalDate',
      sortOrder: SortOrder.DESC,
    } as Sorting;
    this.updateTableHeaders(event);
    this.setSortParams(event);
    this.setSearchTerm('');
    this.resetCurrentPage();
    this.setDateFilters({ from: '', to: '' });
    this.setSelectedType([]);
    this.setSelectedStatus(['current']);
    this.setPageSize(this.perPageOptions[0]);
  }

  public resetCurrentPage() {
    this.currentPage = 0;
  }

  public isLimitActive(limit) {
    return limit === this.pageSize;
  }

  public isLastElement(limit): boolean {
    return limit !== this.perPageOptions.slice(-1).pop();
  }

  async mounted() {
    await this.fetchDocumentsList({
      schemeCode: this.selectedScheme.schemeID,
    });
  }

  beforeDestroy() {
    this.resetAllConfig();
  }

  @Watch('$route', { immediate: true, deep: false })
  async onRouteChanged(newRoute: Route) {
    const { sortKey, sortOrder, page, query, from, to, type, status, limit } =
      newRoute.query;

    this.onMountSetSort(sortKey, sortOrder);
    this.onMountSetSearchQuery(query);
    this.onMountSetPageSize(limit);
    this.onMountSetPagination(page);
    this.onMountSetDate({ from, to });
    this.onMountSetType(type);
    this.onMountSetStatus(status);
  }
}
