import { RippleDocumentLink } from '@/models';
import {
  planningSchemePdfDocName,
  calculateFileSize,
} from '@/helpers/document';

export const makeAmListPDF = (
  amListPDFUrl,
  schemeID,
  planningSchemeTitle,
): RippleDocumentLink | undefined =>
  schemeID
    ? {
        name:
          schemeID === 'vpp'
            ? 'List of amendments to the Victoria Planning Provisions'
            : `View ${planningSchemeTitle} List of approved amendments`,
        url: `${amListPDFUrl}${
          schemeID === 'vpp' ? 'amlist' : `amlist_s_${schemeID}`
        }.pdf`,
        extension: 'pdf',
      }
    : undefined;

export const makeSchemePdf = (
  planningSchemeTitle,
  documentSize,
  url,
): RippleDocumentLink => ({
  name: planningSchemePdfDocName(planningSchemeTitle),
  url,
  extension: 'pdf',
  filesize: calculateFileSize(documentSize),
});
