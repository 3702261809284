


















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AmendmentNotFound extends Vue {
  @Prop() history;

  private showFeedback(): void {
    document.getElementById('atlwdg-trigger')!.click();
  }
}
