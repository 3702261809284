import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import { AlertBannerState } from './types';

export const state: AlertBannerState = {
  alertBannerOptions: [],
};

export const alertBanner: Module<AlertBannerState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
