


































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import RplButton from '@dpc-sdp/ripple-button';
import startCase from 'lodash/startCase';

import { urlToOldSite } from '@/helpers/redirect-to-old-app';

@Component({
  components: {
    RplButton,
    RplTextLink,
  },
})
export default class ChangeInfo extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  tab;

  @Prop({
    type: String,
    required: true,
  })
  schemeName;

  get oldSiteUrl(): string {
    return urlToOldSite(this.tab, this.schemeName);
  }

  get continueText(): string {
    return `Continue to ${startCase(this.tab)}`;
  }

  private showFeedback(): void {
    document.getElementById('atlwdg-trigger')!.click();
  }
}
