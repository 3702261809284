const lgaByName = {
  ALPINE: 300,
  'ALPINE RESORTS': 383,
  ARARAT: 301,
  BALLARAT: 302,
  BANYULE: 303,
  'BASS COAST': 304,
  'BAW BAW': 305,
  BAYSIDE: 306,
  BENALLA: 381,
  BOROONDARA: 307,
  BRIMBANK: 308,
  BULOKE: 309,
  CAMPASPE: 310,
  CARDINIA: 311,
  CASEY: 312,
  'CENTRAL GOLDFIELDS': 313,
  'COLAC OTWAY': 314,
  CORANGAMITE: 315,
  DAREBIN: 316,
  'EAST GIPPSLAND': 319,
  FRANKSTON: 320,
  'FRENCH ISLAND AND SANDSTONE ISLAND': 379,
  'GABO ISLAND': 389,
  GANNAWARRA: 321,
  'GLEN EIRA': 322,
  GLENELG: 323,
  'GOLDEN PLAINS': 324,
  'GREATER BENDIGO': 325,
  'GREATER DANDENONG': 326,
  'GREATER GEELONG': 327,
  'GREATER SHEPPARTON': 328,
  HEPBURN: 329,
  HINDMARSH: 330,
  'HOBSONS BAY': 331,
  HORSHAM: 332,
  HUME: 333,
  INDIGO: 334,
  KINGSTON: 335,
  KNOX: 336,
  LATROBE: 337,
  LODDON: 338,
  'MACEDON RANGES': 339,
  MANNINGHAM: 340,
  MANSFIELD: 382,
  MARIBYRNONG: 341,
  MAROONDAH: 342,
  MELBOURNE: 343,
  MELTON: 344,
  MILDURA: 345,
  MITCHELL: 346,
  MOIRA: 347,
  MONASH: 348,
  'MOONEE VALLEY': 349,
  MOORABOOL: 350,
  'MERRI-BEK': 351,
  MORELAND: 351,
  'MORNINGTON PENINSULA': 352,
  'MOUNT ALEXANDER': 353,
  MOYNE: 354,
  MURRINDINDI: 355,
  NILLUMBIK: 356,
  'NORTHERN GRAMPIANS': 357,
  'PORT OF MELBOURNE': 998,
  'PORT PHILLIP': 358,
  PYRENEES: 359,
  QUEENSCLIFFE: 360,
  'SOUTH GIPPSLAND': 361,
  'SOUTHERN GRAMPIANS': 362,
  STONNINGTON: 363,
  STRATHBOGIE: 364,
  'SURF COAST': 365,
  'SWAN HILL': 366,
  TOWONG: 367,
  WANGARATTA: 368,
  WARRNAMBOOL: 369,
  WELLINGTON: 370,
  'WEST WIMMERA': 371,
  WHITEHORSE: 372,
  WHITTLESEA: 373,
  WODONGA: 374,
  WYNDHAM: 375,
  YARRA: 376,
  'YARRA RANGES': 377,
  YARRIAMBIACK: 378,
};

type StringToNumber = (input: string) => number | undefined;

const lgaCodeForSchemeName: StringToNumber = (schemeName) =>
  lgaByName[schemeName.toUpperCase()];

export { lgaCodeForSchemeName };
