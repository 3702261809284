









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplCol } from '@dpc-sdp/ripple-grid';

@Component({
  components: {
    RplCol,
  },
})
export default class ListResultInfo extends Vue {
  @Prop({ default: 0 }) start!: number;

  @Prop({ default: 0 }) end!: number;

  @Prop({ default: 0 }) totalCount!: number;
}
