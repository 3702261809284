import {
  pagerForResult,
  normalizeClassificationLabel,
} from '@/helpers/render.helper';
import { logger } from '@/helpers';
import { LoaderStates } from '@/store/loading/types';
import { api } from '@/config';
import { ActionTree } from 'vuex';
import { AmendmentDetail, AmendmentDetailsParams } from '@/models';
import { RootState } from '../types';
import { AmendmentState } from './types';
import { convertPlanningAuthority, outcomeToSym } from './convert-from-api';

export const actions: ActionTree<AmendmentState, RootState> = {
  async fetchAmendmentDetail(
    { commit, rootGetters: { selectedScheme } },
    callDetails: AmendmentDetailsParams,
  ): Promise<any> {
    commit('loading/setState', LoaderStates.RUNNING, { root: true });
    const client = (await api).schemesClient;
    try {
      const schemeCode = callDetails.schemeCode || selectedScheme.schemeID;
      const amendmentDetail = (
        await client.fetchAmendment(schemeCode, callDetails.amendmentNumber)
      ).data;

      const {
        outcome,
        stages: { planningAuthority },
      } = amendmentDetail;

      const detailWithSymbols: AmendmentDetail = {
        ...amendmentDetail,
        outcome: outcomeToSym(outcome),
        stages: {
          ...amendmentDetail.stages,
          planningAuthority: convertPlanningAuthority(planningAuthority),
        },
      };

      commit('setAmendmentDetail', {
        ...detailWithSymbols,
        lastUpdated: amendmentDetail.statusDate,
      });

      commit('loading/setState', LoaderStates.COMPLETED, { root: true });
    } catch (e) {
      commit('loading/setState', LoaderStates.ERROR, { root: true });
    }
  },

  async fetchAmendments({
    commit,
    getters: { searchParams },
    rootGetters: { selectedScheme },
  }): Promise<any> {
    const client = (await api).schemesClient;

    commit('loading/setState', LoaderStates.RUNNING, { root: true });

    const page = Number(searchParams?.page) || 1;
    const limit = Number(searchParams?.limit) || 20;
    const offset = (page - 1) * limit;

    // FIXME: remove when API refactored

    try {
      const schemeCode =
        selectedScheme.schemeID !== '' ? selectedScheme.schemeID : undefined;

      const { results, total } = (
        await client.searchAmendments({
          ...searchParams,
          schemeCode,
          offset,
        })
      ).data;

      // FIXME: remove when API refactored
      const updatedAmendments = results.map((it) => ({
        ...it,
        lastUpdated: it.statusDate,
        outcome: outcomeToSym(it.outcome as unknown as string),
        classification: {
          key: it.classification || '',
          label: normalizeClassificationLabel(it.classification),
        },
      }));

      commit('setAmendments', updatedAmendments);
      commit('setTotalAmendments', total);

      const pager = pagerForResult(total, limit, page);

      commit('setPager', pager);

      commit('loading/setState', LoaderStates.COMPLETED, { root: true });
    } catch (e) {
      logger.error('Error while fetching amendments: ', e.message);
      commit('loading/setState', LoaderStates.ERROR, { root: true });
    }
  },
  async searchAmendments({ commit, dispatch }, params): Promise<any> {
    try {
      commit('setSearchParams', params);

      await dispatch('fetchAmendments');
    } catch (e) {
      logger.error('Error while searching amendments', e.message);
    }
  },
};
