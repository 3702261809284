import {
  Clause,
  OrdinanceContent as OrdinanceContentAPI,
  PlanningSchemeHistoricalVersion,
  PlanningSchemeVersion as PlanningSchemeVersionAPI,
  SchemePDFMapsResponse,
  Section,
} from '@/api/types';
import {
  extractCategory,
  extractOrdinanceTitle,
  removeStrikeTags,
  normalizeClassificationLabel,
} from '@/helpers';
import {
  extractOrdinanceNumber,
  removeOrdinanceNumber,
} from '@/helpers/search-result-title';
import { PlanningSchemeResultBySchemeIdAndOrdinanceId as PlanningScheme } from '@/models';
import { PDFMapsByGridNumber } from '../maps/types';
import { ordinanceIndexDetails } from './ordinance-details';
import {
  HistoricalScheme,
  Navigation,
  OrdinanceContent,
  OrdinanceNumberParams,
  PlanningSchemeVersion,
  SectionWithTags,
  SimpleOrdinance,
} from './types';

const anyDashOrSlash = /[-/]/i;

const parseDateDmy = (value) => {
  if (!value || !anyDashOrSlash.test(value)) return undefined;

  const replacedDate = value.replace(/-/g, '/');
  const [day, month, year] = replacedDate.split('/');
  const date = new Date(`${year}-${month}-${day}`);
  return Number.isNaN(date.valueOf()) ? undefined : date;
};

const anyDotOrSlash = /[.-]/i;

const extractOrdinanceParams = (text: string): OrdinanceNumberParams => {
  const ordinanceParams: OrdinanceNumberParams = {};

  if (anyDotOrSlash.test(text)) {
    const [clause, ...restSplit] = text.split('.');
    const rest = restSplit.join('.');
    ordinanceParams.clause = clause;

    if (rest.includes('-s')) {
      const [subClause, scheduleRest] = rest.split('-s');
      ordinanceParams.subClause = subClause;
      const [schedule, section, subSection] = scheduleRest.split(/[-.]/);
      ordinanceParams.schedule = schedule || 's';
      ordinanceParams.section = section;
      ordinanceParams.subSection = subSection;
      if (ordinanceParams.section && !ordinanceParams.subSection) {
        ordinanceParams.subSection = '0';
      }
    } else {
      const [subClause, ...sectionRestSplit] = rest.split('-');
      const sectionRest = sectionRestSplit.join('-');
      const [section, subSection] = sectionRest.split('.');
      ordinanceParams.subClause = subClause;
      ordinanceParams.section = section || undefined;
      ordinanceParams.subSection = subSection;
    }
  } else {
    ordinanceParams.clause = text || undefined;
  }

  return ordinanceParams;
};

const allNumbers = /^[0-9]+$/;

const isSemanticOrdinanceNumber = (text: string): boolean => {
  if (text) {
    return !allNumbers.test(text) || String(text).length <= 4;
  }
  return false;
};

const parseOrdinanceNumber = (ordinanceId: string): string => {
  let numberSelector;
  const { schedule, section, subSection } = extractOrdinanceParams(ordinanceId);
  if (ordinanceId.includes('-s')) {
    numberSelector =
      section && subSection
        ? [section, subSection].join('.')
        : `SCHEDULE ${schedule}`;
    if (schedule === 's' && !section) {
      numberSelector = 'SCHEDULE';
    }
  } else {
    numberSelector = ordinanceId;
  }
  return numberSelector;
};

const convertLegacyOrdinanceContent = (
  ordinanceContent: PlanningScheme,
  schemeId: string | undefined,
  apiUrl: string,
  pdfUrl: string,
): OrdinanceContent => ({
  id: ordinanceContent.ordinanceID,
  number: extractCategory(ordinanceContent.title),
  gazettalDate: ordinanceContent.gazettalDate
    ? parseDateDmy(ordinanceContent.gazettalDate)
    : undefined,
  amendmentNumber: ordinanceContent.amendmentNumber || '',
  title: extractOrdinanceTitle(ordinanceContent.title),
  content: removeStrikeTags(ordinanceContent.content) || '',
  sections: ordinanceContent.ordinanceSections
    ? ordinanceContent.ordinanceSections?.map((it) =>
        convertLegacyOrdinanceContent(it, schemeId, apiUrl, pdfUrl),
      )
    : undefined,
  state: ordinanceContent.state
    ? {
        active: ordinanceContent.state.activeFlag,
        altUri: ordinanceContent.state.alternateUri,
      }
    : undefined,
  ordinancePdfUrl: `${pdfUrl}/${schemeId}/ordinanceId/${ordinanceContent.ordinanceID}`,
});

const buildPdfUrl = (
  baseUrl: string,
  schemeCode: string | undefined,
  semanticNumber: string | undefined,
  ordinanceId: string,
  isHistoric: boolean,
): string => {
  if (isHistoric) {
    return `${baseUrl}/historic/${schemeCode}/ordinanceId/${ordinanceId}`;
  }
  if (semanticNumber) {
    return `${baseUrl}/${schemeCode}/ordinanceNumber/${semanticNumber}`;
  }
  return `${baseUrl}/${schemeCode}/ordinanceId/${ordinanceId}`;
};

const convertOrdinanceContent = (
  ordinance: OrdinanceContentAPI,
  schemeCode: string | undefined,
  apiUrl: string,
  pdfUrl: string,
  isHistoric = false,
): OrdinanceContent => ({
  ...ordinance,
  gazettalDate: ordinance.gazettalDate
    ? new Date(ordinance.gazettalDate)
    : undefined,
  sections: ordinance.sections
    ? ordinance.sections?.map((it) =>
        convertOrdinanceContent(it, schemeCode, apiUrl, pdfUrl, isHistoric),
      )
    : undefined,
  number: ordinance.number,
  ordinancePdfUrl: buildPdfUrl(
    pdfUrl,
    schemeCode,
    ordinance.semanticNumber,
    ordinance.id,
    isHistoric,
  ),
  content: removeStrikeTags(ordinance.content) || '',
});

const convertSchemeVersions = (
  schemeVersions: PlanningSchemeVersionAPI[],
): PlanningSchemeVersion[] =>
  schemeVersions.map((version) => ({
    ...version,
    description:
      version.legacyAmendmentNumbers.length > 0
        ? `${version.description}`
        : version.description,
    operationalDate: version.operationalDate
      ? new Date(version.operationalDate)
      : undefined,
    classification: {
      key: version.classification || '',
      label: normalizeClassificationLabel(version.classification),
    },
  }));

const convertHistoricalSchemeVersion = (
  schemeVersion: PlanningSchemeHistoricalVersion,
): HistoricalScheme => {
  const convertedDate = new Date(schemeVersion.operationalDate);

  return {
    ...schemeVersion,
    pdfClauses: schemeVersion.pdfClauses || [],
    operationalDate: convertedDate,
  };
};

const convertHistoricalSchemeVersionMapClauses = (
  historicalScheme: PlanningSchemeHistoricalVersion,
) => historicalScheme.pdfMaps || [];

const convertToOrdinanceIndexDetails = (
  clauses: Navigation[] = [],
): SimpleOrdinance[] => {
  const ordinances: SimpleOrdinance[] = [];
  const addedIds = new Set<string>();

  const addOrdinance = (clause: Navigation) => {
    const ordinance = ordinanceIndexDetails(clause);

    if (
      clause.ordinanceID &&
      !addedIds.has(clause.ordinanceID) &&
      !ordinances.some((x) => x.semanticNumber === clause.semanticNumber)
    ) {
      ordinances.push(ordinance);
      addedIds.add(clause.ordinanceID);
    }

    if (clause.children) {
      clause.children.forEach((child) => {
        addOrdinance(child);

        if (child.children) {
          child.children.forEach((subChild) => {
            addOrdinance(subChild);

            if (subChild.children) {
              subChild.children.forEach((subSubChild) => {
                addOrdinance(subSubChild);

                if (subSubChild.children) {
                  subSubChild.children.forEach((schedule) => {
                    addOrdinance(schedule);
                  });
                }
              });
            }
          });
        }
      });
    }
  };

  clauses.forEach(addOrdinance);

  return ordinances;
};

const convertToFlatHistoricalClauses = (
  clauses?: Clause[],
): SectionWithTags[] => {
  const ordinances: Section[] = [];

  if (!clauses) {
    return [];
  }
  clauses.forEach((clause) => {
    if (clause) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { subClauses, ...clauseProps } = clause;
      ordinances.push(clauseProps);
      if (clause.subClauses) {
        clause.subClauses.forEach((subClause) => {
          ordinances.push(subClause);
          if (subClause.schedules) {
            subClause.schedules.forEach((schedule) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { sections, ...scheduleProps } = schedule;
              ordinances.push(scheduleProps);
            });
          }
        });
      }
    }
  });

  return ordinances.map((item) => {
    const ordinanceNumber = extractOrdinanceNumber(item.title);
    const tags: string[] = [];
    if (item.ordinanceType === 'Schedule') {
      tags.push(item.ordinanceType);
    }
    if (ordinanceNumber) {
      tags.push(ordinanceNumber);
    }
    return {
      ...item,
      tags,
      ordinanceNumber,
      title: removeOrdinanceNumber(item.title),
    };
  });
};

const convertToSchemePDFMaps = (
  pdfMapsResponse: SchemePDFMapsResponse[],
): PDFMapsByGridNumber => {
  const mapsByGrid: any = [];
  const gridReference = new Map();

  pdfMapsResponse.forEach((item) => {
    if (!gridReference.has(item.gridReference)) {
      gridReference.set(item.gridReference, {
        gridReference: item.gridReference,
        maps: [],
      });
    }

    gridReference.get(item.gridReference).maps.push(item);
  });

  gridReference.forEach((value) => {
    const gridRef = parseInt(value.gridReference, 10);
    if (gridRef) {
      mapsByGrid.push({ gridNumber: gridRef, maps: value.maps });
    }
  });

  return mapsByGrid.sort((a, b) => a.gridNumber - b.gridNumber);
};

export {
  convertHistoricalSchemeVersion,
  convertHistoricalSchemeVersionMapClauses,
  convertLegacyOrdinanceContent,
  convertOrdinanceContent,
  convertSchemeVersions,
  convertToFlatHistoricalClauses,
  convertToOrdinanceIndexDetails,
  convertToSchemePDFMaps,
  extractOrdinanceParams,
  isSemanticOrdinanceNumber,
  parseDateDmy,
  parseOrdinanceNumber,
};
