






import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';
import { RplDivider } from '@dpc-sdp/ripple-global';

import Accordions from './Accordions.vue';

@Component({
  components: {
    Accordions,
    RplDivider,
  },
})
export default class MapsSidebar extends Vue {
  @Prop() selectedScheme;
}
