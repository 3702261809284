

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import RplTabs from '@dpc-sdp/ripple-tabs';

import PSOLink from '@/components/shared/PSOLink.vue';

import { Tab, SelectedScheme } from '@/models';

import { Route } from 'vue-router';
import { HistoricalScheme } from '@/store/schemes/types';

@Component({
  components: {
    PSOLink,
    RplRow,
    RplCol,
    RplTabs,
  },
})
export default class HistoricHTMLWrapper extends Vue {
  @Prop() versionId;

  @Prop() previousRoute!: Location;

  @Prop() historicalScheme!: HistoricalScheme;

  @Getter('selectedScheme')
  public selectedScheme!: SelectedScheme;

  public activeTab = 'pdfView';

  public previousOrdinanceRoute: Route | undefined;

  get isHistoric() {
    return this.$route.name === 'historiesSearch';
  }

  get historicOrPrevious() {
    return this.isHistoric
      ? {
          name: 'historicPDFTab',
          params: { versionId: this.$route.params.versionId },
        }
      : this.previousRoute;
  }

  get linkText() {
    return this.isHistoric
      ? `Back to historic amendment`
      : 'Back to list of historic amendments';
  }

  get tabs(): Tab[] {
    if (this.selectedScheme.schemeID.toLowerCase() === 'vpp') {
      return [
        {
          label: 'Ordinance view',
          key: 'pdfView',
          icon: 'list',
        },
      ];
    }

    return [
      {
        label: 'Ordinance view',
        key: 'pdfView',
        icon: 'list',
      },
      {
        label: 'Map view',
        key: 'mapView',
        icon: 'map_marker',
      },
    ];
  }

  public switchTab(tab) {
    if (tab === this.activeTab) {
      return;
    }

    if (tab === 'mapView') {
      this.$router.push({
        name: 'historicMapTab',
      });
    }

    if (tab === 'pdfView') {
      if (this.previousOrdinanceRoute?.name === 'historicOrdinanceDetail') {
        if (
          this.$router.currentRoute.name !== this.previousOrdinanceRoute.name
        ) {
          const { name, params, query } = this.previousOrdinanceRoute;
          this.$router.push({ name, params, query });
        }
      } else if (this.$router.currentRoute.name !== 'historicPDFTab') {
        this.$router.push({
          name: 'historicPDFTab',
        });
      }
    }
    this.activeTab = tab;
  }

  @Watch('$route', { immediate: true, deep: false })
  onUrlChange(newRoute?: Route, oldRoute?: Route) {
    if (newRoute) {
      const { name } = newRoute;
      if (name === 'historicMapTab') {
        this.activeTab = 'mapView';
      } else if (
        name === 'historicPDFTab' ||
        name === 'historicOrdinanceDetail'
      ) {
        this.activeTab = 'pdfView';
      }
    }

    if (oldRoute?.name === 'historicOrdinanceDetail') {
      this.previousOrdinanceRoute = oldRoute;
    }
  }
}
