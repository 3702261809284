/**
 * Smooth Scroll to given selector
 * if given element is not found scroll to top of the page
 */
export function scrollToElement(selector: string): void {
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo(0, 0);
  }
}
