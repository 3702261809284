



















































import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import RplIcon from '@dpc-sdp/ripple-icon';
import MenuItemNumberSpan from '@/components/ordinance-navigation/MenuItemNumberSpan.vue';
import { extractOrdinanceParams } from '@/store/schemes/convert-to-domain';

@Component({
  components: {
    RplIcon,
    MenuItemNumberSpan,
  },
})
export default class MenuItem extends Vue {
  @Prop() depth;

  @Prop() header;

  @Prop() identifier;

  @Prop() isOpen: boolean | undefined;

  @Prop() mapCode;

  @Prop() ordinanceLevel;

  @Prop() ordinanceType;

  @Prop() route;

  @Prop() children;

  @Prop() isSchedule;

  @Prop() title;

  @Prop() routeMeta;

  @Prop() isHistoricalNavigator;

  get extractedOrdinanceURL() {
    return extractOrdinanceParams(this.$route.params.ordinanceID);
  }

  get extractedOrdinanceAPI() {
    return extractOrdinanceParams(this.routeMeta.semanticNumber);
  }

  get activeFromMeta() {
    if (!this.$route.params.ordinanceID) {
      return false;
    }

    if (this.isHistoricalNavigator) {
      return this.isOrdinanceIDMatch;
    }

    if (
      this.extractedOrdinanceURL.clause &&
      parseInt(this.extractedOrdinanceURL.clause, 10) < 30
    ) {
      return this.isOrdinanceIDMatch;
    }

    if (
      this.extractedOrdinanceURL.schedule &&
      !this.extractedOrdinanceURL.section
    ) {
      return this.isOrdinanceMatch(['clause', 'subClause', 'schedule']);
    }

    if (
      !this.extractedOrdinanceURL.schedule &&
      this.extractedOrdinanceURL.section &&
      !this.extractedOrdinanceAPI.schedule
    ) {
      return this.isOrdinanceMatch(['clause', 'subClause']);
    }

    if (
      this.extractedOrdinanceURL.schedule &&
      this.extractedOrdinanceURL.section
    ) {
      return this.isOrdinanceMatch(['clause', 'subClause', 'schedule']);
    }

    if (
      this.extractedOrdinanceAPI.clause &&
      !this.extractedOrdinanceAPI.subClause &&
      this.extractedOrdinanceURL.clause &&
      !this.extractedOrdinanceURL.subClause
    ) {
      return this.isOrdinanceMatch(['clause']);
    }

    return this.isOrdinanceIDMatch;
  }

  get isOrdinanceIDMatch() {
    return (
      this.$route.params.ordinanceID === this.routeMeta.semanticNumber ||
      this.$route.params.ordinanceID === this.routeMeta.ordinanceID
    );
  }

  isOrdinanceMatch(fields) {
    return fields.every(
      (field) =>
        this.extractedOrdinanceAPI[field] === this.extractedOrdinanceURL[field],
    );
  }

  get ariaLabel() {
    return `${this.getAriaAction(this.depth, this.isOpen)}${
      this.ordinanceType
    } ${this.identifier} ${this.header}`;
  }

  get isActiveRouteLink() {
    return this.activeFromMeta;
  }

  public getAriaAction(depth, isOpen): string {
    if (depth === 0) {
      return isOpen ? 'collapse ' : 'expand ';
    }
    return '';
  }

  @Watch('isActiveRouteLink', { immediate: false, deep: false })
  active() {
    this.$emit('expand-menu');
  }
}
