import VueRouter, {
  NavigationGuard,
  NavigationGuardNext,
  Route,
  RouteConfig,
} from 'vue-router';
import store from '@/store';

import redirectToOldApp from '@/helpers/redirect-to-old-app';
import { featureEnabled } from '@/helpers/feature-toggles';

import AmendmentsTab from '@/views/scheme/AmendmentsTab.vue';
import AmendmentDetail from '@/views/scheme/AmendmentDetail.vue';
import IncorporatedDocumentsTab from '@/views/scheme/IncorporatedDocumentsTab.vue';
import HistoriesTab from '@/views/scheme/HistoriesTab.vue';
import HistoricScheme from '@/components/histories/HistoricScheme.vue';
import HistoricOrdinanceDetail from '@/components/histories/HistoricOrdinanceDetail.vue';
import PrePdfRecords from '@/components/histories/PrePdfRecords.vue';
import Home from '@/views/Home.vue';
import Maps from '@/views/scheme/Maps.vue';
import MapLookup from '@/views/scheme/MapLookup.vue';
import SchemeOrdinance from '@/components/scheme/SchemeOrdinance.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import Scheme from '@/views/scheme/Scheme.vue';
import SchemeContainer from '@/components/scheme/SchemeContainer.vue';
import SearchScheme from '@/views/scheme/SearchScheme.vue';
import HistoricPDFTab from '@/components/histories/HistoricPDFTab.vue';
import HistoricPDFMapTab from '@/components/histories/HistoricPDFMapTab.vue';
import HistoricHTMLTab from '@/components/histories/HistoricHTMLTab.vue';
import HistoricHTMLMapTab from '@/components/histories/HistoricHTMLMapTab.vue';
import HistoricSearchHTML from '@/components/histories/HistoricSearchHTML.vue';
import { getRedirectionMappings } from '@/config';
import { allSchemeTitles } from '@/store/allSchemes';

export const shouldRedirectToAmendmentsTab = async (
  routeName,
): Promise<boolean> =>
  routeName === 'amendmentDetail' &&
  !(await featureEnabled('amendments.browse'));

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: redirectToOldApp,
  },
  {
    path: '/:schemeName',
    name: 'scheme',
    component: Scheme,
    redirect: {
      name: 'ordinance',
    },
    children: [
      {
        path: 'ordinance',
        name: 'ordinance',
        component: SchemeContainer,
        props: true,
        children: [
          {
            path: 'search',
            name: 'ordinanceSearch',
            component: SearchScheme,
            props: true,
          },
          {
            path: ':ordinanceID',
            name: 'ordinanceId',
            props: true,
            component: SchemeOrdinance,
          },
        ],
      },
      {
        path: 'map-lookup',
        name: 'map-lookup',
        component: MapLookup,
      },
      {
        path: 'maps',
        name: 'maps',
        props: true,
        component: Maps,
      },
      {
        path: 'docs',
        name: 'docs',
        props: true,
        component: IncorporatedDocumentsTab,
      },
      {
        path: 'amendments',
        name: 'amendments',
        props: true,
        component: AmendmentsTab,
      },
      {
        path: 'amendments/:amendmentNumber',
        name: 'amendmentDetail',
        props: true,
        component: AmendmentDetail,
      },
      {
        path: 'histories',
        name: 'histories',
        props: true,
        component: HistoriesTab,
      },
      {
        path: 'histories/records-between-1946-and-1997',
        name: 'pre-pdf-records',
        props: false,
        component: PrePdfRecords,
      },
      {
        path: 'histories/:versionId',
        name: '',
        props: true,
        component: HistoricScheme,
        children: [
          {
            path: '',
            name: 'historicPDFTab',
            props: { default: true, htmlView: true },
            components: {
              default: HistoricPDFTab,
              htmlView: HistoricHTMLTab,
            },
            children: [
              {
                path: 'ordinance/:ordinanceID',
                name: 'historicOrdinanceDetail',
                props: true,
                component: HistoricOrdinanceDetail,
              },
            ],
          },
          {
            path: 'maps',
            name: 'historicMapTab',
            props: { default: true, htmlView: true },
            components: {
              default: HistoricPDFMapTab,
              htmlView: HistoricHTMLMapTab,
            },
          },
          {
            path: 'search',
            name: 'historiesSearch',
            components: {
              searchView: HistoricSearchHTML,
            },
            props: { default: true, htmlView: true },
          },
        ],
      },
    ],
  },
  {
    path: '/:notFound(.*)',
    name: '404',
    component: PageNotFound,
  },
];

// FIXME: these types aren't exported from vue-router,
// maybe a separate types.d in this codebase would help maintain separation?
type Position = { x: number; y: number };

type PositionResult =
  | Position
  | void
  | { selector: string; offset?: Position; behavior?: ScrollBehavior };

const isRedirectionIncludes = (
  redirectionMappings: Record<string, string>,
  schemeName: string | undefined,
) => {
  if (!schemeName) {
    return false;
  }
  return schemeName.toLowerCase().trim() in redirectionMappings;
};

export const invokeRedirection = async (
  to: Route,
  from: Route,
  redirectionMappings: Record<string, string>,
  next: NavigationGuardNext,
) => {
  const scheme =
    redirectionMappings[to.params.schemeName!.toLowerCase().trim()];
  next({
    ...to,
    name: to.name!,
    params: { ...to.params, schemeName: scheme },
  });
};

export const beforeEach: NavigationGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
) => {
  const redirectMappings = await getRedirectionMappings();
  if (
    redirectMappings &&
    isRedirectionIncludes(redirectMappings, to.params?.schemeName)
  ) {
    await invokeRedirection(to, from, redirectMappings, next);
  } else {
    await store.dispatch('fetchAllSchemes');

    if (to.name === '404' || to.name === 'home') {
      next();
    } else if (
      !allSchemeTitles.includes(to.params.schemeName.toLowerCase()) ||
      (((to?.name && to?.name.toLowerCase() === 'maps') ||
        (to?.name && to?.name.toLowerCase() === 'historicmaptab')) &&
        to.params.schemeName.toLowerCase() === 'victoria planning provisions')
    ) {
      next({ name: '404' });
    } else {
      const schemeName = to.params?.schemeName;

      await store.dispatch('ensureSelectedScheme', schemeName);

      if (await shouldRedirectToAmendmentsTab(to.name)) {
        next({ name: 'amendments', params: { schemeName } });
      } else next();
    }
  }
};

const vueRouter = new VueRouter({
  mode: 'history',
  routes,
});

vueRouter.beforeEach(beforeEach);

export default vueRouter;
