import Vue from 'vue';
import OpenTableModalButton from './OpenTableModalButton.vue';

const forEachTable = function forEachTable<T>(
  func: (value: T, index: number, array: T[]) => void,
) {
  const tables = document.getElementsByTagName('table');
  Array.prototype.forEach.call(tables, func);
};

const createTableWrapper = (
  table: HTMLElement,
  className: string,
  vueDataID: string,
): HTMLElement => {
  const el = document.createElement('div');
  el.className = className;
  el.setAttribute(vueDataID, '');
  table.parentNode!.insertBefore(el, table);
  el.appendChild(table);
  return el;
};

const createOpenModalButton = (
  table: HTMLElement,
  mountingId: string,
): void => {
  const modalPadding = 48;
  const { scrollWidth } = table;

  const width =
    window.innerWidth <= 1000 ? '98%' : (scrollWidth + modalPadding).toString();

  const constructor = Vue.extend(OpenTableModalButton);
  const openModalComponent = new constructor({
    propsData: {
      // tabindex is for tab control in modal
      content: `<table tabindex="0">${table.innerHTML}</table>`,
      width,
    },
  });

  openModalComponent.$mount(`#${mountingId}`);
};

const getOrdinanceNumber = (table: Element): string => {
  const parent = table.closest('.ordinance-section');
  return parent === null
    ? 'no-tag'
    : parent
        .querySelector('.last-tag')!
        .innerHTML.trim()
        .replaceAll(/[^-a-zA-Z0-9]+/g, '_');
};

const createMountingAnchor = (
  parent: ParentNode,
  mountingId: string,
): HTMLElement => {
  const el = document.createElement('a');
  el.id = mountingId;
  (parent as HTMLElement).appendChild(el);
  return el;
};

export const addShowTableButtons = (document: Document): void => {
  const vueDataId =
    document.querySelector('.ordinance-section')?.attributes[0].name || '';

  forEachTable((table: HTMLElement, i: number) => {
    if (
      table.parentNode !== null &&
      document.querySelector(`.clause-${i}`) === null
    ) {
      createTableWrapper(
        table,
        `ordinance-section__table clause-${i}`,
        vueDataId,
      );

      const ordinanceNumber = getOrdinanceNumber(table);
      const mountingId = `modal-${ordinanceNumber}__${i}`;
      createMountingAnchor(table.parentNode, mountingId);
      createOpenModalButton(table, mountingId);
    }
  });
};
