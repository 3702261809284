import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AmendmentState } from './types';
import { RootState } from '../types';

const state: AmendmentState = {
  amendments: [],
  totalAmendments: undefined,
  pager: undefined,
  searchParams: undefined,
  amendmentDetail: {
    amendmentNumber: '',
    description: '',
    status: '',
    lastUpdated: '',
    schemes: [],
    planningAuthorityContact: {
      authority: '',
    },
    stages: {
      exhibitionRequired: false,
      panelRequested: false,
      decisionRequired: false,
    },
  },
};

export const amendment: Module<AmendmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
