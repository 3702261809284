export interface MyHTMLElement extends HTMLFormElement {
  $el: HTMLElement;
}

export const setUpFormAccessibilityOptions = (ref, legendText, buttonText) => {
  const form = ref as MyHTMLElement;

  if (form && form.$el[0]) {
    form.$el[0].setAttribute('aria-label', 'Search');
    const legend = document.createElement('legend');
    const button = document.createElement('button');

    button.textContent = buttonText;
    legend.textContent = legendText;
    button.classList.add('sr-only');
    legend.classList.add('sr-only');

    form.$el[0].insertBefore(legend, form.$el[0].firstChild);
    form.$el[0].insertBefore(button, form.$el[0].lastChild);
  }
};
