








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import ChangeInfo from '@/components/ChangeInfo.vue';
import Amendments from '@/views/scheme/Amendments.vue';
import AlertBanner from '@/components/shared/AlertBanner.vue';

@Component({
  components: {
    AlertBanner,
    Amendments,
    ChangeInfo,
    FeatureToggled,
  },
})
class AmendmentsTab extends Vue {
  @Prop({ type: String, required: true }) schemeName;
}

export default AmendmentsTab;
