







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MapIcon from '@/components/icons/Map.vue';
import VicGovLogo from '@/components/icons/VicGovLogo.vue';
import BannerIcon from '@/components/icons/TopBanner.vue';
import SchemeSelector from '@/components/header-footer/SchemeSelector.vue';
import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { RplIcon } from '@dpc-sdp/ripple-icon';

@Component({
  components: {
    VicGovLogo,
    BannerIcon,
    SchemeSelector,
    MapIcon,
    RplRow,
    RplCol,
    RplIcon,
  },
})
export default class Banner extends Vue {
  updates = false;

  opened = false;

  public toggleUpdatesAndHelp() {
    this.opened = !this.opened;
    this.updates = !this.updates;
  }
}
