





























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplDataTable from '@dpc-sdp/ripple-data-table';

import { SelectedScheme } from '@/models';
import { HistoricalScheme, MapClause } from '@/store/schemes/types';

import { constructPdfLink } from '@/components/histories/historic-links';

import { formatBytes } from '@/filters';
import { historicPdfUrl } from '@/config';
import Spinner from '../shared/Spinner.vue';

import HistoricSearchFilter from './HistoricSearchFilter.vue';
import HistoricInfoMessage from './HistoricInfoMessage.vue';
import { selectedVersionTitle } from './convert-from-domain';
import { filterHistories } from './filter-params';

@Component({
  components: {
    HistoricInfoMessage,
    HistoricSearchFilter,
    RplCol,
    RplRow,
    RplDataTable,
    Spinner,
  },
})
export default class HistoricHTMLMapTab extends Vue {
  private dataIsLoading = false;

  @Prop() versionId;

  @Getter('mapClausesTableRows', { namespace: 'schemes' })
  private mapClausesTableRows!: MapClause[];

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('historicalScheme', { namespace: 'schemes' })
  public historicalScheme!: HistoricalScheme;

  @Getter('mapClausesTableHeaders', { namespace: 'schemes' })
  private mapClausesTableHeaders!: [string, string, string];

  @Getter('historicHTMLMapPDFSearchTerm', { namespace: 'schemes' })
  private searchTerm;

  @Action('fetchHistoricalSchemeMapData', { namespace: 'schemes' })
  private fetchHistoricalSchemeMapData;

  @Action('setHistoricHTMLMapPDFSearchTerm', { namespace: 'schemes' })
  private setPDFSearchTerm;

  @Getter('isHistoricPDFMaps', { namespace: 'schemes' })
  private isHistoricPDFMaps!: boolean;

  public componentKey = 0;

  public filteredItems: any = [];

  public baseUrl = '';

  public helpText = "For example: 'map number or map type'";

  public popUpText = 'Press [Enter] to search historical PDF Map content.';

  get selectedVersionTitle() {
    return selectedVersionTitle(this.historicalScheme);
  }

  get mapsLinkLabel(): string {
    return `${this.selectedScheme.title} maps`;
  }

  get historiesLinkLabel(): string {
    return `${this.selectedScheme.title} histories`;
  }

  public enterClicked(val) {
    if (val === '' || val === undefined) {
      this.filteredItems = this.mapClausesTableRows;
    } else {
      this.filteredItems = this.mapClausesTableRows.filter(
        (item) =>
          filterHistories(item.mapNumber, val) ||
          filterHistories(item.mapType, val),
      );
    }

    this.setPDFSearchTerm(val);
    this.componentKey += 1;
  }

  get filteredItemsWithLinks() {
    const filteredValues: [string, string, string][] = this.filteredItems.map(
      (item): [string, string, string] => [
        constructPdfLink(
          item.fileName ? this.baseUrl + item.fileName : '#',
          item.mapNumber || 'Map (unknown number)',
          true,
        ),
        item.mapType || 'Map (unknown type)',
        formatBytes(item.size),
      ],
    );

    if (filteredValues.length === 0) {
      filteredValues.push(["Sorry! We couldn't find any matches.", '', '']);
    }

    return [this.mapClausesTableHeaders, ...filteredValues];
  }

  public clearSearch() {
    this.setPDFSearchTerm('');
    this.enterClicked('');
  }

  async mounted() {
    this.dataIsLoading = true;

    await this.fetchHistoricalSchemeMapData(this.versionId);

    const resolvedCumberlandUrl = await historicPdfUrl;

    this.baseUrl = this.isHistoricPDFMaps
      ? resolvedCumberlandUrl
      : '/static/maps/';
    this.filteredItems = this.mapClausesTableRows.filter(
      (item) =>
        filterHistories(item.mapNumber, this.searchTerm) ||
        filterHistories(item.mapType, this.searchTerm),
    );
    this.componentKey += 1;
    this.dataIsLoading = false;
  }

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'historicPDFTab') {
      this.setPDFSearchTerm('');
    }
    next();
  }
}
