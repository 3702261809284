











import Vue from 'vue';
import { LoaderState, LoaderStates } from '@/store/loading';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Spinner from './Spinner.vue';

const namespace = 'loading';

@Component({
  components: {
    Spinner,
  },
})
export default class Loader extends Vue {
  @Getter('state', { namespace })
  private loaderState!: LoaderState;

  get isError() {
    return this.loaderState === LoaderStates.ERROR;
  }

  get isRunning() {
    return this.loaderState === LoaderStates.RUNNING;
  }

  get isCompleted() {
    return this.loaderState === LoaderStates.COMPLETED;
  }

  get hasCustomError() {
    return !!this.$slots.customLoadingError;
  }
}
