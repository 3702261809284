import Router from 'vue-router';

import { SearchAmendmentRequestOptions } from '@/models';

import { flattenFilter } from '@/helpers/filter-formatter';

const { isNavigationFailure } = Router;

export const updateAmendmentSearchParams =
  (router, schemeName: string) => (query: SearchAmendmentRequestOptions) => {
    const { filter = {}, ...searchParamsWithoutFilter } = query;
    const routerQuery = {
      ...searchParamsWithoutFilter,
      ...flattenFilter(filter),
    };

    return router
      .push({
        name: 'amendments',
        params: { schemeName },
        query: routerQuery,
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  };
