import { MutationTree } from 'vuex';
import {
  HistoricalScheme,
  OrdinanceContent,
  OrdinanceReference,
  PlanningSchemeVersion,
  SchemeState,
  SchemeStructure,
  SchemeVersionDateRange,
  SectionWithTags,
  SimpleOrdinance,
  Sorting,
} from './types';

export const mutations: MutationTree<SchemeState> = {
  setFocused(state, payload) {
    state.focusedItem = payload;
  },
  setScheme(state, scheme: SchemeStructure) {
    state.scheme = scheme;
  },
  setRedirectOrdinance: (state, ordinanceReference: OrdinanceReference) => {
    state.redirectOrdinance = ordinanceReference;
  },
  setSchemeVersions: (
    state,
    {
      versions,
      hasPlanningScheme,
    }: { versions: PlanningSchemeVersion[]; hasPlanningScheme: boolean },
  ) => {
    state.schemeVersions = versions;
    state.hasPlanningSchemeColumn = hasPlanningScheme;
  },
  setHistoricalScheme: (state, historicalScheme: HistoricalScheme) => {
    state.historicalScheme = historicalScheme;
  },
  setSimpleOrdinances: (state, ordinances: SimpleOrdinance[]) => {
    state.simpleOrdinances = ordinances;
  },
  setTopForContinuesScroll(state, payload) {
    state.backToTopSignal = payload;
  },
  setHistoricOrdinance: (state, ordinance: OrdinanceContent) => {
    state.historicOrdinance = ordinance;
  },
  setFilterParams(state, { from, to }: SchemeVersionDateRange): void {
    state.schemeVersionsFilters.from = from;
    state.schemeVersionsFilters.to = to;
  },
  historicMapPDFSearchTerm(state, payload): any {
    state.historicMapPDFSearchTerm = payload;
  },
  historicPDFSearchTerm(state, payload): any {
    state.historicPDFSearchTerm = payload;
  },
  historicHTMLMapPDFSearchTerm(state, payload): any {
    state.historicHTMLMapPDFSearchTerm = payload;
  },
  historicListSearchTerm(state, payload): void {
    state.historicListSearchTerm = payload;
  },
  setHistoricalFlatOrdinances(state: SchemeState, payload: SectionWithTags[]) {
    state.flatHistoricalOrdinances = payload;
  },
  setSortParams(state: SchemeState, payload: Sorting | undefined) {
    state.sortParams = payload;
  },
  setClearHistoricalDateFilters(state: SchemeState) {
    state.schemeVersionsFilters.from = '';
    state.schemeVersionsFilters.to = '';
  },
  historicListStatus(state: SchemeState, payload: string[]) {
    state.schemeVersionsFilters.status = payload;
  },
  historicListOutcome(state: SchemeState, payload: string[]) {
    state.schemeVersionsFilters.outcome = payload;
  },
  historicListType(state: SchemeState, payload: string[]) {
    state.schemeVersionsFilters.type = payload;
  },
  historicListClassification(state: SchemeState, payload: string[]) {
    state.schemeVersionsFilters.classification = payload;
  },
  currentPage(state: SchemeState, payload: number) {
    state.historicalListCurrentPage = payload;
  },
};
