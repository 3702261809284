





























































import Vue from 'vue';
import { formatDate } from '@/filters';
import { Component, Prop } from 'vue-property-decorator';
import RplDocumentLink from '@dpc-sdp/ripple-document-link';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';

import SvgIcon from '@/components/shared/SvgIcon.vue';
import PSOLink from '@/components/shared/PSOLink.vue';

import { RplIcon } from '@dpc-sdp/ripple-icon';
import { IncorporatedDocument } from '@/store/incorporated-documents/types';
import PSODocumentLink from './DocumentLink.vue';

@Component({
  components: {
    PSODocumentLink,
    RplDocumentLink,
    RplIcon,
    RplRow,
    RplCol,
    SvgIcon,
    PSOLink,
  },
})
export default class DocumentListRow extends Vue {
  @Prop() row!: IncorporatedDocument;

  @Prop() searchTerm!: string;

  @Prop() listItems!: number;

  get archivedByDateString() {
    return this.row.archivedByDate
      ? `${formatDate(this.row.archivedByDate)}, `
      : '';
  }

  get archivedByAmendmentNumberString() {
    return this.row.archivedByAmendmentNumber
      ? ` ${this.row.archivedByAmendmentNumber}`
      : '';
  }

  get markedAmendmentId() {
    return this.searchAndMarkForSubString(
      this.searchTerm,
      this.row.introducedByAmendmentNumber,
    );
  }

  get markedFileName() {
    if (this.row.title) {
      return this.searchAndMarkForSubString(this.searchTerm, this.row.title);
    }
    return this.row.title;
  }

  searchAndMarkForSubString(searchTerm: string, text?: string) {
    if (searchTerm && text) {
      const re = new RegExp(searchTerm, 'gi');
      const match = re.exec(text);

      if (match && match.length > 0) {
        const r = new RegExp(match[0], 'g');
        return text.replace(r, `<mark>${match[0]}</mark>`);
      }
    }

    return text;
  }
}
