










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { OrdinanceSchemeDetails } from '@/models';

import { MappedOrdinanceType } from '@/store/schemes/types';
import provideChildCols from '@dpc-sdp/ripple-global/mixins/ProvideChildCols';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import OrdinanceInfoComponent from '../scheme/OrdinanceInfo.vue';

@Component({
  components: {
    RplRow,
    RplCol,
    OrdinanceInfoComponent,
  },
  mixins: [provideChildCols],
})
export default class SearchResult extends Vue {
  public mobileView = false;

  @Prop() row;

  @Getter('allSchemes')
  private allSchemes!: OrdinanceSchemeDetails[];

  @Getter('mappedOrdinanceTypes', { namespace: 'schemes' })
  private types!: MappedOrdinanceType[];

  public maxLength = 500;

  public displayUrl(href) {
    return `${window?.location?.host}${href}`;
  }

  public showContext({ type }) {
    return type !== 'Schedule' && type !== 'Clause';
  }

  get schemeTitle() {
    return this.allSchemes.find((i) => i.schemeID === this.row.schemeId)?.title;
  }

  get routerLink() {
    return {
      hash: this.row.route.hash,
      name: this.row.route.name,
      params: {
        ordinanceID: this.row.route.params.ordinanceID,
        schemeName: this.schemeTitle,
      },
    };
  }

  get ordinanceLabel() {
    const ordinanceLabel = this.types.find(
      (x) => x.id === this.row.mappedOrdinanceType,
    );
    return ordinanceLabel?.label;
  }

  get truncatedSnippet() {
    const endIndex = this.row.snippet.lastIndexOf('</mark>');

    if (endIndex !== -1 && endIndex > this.maxLength) {
      return `${this.row.snippet.substring(
        0,
        endIndex + 7,
      )}<span class="more">...</span>`;
    }

    if (this.row.snippet.length > this.maxLength) {
      return `${this.row.snippet.substring(
        0,
        this.maxLength,
      )}<span class="more">...</span>`;
    }

    return this.row.snippet;
  }

  public isMobile() {
    const clientWidth = window.innerWidth;

    if (clientWidth <= 991) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  mounted(): void {
    window.addEventListener('resize', this.isMobile);
    this.isMobile();
  }

  destroyed(): void {
    window.removeEventListener('resize', this.isMobile);
  }
}
