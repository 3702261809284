import { uppercaseFirstChar } from '@/helpers';
import { SelectedScheme } from '@/models';

export const AllSchemesScheme: SelectedScheme = {
  title: 'All schemes',
  schemeID: '',
};

const allSchemesLowercaseTitle: string = AllSchemesScheme.title.toLowerCase();

export const isAllSchemes = (selectedScheme?: string): boolean =>
  selectedScheme?.toLowerCase() === allSchemesLowercaseTitle;

export const planningSchemeTitle = (schemeName: string): string =>
  isAllSchemes(schemeName)
    ? 'Browse amendments'
    : uppercaseFirstChar(schemeName);
