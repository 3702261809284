import { redirectEnabled, oldBaseUrl } from '@/config';

export default async (to, from, next) => {
  if (from?.query?.redirect === 'false' || to?.query?.redirect === 'false') {
    next();
  } else if (await redirectEnabled) {
    window.location.assign(oldBaseUrl);
  } else {
    next();
  }
};

export const planningSchemeName = (schemeName: string): string =>
  schemeName === 'Victoria Planning Provisions' ? 'VPPS' : schemeName;

export const urlToOldSite = (section: string, schemeName: string): string =>
  `${oldBaseUrl}/${section}?f.Scheme|planningSchemeName=${planningSchemeName(
    schemeName,
  )}`;

export const urlToHistoricOldRecords = (schemeName: string): string =>
  `${oldBaseUrl}/histories/record-between-1946-and-1997?f.Scheme|planningSchemeName=${planningSchemeName(
    schemeName,
  )}`;
