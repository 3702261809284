










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';

import { Location } from 'vue-router';

@Component({
  components: {
    RplIcon,
  },
})
export default class PSOLink extends Vue {
  @Prop() linkTo!: Location;

  @Prop() newTab!: boolean | null;

  @Prop() showIconLeft!: boolean | null;

  @Prop() showIconRight!: boolean | null;

  @Prop() showBackButton!: boolean;

  @Prop() showForwardArrow!: boolean;

  @Prop() iconLeftSymbol!: string | null;

  @Prop() iconRightSymbol!: string | null;

  @Prop() text!: string;

  @Prop() anchor!: string;
}
