











import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FilterSection from '@/components/filter/FilterSection.vue';
import MultipleSelectMixin from '@/mixins/MultipleSelectMixin.vue';
import { FilterOption } from '@/models';
import MultipleSelect from '../shared/MultipleSelect.vue';

@Component({
  mixins: [MultipleSelectMixin],
  components: {
    FilterSection,
    MultipleSelect,
  },
})
export default class HistoricClassificationFilter extends Vue {
  @Getter('historicListClassificationOptions', { namespace: 'schemes' })
  private options!: FilterOption[];

  @Getter('selectedHistoricClassificationOptions', { namespace: 'schemes' })
  private selected!: string[];

  public title = 'Classification';

  public toggleCheckbox(options): void {
    this.$emit('on-histories-classification-change', options);
  }
}
