import { IncorporatedDocumentState } from '@/store/incorporated-documents/types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from '@/store/incorporated-documents/mutations';
import { actions } from '@/store/incorporated-documents/actions';
import { getters } from '@/store/incorporated-documents/getters';

export const state: IncorporatedDocumentState = {
  documents: [],
  dateFilter: {},
  searchQuery: '',
  pageSize: 20,
  sortParams: undefined,
  selectedTypeOptions: [],
  selectedStatusOptions: ['current'],
  typeFilterOptions: ['local', 'state'],
  statusFilterOptions: ['current', 'archived'],
};

export const incorporatedDocument: Module<
  IncorporatedDocumentState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
