












































































































import Vue from 'vue';
import Router from 'vue-router';

import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { PlanningSchemeVersion, SelectedScheme } from '@/models';
import { RplDivider } from '@dpc-sdp/ripple-global';

import Sidebar from '@/components/histories/Sidebar.vue';
import ContentLayout from '@/components/shared/ContentLayout.vue';
import RplDocumentLink from '@/components/shared/DocumentLink.vue';
import Loader from '@/components/shared/Loader.vue';
import PSOLink from '@/components/shared/PSOLink.vue';
import { api } from '@/config';
import { planningSchemeTitle } from '@/helpers/all-schemes';
import { scrollToElement } from '@/helpers/scroll-to-element';
import { isDate } from '@/helpers/validation-utils';
import { TableHeader } from '@/models/table-header';
import { Sorting, SortOrder } from '@/store/schemes/types';
import { RplCol } from '@dpc-sdp/ripple-grid';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import dayjs from 'dayjs';
import HistoricSearchFilter from './HistoricSearchFilter.vue';
import HistoriesTable from './HistoriesTable.vue';

const { isNavigationFailure } = Router;

@Component({
  components: {
    ContentLayout,
    HistoriesTable,
    HistoricSearchFilter,
    Loader,
    Sidebar,
    RplIcon,
    RplCol,
    RplDivider,
    RplDocumentLink,
    PSOLink,
  },
})
export default class Histories extends Vue {
  @Getter('selectedScheme')
  public selectedScheme!: SelectedScheme;

  @Getter('schemeVersions', { namespace: 'schemes' })
  public planningSchemeVersion!: PlanningSchemeVersion[];

  @Getter('historicListSearchTerm', { namespace: 'schemes' })
  public searchTerm!: string;

  @Getter('sortParams', { namespace: 'schemes' })
  public sortParams!: Sorting;

  @Getter('hasPlanningSchemeColumn', { namespace: 'schemes' })
  public hasPlanningSchemeColumn!: boolean;

  @Getter('selectedHistoricListOutcomeOptions', { namespace: 'schemes' })
  private getSelectedOutcome;

  @Getter('selectedHistoricListTypeOptions', { namespace: 'schemes' })
  private getSelectedType;

  @Getter('selectedHistoricListStatusOptions', { namespace: 'schemes' })
  private getSelectedStatus;

  @Getter('historicalListCurrentPage', { namespace: 'schemes' })
  private historicalListCurrentPage;

  @Action('setHistoricalDateFilters', { namespace: 'schemes' })
  private setDateFilters!: (payload) => void;

  @Action('getSchemeVersions', { namespace: 'schemes' })
  private getSchemeVersions;

  @Action('setHistoricListSearchTerm', { namespace: 'schemes' })
  private setSearchTerm;

  @Action('setSortParams', { namespace: 'schemes' })
  private setSortParams;

  @Action('setSelectedHistoricalAmendmentStatus', { namespace: 'schemes' })
  private setSelectedStatuses!: (payload: string[]) => void;

  @Action('setSelectedHistoricalAmendmentOutcome', { namespace: 'schemes' })
  private setSelectedOutcomes!: (payload: string[]) => void;

  @Action('setSelectedHistoricalAmendmentType', { namespace: 'schemes' })
  private setSelectedType!: (payload: string[]) => void;

  @Action('setCurrentPageToStore', { namespace: 'schemes' })
  private setCurrentPageToStore!: (payload: number) => void;

  public perPageOptions = [20, 50, 100];

  public popUpText = 'Press [Enter] to filter out historical amendments.';

  public helpText = 'For example: Amendment number or description';

  public pageSize = 20;

  private fileIsBeingCreated = false;

  currentPage = 0;

  headers: TableHeader[] = [
    {
      id: 'amendmentNumber',
      text: 'Amendment number',
      enableSort: true,
      sortOrder: SortOrder.NONE,
      isSortActive: false,
    },
    {
      id: 'classification',
      text: 'Classification',
      enableSort: true,
      sortOrder: SortOrder.ASC,
      isSortActive: false,
    },
    {
      id: 'outcome',
      text: 'Outcome',
      enableSort: false,
      sortOrder: SortOrder.NONE,
      isSortActive: false,
    },
    {
      id: 'gazettalDate',
      text: 'Gazettal date',
      enableSort: false,
      sortOrder: SortOrder.DESC,
      isSortActive: false,
    },
    {
      id: 'operationalDate',
      text: 'Date of operation',
      enableSort: true,
      sortOrder: SortOrder.DESC,
      isSortActive: true,
    },
    {
      id: 'planningScheme',
      text: 'Planning Scheme',
      enableSort: true,
      sortOrder: SortOrder.DESC,
      isSortActive: false,
    },
  ];

  public async downloadFile() {
    this.fileIsBeingCreated = true;

    const client = (await api).schemesClient;
    const {
      'filter.status': routeFilterStatuses,
      'filter.outcome': routeFilterOutcomes,
      'filter.type': routeFilterType,
      to,
      from,
    } = this.$route.query;

    await client
      .downloadFile(
        this.selectedScheme.schemeID,
        this.selectedScheme.title,
        from,
        to,
        routeFilterStatuses,
        routeFilterOutcomes,
        routeFilterType,
      )
      .then((success) => {
        if (success) {
          this.fileIsBeingCreated = false;
        }
      })
      .catch(() => {
        this.fileIsBeingCreated = false;
      });
  }

  public isActive(limit) {
    return limit === this.pageSize;
  }

  get disableAmendmentListButton() {
    return this.fileIsBeingCreated;
  }

  get exportUrl() {
    return '/ordinance/scheme/mbek/history/export';
  }

  get count(): number {
    return this.planningSchemeVersion.length;
  }

  get oldRecordsName() {
    return { name: 'pre-pdf-records' };
  }

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.selectedScheme?.title || '');
  }

  get schemeName() {
    return this.selectedScheme.title;
  }

  get emptySearchResult() {
    return this.planningSchemeVersion?.length === 0;
  }

  get from(): string | (string | null)[] {
    return this.$route.query.from;
  }

  get to(): string | (string | null)[] {
    return this.$route.query.to;
  }

  get availableHeaders(): TableHeader[] {
    if (!this.hasPlanningSchemeColumn) {
      return this.headers.filter((x) => x.id !== 'planningScheme');
    }
    return this.headers;
  }

  get historicOldRecordsText(): string {
    return `view 1946-1997 historic records`;
  }

  public resetCurrentPage() {
    this.currentPage = 0;
    this.setCurrentPageToStore(0);
    this.$router
      .push({
        query: {
          ...this.$route.query,
          currentPage: this.currentPage.toString(),
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  public setLimitPerPage(limit) {
    this.resetCurrentPage();
    this.pageSize = limit;
  }

  public isLastElement(limit): boolean {
    return limit !== this.perPageOptions.slice(-1).pop();
  }

  public clearSearch(): void {
    this.resetCurrentPage();
    this.setSortParams({
      sortKey: 'operationalDate',
      sortOrder: SortOrder.DESC,
    });
    const header = this.headers.find((x) => x.id === 'operationalDate');
    if (header) header.sortOrder = SortOrder.DESC;
    this.setSearchTerm('');
  }

  public enterClicked(query: string): void {
    if (!query) {
      this.clearSearch();
    } else {
      this.resetCurrentPage();
      this.setSortParams({
        sortKey: 'operationalDate',
        sortOrder: SortOrder.ASC,
      });
      const header = this.headers.find((x) => x.id === 'operationalDate');
      if (header) header.sortOrder = SortOrder.ASC;
      this.setSearchTerm(query);
    }
  }

  public onPaginationChange(page: number): void {
    this.currentPage = page;
    this.setCurrentPageToStore(page);
    scrollToElement('.histories');

    this.$router
      .push({
        query: {
          ...this.$route.query,
          currentPage: page.toString(),
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  public onSortChange(event: Sorting | undefined): void {
    if (event) {
      this.setHeaders(event);
      this.setQueryParams(event);
    }
    this.resetCurrentPage();
    this.setSortParams(event);
  }

  setHeaders(event: Sorting) {
    this.headers = this.headers.map((x) => {
      if (x.id === event.sortKey) {
        return { ...x, sortOrder: event.sortOrder, isSortActive: true };
      }
      return { ...x, sortOrder: SortOrder.NONE, isSortActive: false };
    });
  }

  onMountSetStatus(statuses: string | (string | null)[]) {
    if (statuses && statuses.length > 0 && typeof statuses === 'string') {
      this.setSelectedStatuses(statuses.split(','));
    } else {
      this.setSelectedStatuses(['Gazetted', 'Finished']);
    }
  }

  onMountSetOutcome(outcomes: string | (string | null)[]) {
    if (outcomes && outcomes.length > 0 && typeof outcomes === 'string') {
      this.setSelectedOutcomes(outcomes.split(','));
    } else {
      this.setSelectedOutcomes([
        'Approved',
        'ApprovedWithChanges',
        'Revoked',
        'ApprovedByPlanningAuthority',
      ]);
    }
  }

  onMountSetType(types: string | (string | null)[]) {
    if (types && types.length > 0 && typeof types === 'string') {
      this.setSelectedType(types.split(','));
    } else {
      this.setSelectedType([]);
    }
  }

  onMountSetDate(payload: {
    from: string | (string | null)[];
    to: string | (string | null)[];
  }) {
    if (payload.from || payload.to) {
      const { from, to } = payload;
      const params: Map<string, string> = new Map<string, string>();

      if (typeof from === 'string' && isDate(from)) {
        params.set('from', dayjs(from).format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        params.set('from', '');
      }
      if (typeof to === 'string' && isDate(to)) {
        params.set('to', dayjs(to).format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        params.set('to', '');
      }
      this.setDateFilters(payload);
    } else {
      this.setDateFilters({ from: '', to: '' });
    }
  }

  setQueryParams(sortEvent: Sorting): void {
    const sortOrder = {
      sortOrder: sortEvent.sortOrder,
      sortKey: sortEvent.sortKey,
    };
    this.$router
      .push({
        query: {
          ...this.$route.query,
          ...sortOrder,
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  validateString(
    val: string | (string | null)[] | undefined,
    possibleValues: string[],
  ): boolean {
    return !val || val === '' || Array.isArray(val)
      ? false
      : possibleValues.includes(val);
  }

  protected onMountSetUpRoutes() {
    const routeFilterStatuses = this.$route.query['filter.status'];
    const routeFilterOutcomes = this.$route.query['filter.outcome'];
    const routeFilterType = this.$route.query['filter.type'];
    const { currentPage } = this.$route.query;

    if (currentPage !== undefined && currentPage !== null) {
      const currentPageValue = currentPage.toString();
      const parsedValue = parseInt(currentPageValue, 10);

      if (!Number.isNaN(parsedValue)) {
        this.setCurrentPageToStore(parsedValue);
        this.currentPage = parsedValue;
      }
    }

    if (routeFilterType !== undefined) {
      this.onMountSetType(routeFilterType);
    }

    if (routeFilterStatuses !== undefined) {
      this.onMountSetStatus(routeFilterStatuses);
    }

    if (routeFilterOutcomes !== undefined) {
      this.onMountSetOutcome(routeFilterOutcomes);
    }

    this.$router
      .push({
        query: {
          ...this.$route.query,
          currentPage:
            currentPage !== undefined
              ? this.historicalListCurrentPage
              : currentPage,
          'filter.outcome':
            routeFilterOutcomes !== undefined
              ? routeFilterOutcomes
              : this.getSelectedOutcome.join(','),
          'filter.status':
            routeFilterStatuses !== undefined
              ? routeFilterStatuses
              : this.getSelectedStatus.join(','),
          'filter.type':
            routeFilterType !== undefined
              ? routeFilterType
              : this.getSelectedType.join(','),
        },
      })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
  }

  async mounted() {
    const { sortKey, sortOrder } = this.$route.query;

    if (
      this.validateString(sortKey, [
        'amendmentNumber',
        'operationalDate',
        'planningScheme',
      ]) &&
      this.validateString(sortOrder, [SortOrder.ASC, SortOrder.DESC])
    ) {
      const event = {
        sortKey,
        sortOrder,
      } as Sorting;
      this.setHeaders(event);
      this.setSortParams(event);
      this.onSortChange(event);
    } else if ('sortKey' in this.$route.query) {
      const event = {
        sortKey: 'operationalDate',
        sortOrder: SortOrder.DESC,
      } as Sorting;
      this.setHeaders(event);
      this.setSortParams(event);
      this.onSortChange(event);
    } else {
      this.setSortParams({
        sortKey: 'operationalDate',
        sortOrder: SortOrder.DESC,
      });
    }

    await this.getSchemeVersions({
      schemeCode: this.selectedScheme.schemeID,
      from: this.from,
      to: this.to,
    });

    this.onMountSetUpRoutes();
  }

  @Watch('$route', { immediate: true, deep: false })
  async onRouteChanged(newRoute) {
    const { from, to, currentPage } = newRoute.query;
    if (currentPage) {
      this.currentPage = parseInt(currentPage, 0);
      this.setCurrentPageToStore(parseInt(currentPage, 0));
    }
    this.onMountSetDate({ from, to });
    this.onMountSetType(newRoute.query['filter.type']);
    this.onMountSetStatus(newRoute.query['filter.status']);
    this.onMountSetOutcome(newRoute.query['filter.outcome']);
  }
}
