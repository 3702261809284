import {
  StageStatus,
  StageStatusSymbol,
  StageHeaderData,
  GazettalDetails,
  StageDetailsType,
} from './stages.model';

export const getGazettalStatus = (
  model: GazettalDetails,
  now: () => Date = () => new Date(),
): StageStatusSymbol => {
  const { status, gazettal } = model;

  if (status === 'SplitParent') {
    return StageStatus.NOT_REQUIRED;
  }

  if (gazettal && gazettal.date && new Date(gazettal.date) < now()) {
    return StageStatus.COMPLETE;
  }

  return StageStatus.PENDING;
};

export const getGazettalData = (model: GazettalDetails): StageHeaderData[] => {
  const stage = getGazettalStatus(model);
  switch (stage) {
    case StageStatus.NOT_REQUIRED:
      return [
        {
          value: 'Not required',
          type: StageDetailsType.Text,
        },
      ];
    case StageStatus.PENDING:
      return [];
    default: {
      const { date, operationalDate } = model.gazettal!;

      return [
        {
          label: 'Government gazettal publish date',
          value: date,
          type: StageDetailsType.Date,
        },
        {
          label: 'Date of operation',
          value: operationalDate,
          type: StageDetailsType.Date,
        },
      ];
    }
  }
};
