





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import RplDocumentLink from '@dpc-sdp/ripple-document-link';

import { extractExtension } from './stage-details';
import { Document, StageDetailsType } from './stages.model';

const stageExpand = 'Expand';
const stageCollapse = 'Collapse';

@Component({
  components: {
    RplRow,
    RplCol,
    RplIcon,
    RplDocumentLink,
  },
})
export default class StageDetails extends Vue {
  @Prop()
  dataRows!: { label: string; docs: Document[] }[];

  @Prop() name!: string;

  private isOpen = false;

  private ariaState = stageExpand;

  get accordionClasses() {
    return {
      collapsed: !this.isOpen,
    };
  }

  get expandAction() {
    return this.isOpen ? stageCollapse : stageExpand;
  }

  isDocumentListSection(valueType: symbol) {
    return valueType === StageDetailsType.DocumentList;
  }

  isSingleDocumentSection(valueType: symbol) {
    return valueType === StageDetailsType.SingleDocument;
  }

  isDateSection(valueType: symbol) {
    return valueType === StageDetailsType.Date;
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  fileExtension(fileName: string): string {
    return extractExtension(fileName);
  }
}
