


















import Vue from 'vue';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    RplContainer,
    RplRow,
    RplCol,
  },
})
export default class ContentLayout extends Vue {
  @Prop() public rowClass;

  @Prop() public mainColsBp;
}
