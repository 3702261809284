import { GetterTree } from 'vuex';

import { MapState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<MapState, RootState> = {
  schemeMaps(state) {
    return state.schemePDFMaps;
  },
};
