



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RplContainer } from '@dpc-sdp/ripple-grid';
import Loader from '@/components/shared/Loader.vue';

import { Location } from 'vue-router';
import { previousRouteMixin } from '@/mixins/previousRouteMixin';
import FocusItem from '@/mixins/FocusItem.vue';
import HistoricPDF from './HistoricPDF.vue';
import HistoricHTMLWrapper from './HistoricHTMLWrapper.vue';
import AlertBanner from '../shared/AlertBanner.vue';

@Component({
  mixins: [previousRouteMixin, FocusItem],
  components: {
    AlertBanner,
    Loader,
    RplContainer,
    HistoricHTMLWrapper,
    HistoricPDF,
  },
})
export default class HistoricScheme extends Vue {
  @Prop() versionId;

  @Action('fetchHistoricalScheme', { namespace: 'schemes' })
  private fetchHistoricalScheme;

  @Getter('historicalScheme', { namespace: 'schemes' })
  private historicalScheme!: string;

  @Getter('isHistoricPDF', { namespace: 'schemes' })
  private isHistoricPDF!: boolean;

  previousRoute: Location = { name: 'histories' };

  async mounted() {
    await this.fetchHistoricalScheme(this.versionId);
  }
}
