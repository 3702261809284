import axios, { AxiosResponse } from 'axios';
import { logger } from '@/helpers';

export interface Features {
  amendments: {
    browse: boolean;
    search: boolean;
    stages: boolean;
    dateFilter: boolean;
  };
  histories: {
    available: boolean;
    alertBanner: boolean;
  };
  maps: {
    available: boolean;
  };
  ordinance: {
    fullScreenTables: boolean;
    fullScreenImages: boolean;
    semanticUrls: {
      available: boolean;
      navbar: boolean;
      searchResults: boolean;
    };
    continuousScroll: boolean;
  };
  general: {
    alertBanner: boolean;
    disableAnalytics: boolean;
  };
}

const getFeatures: () => Promise<Features> = () =>
  axios
    .get<Features>('/toggles/planning-scheme-toggles.json')
    .then((response: AxiosResponse) => {
      const features = response.data;
      logger.debug('Retrieved feature toggles:', features);
      return features;
    })
    .catch(() => ({}));

export { getFeatures };
