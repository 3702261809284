









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplDivider } from '@dpc-sdp/ripple-global';
import HistoricFilters from './HistoricFilters.vue';

@Component({
  components: {
    HistoricFilters,
    RplDivider,
  },
})
export default class Sidebar extends Vue {
  @Prop() public title;
}
