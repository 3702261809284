


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { updateTableStyling } from '@/helpers';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import OrdinanceInfo from './OrdinanceInfo.vue';
import { addShowTableButtons } from './ordinance-detail';
import { addShowImageButtons } from './ordinance-image-details';

@Component({
  components: {
    RplCol,
    RplRow,
    OrdinanceInfo,
  },
})
export default class OrdinanceDetail extends Vue {
  @Prop() ordinance;

  @Prop() version;

  @Prop() ordinancePdfUrl;

  mounted(): void {
    setTimeout(() => {
      updateTableStyling(document, 'ordinance-content');
      addShowTableButtons(document);
      addShowImageButtons(document);
    }, 100);
  }
}
