























































import Vue from 'vue';
import { Getter } from 'vuex-class';

import { RplDivider } from '@dpc-sdp/ripple-global';
import { Component } from 'vue-property-decorator';
import CheckboxFilter from '@/components/amendments/CheckboxFilter.vue';
import {
  SearchAmendmentRequestOptions,
  SelectedScheme,
  OrdinanceSchemeDetails,
  FilterOption,
} from '@/models';
import { optionsWithLabels } from './filter-list';
import { updateAmendmentSearchParams } from '../../helpers/search-params';
import DateFilter from './DateFilter.vue';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import FilterSection from '../filter/FilterSection.vue';

const namespace = 'amendment';

@Component({
  components: {
    FilterSection,
    CheckboxFilter,
    DateFilter,
    RplDivider,
    RplCheckbox,
  },
})
export default class Filters extends Vue {
  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('allSchemes')
  private allSchemes!: OrdinanceSchemeDetails[];

  @Getter('isAllSchemesView')
  public isAllSchemesView!: boolean;

  public dpfTitle = 'Development facilitation program';

  public selectedCheckbox: null | boolean | undefined = null;

  get schemeListOptions(): FilterOption[] {
    return this.allSchemes.map((item) => ({
      label: item.title,
      key: item.schemeID,
    }));
  }

  get statusOptions(): FilterOption[] {
    return optionsWithLabels.status;
  }

  get outcomeOptions(): FilterOption[] {
    return optionsWithLabels.outcome;
  }

  get amendmentTypeOptions(): FilterOption[] {
    return optionsWithLabels.amendment;
  }

  get classificationOptions(): FilterOption[] {
    return optionsWithLabels.classification;
  }

  public resetFilters() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filter, ...searchParamsWithoutFilter } = this.searchParams;
    this.selectedCheckbox = false;
    updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...searchParamsWithoutFilter,
      filter: {
        status: undefined,
        outcome: undefined,
        amendmentType: undefined,
        scheme: undefined,
        from: undefined,
        to: undefined,
        dfp: false,
      },
    });
  }

  private updateRouteWithDfpParams(val) {
    this.selectedCheckbox = val;
    const { filter = {} } = this.searchParams;

    updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...this.searchParams,
      page: undefined,
      filter: { ...filter, [`dfp`]: val },
    });
  }

  mounted() {
    this.selectedCheckbox = this.searchParams.filter?.dfp;
  }
}
