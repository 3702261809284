









import Vue from 'vue';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import { RplIcon } from '@dpc-sdp/ripple-icon';

import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    RplCheckbox,
    RplIcon,
  },
})
export default class FilterGroup extends Vue {
  @Prop() title;
}
