































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

import {
  BFormDatepicker,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue';
import DatePickerIcon from '@/components/icons/DatePickerIcon.vue';

@Component({
  directives: { mask },
  components: {
    BInputGroupAppend,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    DatePickerIcon,
  },
})
export default class PSODatePicker extends Vue {
  @Prop() tag!: string;

  @Prop() date!: string;

  selectedDate: Date | '' = '';

  isInputValid: null | boolean = null;

  isDatePickerVisible = false;

  get createId() {
    return `pso-input-${this.tag}`;
  }

  get placeholderText() {
    if (this.tag) {
      return `Enter date ${this.tag}`;
    }
    return '';
  }

  get formattedInput() {
    if (this.selectedDate) {
      const date = new Date(this.selectedDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      this.validateInput(`${day}/${month}/${year}`);
      return `${day}/${month}/${year}`;
    }

    return '';
  }

  set formattedInput(newValue) {
    const parts = newValue.split('/');

    if (parts.length === 3 && this.isValidDate(parts)) {
      const year = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const day = parseInt(parts[0], 10);
      const date = new Date(year, month, day);

      this.selectedDate = date;
    } else {
      this.isInputValid = null;
      this.selectedDate = '';
    }
  }

  validateInput(value) {
    if (value === '') {
      this.isInputValid = null;
    } else if (this.isValidDateString(value)) {
      this.isInputValid = true;
    } else {
      const parts = value.split('/');

      this.isInputValid = this.isValidDate(parts);
    }
  }

  isValidDate(dateStr) {
    const [day, month, year] = dateStr;

    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= new Date(year, month, 0).getDate();
    const isValidYear = year >= 0 && year <= 9999;

    return isValidMonth && isValidDay && isValidYear;
  }

  isValidDateString(dateStr) {
    if (dateStr === '') {
      return false;
    }

    const dateFromInput = new Date(dateStr);
    return Number.isNaN(dateFromInput.getTime());
  }

  formatDateForPost(dateObject) {
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  valueHasChanged(newVal) {
    let dateForPost = '';

    if (newVal) {
      if (newVal instanceof Date) {
        dateForPost = this.formatDateForPost(newVal);
      } else {
        dateForPost = this.formatDateForPost(new Date(newVal));
      }
    }
    if (this.tag === 'from') {
      this.$emit('date-picked', { from: dateForPost, to: '' });
    }

    if (this.tag === 'to') {
      this.$emit('date-picked', { from: '', to: dateForPost });
    }
  }

  @Watch('selectedDate', { immediate: false })
  setDate(newVal) {
    this.valueHasChanged(newVal);
  }

  @Watch('date', { immediate: true })
  setDateFromStore(newVal) {
    if (newVal !== this.selectedDate) {
      this.selectedDate = newVal;
    }
  }
}
