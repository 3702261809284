






































import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import RplIcon from '@dpc-sdp/ripple-icon';
import PSOLink from '@/components/shared/PSOLink.vue';
import { LinkItem } from '@/models/menu.model';
import Menu from './Menu.vue';
import { extractOrdinanceParams } from '@/store/schemes/convert-to-domain';

RplIcon.register({
  subtract: {
    width: 12,
    height: 12,
    paths: [
      {
        d: 'M1 5 H11 V7 H1 V5 z',
      },
    ],
  },
});

@Component({
  components: {
    Menu,
    RplIcon,
    PSOLink,
  },
})
export default class NavIndex extends Vue {
  @Prop() public title;

  @Prop() public menu;

  @Prop() public version;

  @Prop() public versionLabel;

  @Prop() public semanticInNavEnabled;

  public searchQuery;

  public expanded: null | boolean = null;

  get toggleAriaText(): string {
    return this.expanded === null || !this.expanded
      ? 'expand all'
      : 'collapse all';
  }

  get toggleText(): string {
    return this.expanded === null || !this.expanded ? 'Expand' : 'Collapse';
  }

  get isHistoricalNavigator(): boolean {
    return this.version !== undefined;
  }

  public get processedMenu() {
    const pMenu = this.generateParentLinks(this.menu, this.title, this.version);

    if (
      this.$route?.name === 'ordinanceId' ||
      this.$route?.name === 'historicOrdinanceDetail' ||
      this.$route?.name === 'historicPDFTab'
    ) {
      this.setActivePaths(pMenu);
    }

    return pMenu;
  }

  checkBasicMatch(item) {
    return (
      item.route.meta.semanticNumber === this.$route.params.ordinanceID ||
      item.route.meta.ordinanceID === this.$route.params.ordinanceID
    );
  }

  public setActivePaths(branch) {
    let isActivePath = false;
    const fromURL = extractOrdinanceParams(this.$route.params.ordinanceID);

    if (this.$route.params.ordinanceID) {
      if (
        this.isHistoricalNavigator ||
        (fromURL && fromURL.clause && fromURL.clause?.length > 4)
      ) {
        isActivePath = this.setActivePathsForHistoricalNavigator(branch);
      } else {
        isActivePath = this.setActivePathsForNonHistoricalNavigator(
          branch,
          fromURL,
        );
      }
    }

    return isActivePath;
  }

  private setActivePathsForHistoricalNavigator(branch) {
    let isActivePath = false;

    for (let i = 0; i < branch.length; i += 1) {
      const item = branch[i];

      if (this.checkBasicMatch(item)) {
        item.active = true;
        isActivePath = true;
      }

      if (item.children) {
        if (this.setActivePaths(item.children)) {
          item.active = true;
          isActivePath = true;
        }
      }
    }

    return isActivePath;
  }

  private setActivePathsForNonHistoricalNavigator(branch, fromURL) {
    let isActivePath = false;

    for (let i = 0; i < branch.length; i += 1) {
      const fromURLSplit = this.$route.params.ordinanceID.split('-');
      const splitedParts = `${fromURLSplit[0]}-${fromURLSplit[1]}`;
      const item = branch[i];
      const fromAPI = item.route.meta.semanticNumber.split('-');

      if (!fromURL.section || fromURL.schedule) {
        const semanticFromParts = `${fromAPI[0]}-${fromAPI[1]}`;
        if (splitedParts === semanticFromParts) {
          item.active = true;
          isActivePath = true;
        }
      } else if (fromURL.section || !fromURL.schedule) {
        const semanticFromParts = fromAPI[0];
        if (`${fromURL.clause}.${fromURL.subClause}` === semanticFromParts) {
          item.active = true;
          isActivePath = true;
        }
      } else if (this.checkBasicMatch(item)) {
        item.active = true;
        isActivePath = true;
      }

      if (item.children) {
        if (this.setActivePaths(item.children)) {
          item.active = true;
          isActivePath = true;
        }
      }
    }

    return isActivePath;
  }

  private calculateRoute(version, schemeName, ordinanceID, semanticNumber) {
    const params = {
      schemeName,
      ordinanceID: this.semanticInNavEnabled ? semanticNumber : ordinanceID,
    };

    return !version
      ? {
          name: 'ordinanceId',
          params,
          meta: {
            ordinanceID,
            semanticNumber,
          },
        }
      : {
          name: 'historicOrdinanceDetail',
          params,
          meta: {
            ordinanceID,
            semanticNumber,
          },
        };
  }

  public generateParentLinks(branch, schemeName, version) {
    if (!branch) {
      return null;
    }

    return branch.map((item) => {
      const {
        ordinanceID,
        ordinanceLevel,
        ordinanceMapCode,
        ordinanceType,
        children,
        semanticNumber,
      } = item;
      const title = version ? item.text : item.title;
      const newItem: LinkItem = {
        route: this.calculateRoute(
          version,
          schemeName,
          ordinanceID,
          semanticNumber,
        ),
        title,
        isSchedule: ordinanceType === 'Schedule',
        isScheduleSection: ordinanceType === 'Schedule Section',
        identifier: this.calculateOrdinanceIdentifier(
          version,
          semanticNumber,
          title,
          ordinanceType,
        ),
        ordinanceID,
        ordinanceLevel: ordinanceType === 'Schedule' ? '' : ordinanceLevel,
        ordinanceMapCode,
        ordinanceType,
      };

      if (children) {
        newItem.children = this.generateParentLinks(
          children,
          schemeName,
          version,
        );
      }

      return newItem;
    });
  }

  public expandAll(): void {
    (this.$refs.menu as any).expandAll();
  }

  public collapseAll(): void {
    (this.$refs.menu as any).collapseAll();
  }

  public calculateOrdinanceIdentifier(
    version,
    semanticNumber,
    title,
    ordinanceType,
  ) {
    if (version) {
      return ordinanceType === 'Schedule' ? '' : title.split(' ')[0];
    }
    return ordinanceType === 'Schedule' ? '' : semanticNumber;
  }

  @Watch('$route.params', { immediate: false, deep: false })
  onUrlChange(params): void {
    if (!params.ordinanceID) {
      (this.$refs.menu as any).collapseAll();
    }
  }
}
