










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Redirect from '@/components/shared/Redirect.vue';
import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import ChangeInfo from '@/components/ChangeInfo.vue';
import { SelectedScheme } from '@/models';
import { planningSchemeTitle } from '@/helpers/all-schemes';
import AlertBanner from '@/components/shared/AlertBanner.vue';
import Histories from '@/components/histories/Histories.vue';

@Component({
  components: {
    AlertBanner,
    ChangeInfo,
    Histories,
    FeatureToggled,
    Redirect,
  },
})
export default class HistoriesTab extends Vue {
  @Getter('selectedScheme')
  private selectedScheme?: SelectedScheme;

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.selectedScheme?.title || '');
  }

  get selectedSchemeExists(): boolean {
    return this.selectedScheme !== undefined;
  }

  get notFoundUrl(): string {
    return `/404/${this.planningSchemeTitle}`;
  }
}
