import logger from './logging';

const copyToClip = (str) => {
  // Create container for the HTML
  const container = document.createElement('div');
  container.innerHTML = str;

  // Hide element
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = '0';

  // Mount the container to the DOM to make `contentWindow` available
  document.body.appendChild(container);

  // Copy to clipboard
  const wind = window.getSelection() as Selection;
  wind.removeAllRanges();

  const range = document.createRange();
  range.selectNode(container);
  wind.addRange(range);
  document.execCommand('copy');

  // Remove the container
  document.body.removeChild(container);
};

export default (): void => {
  /* eslint-disable func-call-spacing */
  let content = '';
  const clsName = 'ordinance-content';
  const len = document.getElementsByClassName(clsName).length;
  const style =
    'font-family:Arial;float: left;text-align:left;color: #201547;padding: 10px 0;letter-spacing: 1px;font-size: 16px;';
  const ordCls = 'class="ordinance-info__';
  for (let i = 0; i < len; i += 1) {
    const doc = document.getElementsByClassName(clsName)[i].innerHTML;
    const newContent = doc
      .replace(
        /<span class="content__section-symbol" style="font-weight: 600;">/g,
        '<br><span class="content__section-symbol" style="font-weight: 600;">',
      )
      .replace(`${ordCls}historic"`, `style="${style}font-weight: bold;"`)
      .replace(`${ordCls}date"`, `style="${style}"`)
      .replace(`${ordCls}amendment"`, `style="${style}"`)
      .replace('<h1 ', '<h1 style="font-size: 32px; color:  #201547;"')
      .replace('<h3 ', '<h3 style="font-size: 24px; color:  #201547;"')
      .replace(
        /<caption>/g,
        '<caption style="background-color: #d5dade;line-height:2;color: black;">',
      )
      .replace(/<\/table>/g, '</table><br /><br />')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replace(
        /<div class="content__toolbar-wrapper[\s\S]*?<\/div><\/div>|<strong>|<\/strong>|<em>|<\/em>/g,
        ' ',
      )
      .replace(
        /<div [^<>]*class="ordinance-actions"[\s\S]*?<\/div><\/div>/g,
        ' ',
      )
      .replace(
        /<div [^<>]*class="ordinance-actions historical"[\s\S]*?<\/div><\/div>/g,
        ' ',
      )
      .replace(
        /<button [^<>]*class="image-modal__button"[\s\S]*?<\/button>/g,
        ' ',
      )
      .replace(
        /<button [^<>]*class="table-modal__button"[\s\S]*?<\/button>/g,
        ' ',
      );

    content += newContent;
  }

  logger.debug('copied content', content);
  copyToClip(content);
};
