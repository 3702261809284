import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex';
import { api, disallowedSchemeList } from '@/config';
import { logger } from '@/helpers';
import { AllSchemesScheme, isAllSchemes } from '@/helpers/all-schemes';
import {
  OrdinanceSchemeDetails,
  SchemeAmendmentAndGazettalDate,
  SelectedScheme,
} from '@/models';
import { incorporatedDocument } from '@/store/incorporated-documents';

import { loading } from './loading';
import { schemes } from './schemes';
import { maps } from './maps';
import { amendment } from './amendment';
import { alertBanner } from './alert-banner';
import { FilterValidSchemes, RootState } from './types';

Vue.use(Vuex);

export const filterValidSchemes: FilterValidSchemes = (
  data,
  disallowedSchemes,
) =>
  data
    .filter(
      (t) => t?.state?.activeFlag && !disallowedSchemes.includes(t.schemeID),
    )
    .sort((a, b) => a.title.localeCompare(b.title));

export const actions: ActionTree<RootState, RootState> = {
  SET_CURRENTPAGE_ACTION({ commit }, currentPage): void {
    commit('setCurrentPageMutation', currentPage);
  },
  async ensureSelectedScheme(
    { dispatch, commit, rootGetters: { selectedScheme } },
    schemeName,
  ): Promise<any> {
    if (
      selectedScheme &&
      selectedScheme.schemeID &&
      selectedScheme.title === schemeName
    ) {
      logger.debug('selectedScheme found - skip fetching');
    } else if (isAllSchemes(schemeName.toLowerCase())) {
      commit('setSelectedScheme', AllSchemesScheme);
    } else {
      await dispatch('fetchSelectedScheme', schemeName);
    }
  },
  async fetchSelectedScheme({ commit }, schemeTitle): Promise<any> {
    const client = (await api).schemesClient;
    try {
      const selectedScheme = (await client.fetchSchemesByTitle(schemeTitle))
        .data;
      commit('setSelectedScheme', selectedScheme);
    } catch (e) {
      logger.error('Error while fetching scheme: ', e.message);
    }
  },
  async fetchAllSchemes({ commit, state }) {
    if (state.allSchemes.length > 0) return;

    const disallowedSchemes = await disallowedSchemeList;
    (await api).schemesClient
      .fetchSchemes()
      .then((res) => filterValidSchemes(res.data, disallowedSchemes))
      .then((res) => {
        commit('setAllSchemes', res);
      });
  },
};

export const getters: GetterTree<RootState, RootState> = {
  getCurrentPageGetter(state: any) {
    return state.currentPage;
  },
  selectedScheme(state: any): SelectedScheme {
    return state.selectedScheme;
  },
  allSchemes(state: any) {
    return state.allSchemes;
  },
  isAllSchemesView(state: any): boolean {
    return isAllSchemes(state.selectedScheme?.title);
  },
  schemeAmendmentAndDate(state): SchemeAmendmentAndGazettalDate {
    return {
      amendmentNumber: state.selectedScheme?.amendmentNumber,
      gazettalDate: state.selectedScheme?.gazettalDate,
    };
  },
};

export const mutations: MutationTree<RootState> = {
  setCurrentPageMutation: (state, currentPage: number) => {
    state.currentPage = currentPage;
  },
  setSelectedScheme: (state, selectedScheme) => {
    state.selectedScheme = selectedScheme;
  },
  setAllSchemes: (state, schemeList: OrdinanceSchemeDetails[]) => {
    state.allSchemes = schemeList;
  },
};

export default new Vuex.Store<RootState>({
  state: {
    currentPage: 1,
    selectedScheme: undefined,
    allSchemes: [],
  },
  modules: {
    amendment,
    loading,
    schemes,
    incorporatedDocument,
    maps,
    alertBanner,
  },
  mutations,
  getters,
  actions,
});
