

































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { OrdinanceContent, SelectedScheme } from '@/models';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';

import PSOLink from '@/components/shared/PSOLink.vue';
import { copyToClipboard } from '@/helpers';
import { SimpleOrdinance } from '@/store/schemes/types';
import RplLink from '@dpc-sdp/ripple-link';

import CopyIcon from '../icons/Copy.vue';
import PdfIcon from '../icons/Pdf.vue';
import VectorIcon from '../icons/Vector.vue';
import GlobalScheme from './GlobalScheme.vue';
import OrdinanceDetail from './OrdinanceDetail.vue';
import UnpublishedMessage from './UnpublishedMessage.vue';

@Component({
  components: {
    RplCol,
    RplRow,
    CopyIcon,
    PSOLink,
    GlobalScheme,
    OrdinanceDetail,
    PdfIcon,
    VectorIcon,
    RplLink,
    UnpublishedMessage,
  },
})
export default class OrdinanceComponent extends Vue {
  public showCopied = false;

  get isUnpublished(): boolean {
    const isPublished = this.ordinanceDetails?.state?.active;
    return isPublished !== undefined && isPublished === false;
  }

  get ordinanceDetails(): OrdinanceContent {
    return this.ordinance;
  }

  get ordinanceExists(): boolean {
    return !this.ordinance?.error;
  }

  @Getter('getSimpleOrdinances', { namespace: 'schemes' })
  private simpleOrdinances!: SimpleOrdinance[];

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Prop() ordinance;

  @Prop() ordinancePdfUrl;

  @Prop() version;

  @Prop() versionId;

  @Prop() historicalScheme;

  public copyContentToClipboard(): void {
    this.showCopied = true;
    copyToClipboard();
    setTimeout(() => {
      this.showCopied = false;
    }, 1500);
  }

  get indexOfOrdinance() {
    return this.simpleOrdinances.findIndex(
      (obj) => obj.semanticNumber === this.$route.params.ordinanceID,
    );
  }

  get nextSemanticNumber() {
    return this.indexOfOrdinance + 1 < this.simpleOrdinances.length
      ? this.simpleOrdinances[this.indexOfOrdinance + 1].semanticNumber
      : false;
  }

  get previousSemanticNumber() {
    return this.indexOfOrdinance - 1 >= 0
      ? this.simpleOrdinances[this.indexOfOrdinance - 1].semanticNumber
      : false;
  }

  get linkToNextSemantic() {
    return {
      name: 'ordinanceId',
      params: {
        ordinanceID: this.nextSemanticNumber,
      },
    };
  }

  get linkToPreviousSemantic() {
    return {
      name: 'ordinanceId',
      params: {
        ordinanceID: this.previousSemanticNumber,
      },
    };
  }
}
