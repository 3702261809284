

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { SortOrder, SortOrderType } from '@/store/schemes/types';

@Component({
  components: {
    RplIcon,
  },
})
export default class SortableButton extends Vue {
  @Prop() enableSort!: boolean;

  @Prop() sortKey!: string;

  @Prop() text!: string;

  @Prop({ default: SortOrder.NONE }) sortOrder!: SortOrderType;

  @Prop({ default: false }) isSortActive!: boolean;

  get getColor(): 'secondary' | 'primary' {
    return this.isSortActive ? 'secondary' : 'primary';
  }

  get getArrowDirection(): 'up' | 'down' {
    return this.sortOrder === SortOrder.ASC ? 'up' : 'down';
  }

  toggleOrder() {
    const toggle =
      this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    this.$emit('on-sort-change', {
      sortKey: this.sortKey,
      sortOrder: toggle,
    });
  }
}
