





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';

@Component({
  components: {
    RplIcon,
  },
})
export default class PSODocumentLink extends Vue {
  @Prop() iconSize!: string;

  @Prop() downloadName!: string;

  @Prop() url!: string | undefined;

  @Prop() name!: string;

  @Prop() fileSize!: number;

  @Prop() documentType!: string;

  get isURLEmpty() {
    return this.url === undefined || this.url === '';
  }

  get iconSymbol() {
    return this.documentType === 'PDF' ? 'pdf' : 'external_link';
  }

  get generateDownloadName() {
    return this.documentType === 'PDF'
      ? `${this.downloadName}.${this.documentType.toLowerCase()}`
      : false;
  }
}
