









import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    RplIcon,
  },
})
export default class OrdinanceTableModal extends Vue {
  @Prop() content;
}
