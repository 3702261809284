









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { validateUri } from '@/helpers';
import RplCallToAction from '@dpc-sdp/ripple-call-to-action';

@Component({
  components: {
    RplCallToAction,
  },
})
export default class UnpublishedMessage extends Vue {
  @Prop({
    required: false,
    type: String,
    default: 'This ordinance is temporarily unavailable',
  })
  title;

  @Prop() alternateUri;

  @Prop({
    required: true,
    type: String,
  })
  ordinanceTitle;

  get getSummary() {
    const schemeHasBeenTakenOffline =
      'The chosen scheme or related ordinance has been taken offline';
    if (validateUri(this.alternateUri)) {
      return `<p>${schemeHasBeenTakenOffline}. An <a href='${this.alternateUri}'> alternate document</a> has been provided.</p>`;
    }
    return `<p>${schemeHasBeenTakenOffline}. Please contact <a href="mailto:planning.support@transport.vic.gov.au">planning.support@transport.vic.gov.au</a>.</p>`;
  }
}
