




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplForm from '@dpc-sdp/ripple-form';
import { RplTextIcon, RplIcon } from '@dpc-sdp/ripple-icon';
import { setUpFormAccessibilityOptions } from '@/helpers/accessibilityOptions';
import ClearSearch from '../shared/ClearSearch.vue';

@Component({
  components: {
    ClearSearch,
    RplForm,
    RplCol,
    RplRow,
    RplTextIcon,
    RplIcon,
  },
})
export default class HistoricSearchFilter extends Vue {
  @Prop() public sidebar;

  @Prop() public searchTerm;

  @Prop() public searchPopupText;

  @Prop() public helpText;

  public searchQueryModel = {
    model: {
      text: '',
    },
    schema: {
      fields: [
        {
          attributes: {
            'aria-label': 'search',
            autocomplete: 'off',
          },
          type: 'input',
          inputType: 'text',
          label: 'search ordinance',
          styleClasses: [
            'rpl-form__prepend-icon',
            'rpl-form__prepend-icon--search',
          ],
          placeholder: 'Search...',
          model: 'text',
        },
      ],
    },
    tag: 'rpl-fieldset',
    formState: {},
  };

  public enterClicked() {
    this.$emit('search-histories', this.searchQueryModel.model.text);
  }

  public clearSearch() {
    this.searchQueryModel.model.text = '';
    this.$emit('clear-search');
  }

  get leftCol() {
    if (this.sidebar) {
      return { l: 12 };
    }
    return { s: 12, m: 8, l: 5 };
  }

  mounted() {
    // this.$emit('search-histories', this.searchTerm);
    this.searchQueryModel.model.text = this.searchTerm;
    this.$nextTick(() => {
      setUpFormAccessibilityOptions(
        this.$refs.searchForm,
        'search form',
        'clear search',
      );
    });
  }
}
