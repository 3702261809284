




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import RplDocumentLink from '@/components/shared/DocumentLink.vue';

import Loader from '@/components/shared/Loader.vue';
import NavIndex from '@/components/ordinance-navigation/NavIndex.vue';
import HistoricOrdinanceDetail from '@/components/histories/HistoricOrdinanceDetail.vue';
import GlobalScheme from '@/components/scheme/GlobalScheme.vue';

import { buildNav } from '@/components/ordinance-navigation/navigator';
import AmendmentNotFound from '@/components/amendments/AmendmentNotFound.vue';
import { scrollToElement } from '@/helpers/scroll-to-element';
import RplForm from '@dpc-sdp/ripple-form';

import { Tab } from '@/models';
import {
  historicPlanningSchemeTitle,
  selectedVersionTitle,
} from './convert-from-domain';

import HistoricSearchFilter from './HistoricSearchFilter.vue';

import {
  planningSchemePdfDocName,
  planningSchemePdfDownloadName,
  calculateFileSize,
} from '../../helpers/document';
import ClearSearch from '../shared/ClearSearch.vue';

@Component({
  components: {
    ClearSearch,
    NavIndex,
    Loader,
    RplForm,
    RplContainer,
    RplRow,
    RplCol,
    RplDocumentLink,
    GlobalScheme,
    HistoricOrdinanceDetail,
    HistoricSearchFilter,
    AmendmentNotFound,
  },
})
export default class HistoricHTMLTab extends Vue {
  @Prop() versionId;

  @Prop() ordinanceID;

  @Prop() historicalScheme;

  @Prop() selectedScheme;

  public searchQuery = {
    model: {
      text: '',
    },
    schema: {
      fields: [
        {
          label: 'Search',
          attributes: {
            'aria-label': 'search',
            autocomplete: 'off',
          },
          type: 'input',
          inputType: 'text',
          styleClasses: [
            'rpl-form__prepend-icon',
            'rpl-form__prepend-icon--search',
          ],
          placeholder: 'keyword, clause number or amendment number',
          model: 'text',
        },
      ],
    },
    tag: 'rpl-fieldset',
    formState: {},
  };

  get selectedVersionTitle() {
    return selectedVersionTitle(this.historicalScheme);
  }

  get tabs(): Tab[] {
    if (this.selectedScheme.schemeID.toLowerCase() === 'vpp') {
      return [
        {
          label: 'Ordinance View',
          key: 'pdfView',
          icon: 'list',
        },
      ];
    }

    return [
      {
        label: 'Ordinance View',
        key: 'pdfView',
        icon: 'list',
      },
      {
        label: 'Map view',
        key: 'mapView',
        icon: 'map_marker',
      },
    ];
  }

  public clearSearch() {
    this.searchQuery.model.text = '';
  }

  get menu() {
    let menuModel = [];
    const { clauses } = this.historicalScheme;
    if (clauses && clauses.length > 0) {
      menuModel = buildNav(clauses);
    }

    return menuModel;
  }

  get schemeName() {
    return this.selectedScheme.title;
  }

  get planningSchemeTitle(): string {
    return historicPlanningSchemeTitle(this.historicalScheme.title);
  }

  get isHistoricOrdinanceDetail(): boolean {
    return this.$route?.name === 'historicOrdinanceDetail';
  }

  get pdf() {
    let pdf;
    const { documentURL: url, documentSize } = this.historicalScheme;

    if (url) {
      pdf = {
        name: planningSchemePdfDocName(this.historicalScheme.title, true),
        url,
        extension: 'pdf',
        filesize: calculateFileSize(documentSize as any),
      };
    }

    return pdf;
  }

  get pdfDownloadName() {
    return planningSchemePdfDownloadName(this.historicalScheme.title, true);
  }

  public enterClicked(): void {
    const searchQuery = this.searchQuery?.model?.text;
    if (searchQuery) {
      this.$router.push({
        name: 'historiesSearch',
        params: {
          schemeName: this.selectedScheme.title,
        },
        query: {
          q: searchQuery,
        },
      });
    }
  }

  created() {
    scrollToElement('.app');
  }
}
