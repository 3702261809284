









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RplForm from '@dpc-sdp/ripple-form';
import { FormData } from '@/models/scheme-search.model';

@Component({
  name: 'SearchSimpleComponent',
  components: {
    RplForm,
  },
})
export default class SearchSimpleComponent extends Vue {
  @Prop() prefillSearchTerm;

  public formData: FormData = {
    model: {
      text: '',
    },
    schema: {
      fields: [
        {
          attributes: {
            'aria-label': 'search',
            autocomplete: 'off',
          },
          type: 'input',
          inputType: 'text',
          labelClass: 'sr-only',
          styleClasses: [
            'rpl-form__prepend-icon',
            'rpl-form__prepend-icon--search',
          ],
          placeholder: 'For example: Amendment number or description',
          model: 'text',
        },
      ],
    },
    tag: 'rpl-fieldset',
    formState: {},
  };

  get updatedFormData() {
    this.formData.model.text =
      this.$route.query && this.$route.query.q
        ? this.$route.query?.q.toString()
        : '';
    return this.formData;
  }

  public getSearchResults() {
    if (this.formData.model.text !== '')
      this.$emit('searchUpdated', this.formData.model.text.trim());
  }
}
