





















































import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import {
  SelectedScheme,
  SchemeStructure,
  Navigation,
  RippleDocumentLink,
} from '@/models';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { uppercaseFirstChar, logger } from '@/helpers';
import { featureEnabled } from '@/helpers/feature-toggles';

import RplDocumentLink from '@/components/shared/DocumentLink.vue';
import Loader from '@/components/shared/Loader.vue';
import Redirect from '@/components/shared/Redirect.vue';

import { amListUrl } from '@/config';
import FocusItem from '@/mixins/FocusItem.vue';
import AlertBanner from '@/components/shared/AlertBanner.vue';
import Router from 'vue-router';
import GlobalScheme from './GlobalScheme.vue';
import NavIndex from '../ordinance-navigation/NavIndex.vue';
import UnpublishedMessage from './UnpublishedMessage.vue';
import { planningSchemePdfDownloadName } from '../../helpers/document';
import SchemeSearchAndDocuments from './SchemeSearchAndDocuments.vue';
import { makeAmListPDF, makeSchemePdf } from './scheme-container';

const { isNavigationFailure } = Router;

@Component({
  mixins: [FocusItem],
  components: {
    AlertBanner,
    Redirect,
    RplContainer,
    RplRow,
    RplCol,
    Loader,
    NavIndex,
    GlobalScheme,
    RplDocumentLink,
    UnpublishedMessage,
    SchemeSearchAndDocuments,
  },
})
export default class SchemeContainer extends Vue {
  public hideGblSchemeV = true;

  public schemePDF: RippleDocumentLink | undefined = {
    name: '',
    url: '',
    extension: '',
    filesize: '',
  };

  public amListPDF: RippleDocumentLink | undefined = {
    name: '',
    url: '',
    extension: '',
  };

  public unpublished = false;

  public alternateUri;

  public semanticInNavEnabled = false;

  @Prop() public schemeName;

  @Prop() ordinanceID;

  @Action('fetchSchemeDetail', { namespace: 'schemes' })
  private ensureExistsSchemeDetail;

  @Getter('scheme', { namespace: 'schemes' })
  public scheme!: SchemeStructure;

  @Getter('schemeNavigation', { namespace: 'schemes' })
  public schemeNavigation!: Navigation[];

  @Getter('selectedScheme')
  public selectedScheme!: SelectedScheme;

  get selectedSchemeExists() {
    return this.selectedScheme !== undefined;
  }

  get pdfDownloadName() {
    return planningSchemePdfDownloadName(this.scheme.title);
  }

  get title(): string {
    return `${this.scheme.title} Planning Scheme`;
  }

  get isSearch(): boolean {
    return this.$route?.name === 'ordinanceSearch';
  }

  get planningSchemeTitle() {
    return uppercaseFirstChar(this.scheme.title);
  }

  get redirectUrl() {
    return `/404/${this.planningSchemeTitle}`;
  }

  get hideGblScheme() {
    return !!this.ordinanceID;
  }

  private async schemeUpdated() {
    if (this.scheme && this.scheme.schemeID) {
      const { state } = this.scheme;

      if (state && state?.active === false) {
        this.unpublished = true;
        this.alternateUri = state?.altUri;
      } else {
        this.amListPDF = makeAmListPDF(
          await amListUrl,
          this.scheme?.schemeID,
          this.planningSchemeTitle,
        );

        this.schemePDF = makeSchemePdf(
          this.planningSchemeTitle,
          this.scheme.documentSize,
          this.scheme.documentURL,
        );
        this.hideGblSchemeV = false;
      }
    }
  }

  async mounted() {
    this.semanticInNavEnabled = await featureEnabled(
      'ordinance.semanticUrls.navbar',
    );
    await this.ensureExistsSchemeDetail()
      .then(() => {
        this.schemeUpdated();
      })
      .catch((error) => {
        logger.error('Error while fetching ordinance', error);
      });
  }

  @Watch('$route', { immediate: false, deep: false })
  handleDownloads(val) {
    if (val.hash === '#documents') {
      this.$router.push({ name: 'ordinance' }).catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
    }
  }
}
