




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Redirect extends Vue {
  @Prop() public options;

  mounted() {
    // TODO: Debug why
    if (this.options !== '/404/Victoria Planning Provisions/maps')
      this.$router.push(this.options);
  }
}
