

















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { SearchAmendmentRequestOptions, SelectedScheme } from '@/models';
import { updateAmendmentSearchParams } from '@/helpers/search-params';
import { RplCol } from '@dpc-sdp/ripple-grid';

const namespace = 'amendment';

@Component({
  components: {
    RplCol,
  },
})
export default class ResultsPerPage extends Vue {
  public limit = 20;

  @Prop() public options;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  get routeQueryLimit(): any {
    return Number(this.searchParams?.limit) || this.limit;
  }

  public calculateActive(limit): boolean {
    return limit === this.routeQueryLimit;
  }

  public isLastElement(options, index): boolean {
    return index !== options.length - 1;
  }

  public setLimitPerPage(limit: string) {
    updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...this.searchParams,
      limit,
      page: undefined,
    });
  }
}
