













import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DocumentsFilters extends Vue {
  public resetFilters() {
    this.$emit('reset-filters');
  }
}
