import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { MapState } from './types';
import { RootState } from '../types';

export const maps: Module<MapState, RootState> = {
  namespaced: true,
  state: {
    schemePDFMaps: undefined,
  },
  getters,
  actions,
  mutations,
};
