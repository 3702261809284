































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import Loader from '@/components/shared/Loader.vue';
import ContentLayout from '@/components/shared/ContentLayout.vue';
import AmendmentNotFound from '@/components/amendments/AmendmentNotFound.vue';
import {
  Summary,
  Details,
  Stages,
  Schemes,
} from '@/components/amendments/detail';
import { RplIcon } from '@dpc-sdp/ripple-icon';

import { scrollToElement } from '@/helpers/scroll-to-element';
import FocusItem from '@/mixins/FocusItem.vue';
import AlertBanner from '@/components/shared/AlertBanner.vue';
import PSOLink from '@/components/shared/PSOLink.vue';

const namespace = 'amendment';

@Component({
  mixins: [FocusItem],
  components: {
    AlertBanner,
    RplRow,
    RplCol,
    ContentLayout,
    Loader,
    Summary,
    Details,
    RplIcon,
    Stages,
    Schemes,
    AmendmentNotFound,
    PSOLink,
  },
})
export default class AmendmentDetail extends Vue {
  @Prop() public amendmentNumber;

  @Action('fetchAmendmentDetail', { namespace })
  fetchAmendmentDetail;

  public goBackOrToAmendments() {
    if (window.history.length > 2) {
      this.$router.go(-1);
    } else {
      this.$router.push({ name: 'amendments' });
    }
  }

  async created() {
    await this.fetchAmendmentDetail({
      amendmentNumber: this.amendmentNumber,
      schemeCode: this.$route.query.schemeCode,
    });
    scrollToElement('.app');
  }
}
