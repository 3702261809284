export enum OrdinanceType {
  Clause = 'Clause',
  SubClause = 'Sub-Clause',
  SubClauseSection = 'Sub-Clause Section',
  ScheduleSection = 'Schedule Section',
  Schedule = 'Schedule',
}

const invertedOrdType: {
  [k: string]: OrdinanceType;
} = {
  Clause: OrdinanceType.Clause,
  'Sub-Clause': OrdinanceType.SubClause,
  'Sub-Clause Section': OrdinanceType.SubClauseSection,
  'Schedule Section': OrdinanceType.ScheduleSection,
  Schedule: OrdinanceType.Schedule,
};

const ordinanceTypeToDomain: (type: string) => OrdinanceType = (type) =>
  invertedOrdType[type];

const ordinanceTypeFromDomain: (type: OrdinanceType) => string = (type) =>
  OrdinanceType[type];

const removeStrikeTags: (rawHtml: string | undefined) => string | undefined = (
  rawHtml,
) => (rawHtml ? rawHtml.replace(/<\/?\s*strike\s*\/?>/gi, '') : undefined);

export { ordinanceTypeToDomain, ordinanceTypeFromDomain, removeStrikeTags };
