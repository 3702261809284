





























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import RplPagination from '@dpc-sdp/ripple-pagination';

import { Sidebar, SearchAmendmentResultTable } from '@/components/amendments';
import Loader from '@/components/shared/Loader.vue';
import Redirect from '@/components/shared/Redirect.vue';
import { Amendment as AmendmentModel, SelectedScheme, Pager } from '@/models';
import ContentLayout from '@/components/shared/ContentLayout.vue';
import { planningSchemeTitle } from '@/helpers/all-schemes';
import { updateAmendmentSearchParams } from '@/helpers/search-params';
import { scrollToElement } from '@/helpers/scroll-to-element';

const namespace = 'amendment';

@Component({
  components: {
    Loader,
    Redirect,
    RplContainer,
    RplRow,
    RplCol,
    RplPagination,
    Sidebar,
    SearchAmendmentResultTable,
    ContentLayout,
  },
})
export default class Amendments extends Vue {
  @Prop() public schemeName;

  @Getter('getAmendments', { namespace })
  public results!: AmendmentModel[];

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Getter('getPager', { namespace })
  public pager!: Pager;

  @Getter('searchParams', { namespace })
  private searchParams;

  @Getter('isAllSchemesView')
  private isAllSchemesView!: boolean;

  @Action('setFocusedItem', { namespace: 'schemes' })
  private setFocused;

  @Action('searchAmendments', { namespace })
  searchAmendments;

  get emptySearchResult() {
    return this.results?.length === 0;
  }

  get selectedSchemeExists(): boolean {
    return this.selectedScheme !== undefined;
  }

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.schemeName);
  }

  get redirectUrl(): string {
    return `/404/${this.planningSchemeTitle}`;
  }

  public async goToPage(page) {
    await updateAmendmentSearchParams(
      this.$router,
      this.selectedScheme.title,
    )({
      ...this.searchParams,
      page,
    });
    scrollToElement('.app');
    this.setFocused('#item-0');
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChanged() {
    this.searchAmendments(this.$route.query);
  }
}
