import {
  Stages,
  StageHeaderData,
  StageStatus,
  StageStatusSymbol,
  StageDetailsType,
} from './stages.model';

export const panelHearingStatus = (
  model: Stages = {
    exhibitionRequired: false,
    panelRequested: false,
    decisionRequired: false,
  },
  amendmentStatus: string | null = null,
  now: () => Date = () => new Date(),
): StageStatusSymbol => {
  const { exhibitionRequired, panelRequested, exhibition, panelHearing } =
    model;
  const amendmentPanelStages = [
    'Panel requested',
    'Panel appointed',
    'Panel hearing',
    'Submissions Under Assessment',
  ];

  if (amendmentPanelStages.includes(amendmentStatus as string)) {
    return StageStatus.PENDING;
  }

  if (!exhibitionRequired) {
    return StageStatus.NOT_REQUIRED;
  }

  if (!exhibition || new Date(exhibition.endDate) > now()) {
    return StageStatus.PENDING;
  }

  if (!panelRequested) {
    return StageStatus.NOT_REQUIRED;
  }

  if (!panelHearing) {
    return StageStatus.PENDING;
  }

  return StageStatus.COMPLETE;
};

export const panelHearingData = (
  model: Stages = {
    exhibitionRequired: false,
    panelRequested: false,
    decisionRequired: false,
  },
  amendmentStatus: string | null = null,
  now: () => Date = () => new Date(),
): StageHeaderData[] => {
  const stage = panelHearingStatus(model, amendmentStatus, now);

  switch (stage) {
    case amendmentStatus === 'Submissions Under Assessment' &&
      StageStatus.PENDING:
      return [{ value: '' }];
    case StageStatus.NOT_REQUIRED:
      return [{ value: 'Not required' }];
    case StageStatus.PENDING:
      return [{ value: 'Pending' }];
    default:
      return [
        {
          label: 'Panel requested date',
          value: model.panelHearing?.requestedDate,
          type: StageDetailsType.Date,
        },
        {
          label: 'Panel appointed date',
          value: model.panelHearing?.appointedDate,
          type: StageDetailsType.Date,
        },
      ];
  }
};
