import { GetterTree } from 'vuex';

import { filterHistories } from '@/components/histories/filter-params';
import {
  dateSort,
  inDateRange,
  textSort,
  toDateOrNull,
} from '@/helpers/sortable';
import { Sorting } from '@/store/schemes/types';
import { RootState } from '../types';
import {
  DateFilter,
  IncorporatedDocument,
  IncorporatedDocumentState,
} from './types';

export const getters: GetterTree<IncorporatedDocumentState, RootState> = {
  getDocuments({ documents }): IncorporatedDocument[] {
    return documents;
  },
  getFilteredDocuments({
    documents,
    dateFilter,
    searchQuery,
    sortParams,
    selectedTypeOptions,
    selectedStatusOptions,
  }): IncorporatedDocument[] {
    const { from, to } = dateFilter;
    const dateRangeChecker = inDateRange(toDateOrNull(from), toDateOrNull(to));
    return documents
      .filter(
        ({ title, introducedByAmendmentNumber }) =>
          filterHistories(title, searchQuery) ||
          filterHistories(introducedByAmendmentNumber, searchQuery),
      )
      .filter((x) => {
        const date = toDateOrNull(x.introducedByDate);
        return date === null ? !(from || to) : dateRangeChecker(date);
      })
      .filter((x) => {
        if (selectedTypeOptions.length > 0) {
          return x.type && selectedTypeOptions.includes(x.type.toLowerCase());
        }
        return true;
      })
      .filter((x) => {
        if (selectedStatusOptions.length > 0) {
          return (
            x.status && selectedStatusOptions.includes(x.status.toLowerCase())
          );
        }
        return true;
      })
      .sort((a, b) => {
        if (sortParams) {
          switch (sortParams.sortKey) {
            case 'status': {
              return textSort(a.status, b.status, sortParams.sortOrder);
            }
            case 'type': {
              return textSort(a.type || '', b.type || '', sortParams.sortOrder);
            }
            case 'documentName': {
              return textSort(a.title, b.title, sortParams.sortOrder);
            }
            case 'amendmentNo': {
              return textSort(
                a.introducedByAmendmentNumber || '',
                b.introducedByAmendmentNumber || '',
                sortParams.sortOrder,
              );
            }
            case 'operationalDate': {
              return dateSort(
                toDateOrNull(a.introducedByDate),
                toDateOrNull(b.introducedByDate),
                sortParams.sortOrder,
              );
            }
            default: {
              return 0;
            }
          }
        }
        return 0;
      });
  },
  getSearchTerm({ searchQuery }): string {
    return searchQuery;
  },
  getDateFilter(state): DateFilter {
    return state.dateFilter;
  },
  getSortParams(state): Sorting | undefined {
    return state.sortParams;
  },
  getPageSize(state): number {
    return state.pageSize;
  },
  getTypeOptions(state): string[] {
    return state.typeFilterOptions;
  },
  getStatusOptions(state): string[] {
    return state.statusFilterOptions;
  },
  getSelectedStatus(state): string[] {
    return state.selectedStatusOptions;
  },
  getSelectedType(state): string[] {
    return state.selectedTypeOptions;
  },
};
