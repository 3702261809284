import Vue from 'vue';
import OpenImageModalButton from './OpenImageModalButton.vue';

const forEachImage = function forEachImage<T>(
  func: (value: T, index: number, array: T[]) => void,
) {
  const images = document.querySelectorAll('.ordinance img');

  Array.prototype.forEach.call(images, func);
};

const createImageWrapper = (
  image: HTMLElement,
  className: string,
  vueDataID: string,
): HTMLElement => {
  const el = document.createElement('div');

  el.className = className;
  el.setAttribute(vueDataID, '');
  image.parentNode!.insertBefore(el, image);
  el.appendChild(image);

  return el;
};

const createOpenImageModalButton = (
  imageString: string,
  mountingId: string,
): void => {
  const constructor = Vue.extend(OpenImageModalButton);
  const openImageModalComponent = new constructor({
    propsData: {
      content: [imageString],
    },
  });

  openImageModalComponent.$mount(`#${mountingId}`);
};

const getImageOrdinanceNumber = (image: Element): string => {
  const parent = image.closest('.ordinance-section');
  return parent === null
    ? 'no-tag'
    : parent
        .querySelector('.last-tag')!
        .innerHTML.trim()
        .replaceAll(/[^-a-zA-Z0-9]+/g, '_');
};

const createMountingAnchor = (
  parent: ParentNode,
  mountingId: string,
): HTMLElement => {
  const el = document.createElement('a');

  el.id = mountingId;
  (parent as HTMLElement).appendChild(el);

  return el;
};

export const addShowImageButtons = (document: Document): void => {
  const vueDataId =
    document.querySelector('.ordinance-section')?.attributes[0].name || '';

  forEachImage((image: HTMLImageElement, i: number) => {
    if (
      image.parentElement !== null &&
      image.parentElement.className !== `ordinance-section__image clause-${i}`
    ) {
      createImageWrapper(
        image,
        `ordinance-section__image clause-${i}`,
        vueDataId,
      );

      const ordinanceNumber = getImageOrdinanceNumber(image);
      const mountingId = `modal-${ordinanceNumber}__${i}`;

      createMountingAnchor(image.parentElement, mountingId);
      createOpenImageModalButton(image.src, mountingId);
    }
  });
};
