// import 'current-script-polyfill';
import './class-component-hooks';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import RplGlobal from '@dpc-sdp/ripple-global';
import VueGtag from 'vue-gtag';
import store from '@/store';
import router from '@/router';
import App from '@/App.vue';
import { capitalizeFirstLetter, formatDate, formatBytes } from '@/filters';
import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import VModal from 'vue-js-modal';
import 'viewerjs/dist/viewer.min.css';
import VueViewer from 'v-viewer';
import { features } from './config';

Vue.config.productionTip = false;

features
  .then((config) =>
    Vue.use(
      VueGtag,
      {
        config: { id: 'G-FPDZN5D084' },
        disableScriptLoad: config.general.disableAnalytics,
      },
      router,
    ),
  )
  .catch((e) => console.log(e));

Vue.use(RplGlobal, {});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
Vue.use(VueRouter);
Vue.use(VModal);
Vue.use(VueViewer);
Vue.filter('capitalizeFirstLetter', (value) => capitalizeFirstLetter(value));
Vue.filter('formatDate', (value) => formatDate(value));
Vue.filter('formatBytes', (value, decimal) => formatBytes(value, decimal));
Vue.component('FeatureToggled', FeatureToggled);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
