



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { RplAlertBase } from '@dpc-sdp/ripple-alert';

@Component({
  components: {
    RplAlertBase,
  },
})
export default class HistoricInfoMessage extends Vue {
  @Prop() pdfMapMessage;
}
