




























































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { refineHeader } from '@/helpers/render.helper';
import MenuItem from '@/components/ordinance-navigation/MenuItem.vue';

@Component({
  name: 'Menu',
  components: {
    MenuItem,
  },
})
export default class Menu extends Vue {
  get schemeTitle() {
    return this.$store.getters['schemes/schemeTitle'];
  }

  @Prop() menu;

  @Prop() open;

  @Prop() depth;

  @Prop() isHistoricalNavigator;

  public menuItemOpen;

  constructor() {
    super();
    this.menuItemOpen = this.prepareOpenStates();
  }

  public getHeader(val): string {
    return refineHeader(val);
  }

  public prepareOpenStates() {
    const menuItemOpen = {};
    this.menu.forEach((item, index) => {
      menuItemOpen[index] = item.active === true;
    });
    return menuItemOpen;
  }

  public toggleSection(index) {
    this.menuItemOpen[index] = !this.menuItemOpen[index];
  }

  public expandSection(index) {
    this.menuItemOpen[index] = true;
  }

  public start({ scrollHeight }) {
    (this.$el as any).style.height = `${scrollHeight}px`;
  }

  public end() {
    (this.$el as any).style.height = '';
  }

  public expandAll(): void {
    Object.keys(this.menuItemOpen).forEach((key) => {
      this.menuItemOpen[key] = true;
    });
    if (this.$refs.childMenu) {
      (this.$refs.childMenu as Array<Menu>).forEach((menu) => menu.expandAll());
    }
  }

  public collapseAll(): void {
    Object.keys(this.menuItemOpen).forEach((key) => {
      this.menuItemOpen[key] = false;
    });
    if (this.$refs.childMenu) {
      (this.$refs.childMenu as Array<Menu>).forEach((menu) =>
        menu.collapseAll(),
      );
    }
  }
}
