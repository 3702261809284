



















import Vue from 'vue';
import { Getter } from 'vuex-class';

import { Component } from 'vue-property-decorator';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import { AlertBannerModel } from '@/models/alert-banner';

@Component({
  components: {
    RplAlertBase,
  },
})
export default class AlertBanner extends Vue {
  @Getter('getAlertBannerOptions', { namespace: 'alertBanner' })
  public bannerOptions!: AlertBannerModel[];

  public calculateRoute(banner) {
    const routePath = this.$route.path;

    if (/ordinance/i.test(routePath)) {
      return banner.ordinance;
    }
    if (/docs/i.test(routePath)) {
      return banner.incorporatedDocuments;
    }
    if (/amendments/i.test(routePath)) {
      return banner.amendments;
    }
    if (/histories/i.test(routePath)) {
      return banner.histories;
    }
    if (/maps/i.test(routePath)) {
      return banner.maps;
    }
    return false;
  }
}
