










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import AlertBanner from '@/components/shared/AlertBanner.vue';

import Redirect from '@/components/shared/Redirect.vue';
import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import ChangeInfo from '@/components/ChangeInfo.vue';

import { planningSchemeTitle } from '@/helpers/all-schemes';
import { SelectedScheme } from '@/models';

import Map from '@/components/maps/Map.vue';

@Component({
  components: {
    AlertBanner,
    ChangeInfo,
    FeatureToggled,
    Map,
    Redirect,
  },
})
export default class Maps extends Vue {
  @Getter('selectedScheme')
  private selectedScheme?: SelectedScheme;

  get schemeId() {
    return this.selectedScheme?.schemeID;
  }

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.selectedScheme?.title || '');
  }

  get selectedSchemeExists(): boolean {
    return this.selectedScheme !== undefined;
  }

  get notFoundUrl(): string {
    return `/404/${this.planningSchemeTitle}`;
  }
}
