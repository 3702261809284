






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import StageHeader from './StageHeader.vue';
import { getStageIcon } from './stage-details';
import { StageHeaderData } from './stages.model';
import { getStatus, getData } from './outcome-data';

@Component({ components: { StageHeader } })
export default class Outcome extends Vue {
  @Prop() model; // OutcomeDetails

  get status(): string {
    return getStageIcon(getStatus(this.model));
  }

  get getData(): StageHeaderData[] {
    return getData(this.model);
  }
}
