import dayjs from 'dayjs';

export const isNumber = (num: any): boolean => {
  if (typeof num === 'number') {
    return num - num === 0;
  }
  if (typeof num === 'string' && num.trim() !== '') {
    return Number.isFinite ? Number.isFinite(+num) : Number.isFinite(+num);
  }
  return false;
};

export const validateString = (possibleValues: string[], val: any): boolean =>
  !val || val === '' || Array.isArray(val)
    ? false
    : possibleValues.includes(val);

export const isDate = (x: string | undefined | null): boolean => {
  if (x === undefined || x === null) return false;
  return dayjs(x).isValid();
};
