import { OrdinanceType } from './ordinance-type';

const isNumeric: (value: string) => boolean = (value) =>
  /^[-.\d]+$/.test(value);

const ordinanceNumberFromTitle = (title?: string): string => {
  if (!title) {
    return '';
  }
  const regex = /<\/?mark>/gi;

  const titleWithoutMark = title.replace(regex, '');

  if (isNumeric(titleWithoutMark[0])) {
    return titleWithoutMark.split(' ', 2)[0];
  }
  const parts = titleWithoutMark.split(' ', 3);
  if (parts[0].toLocaleUpperCase() === 'SCHEDULE') {
    if (isNumeric(parts[1])) {
      return [parts[0], parts[1]].join(' ');
    }
    return parts[0];
  }
  return '';
};

const extractTags = (title: string, type: string): Array<string> => {
  const ordinanceNumber = ordinanceNumberFromTitle(title);
  switch (type) {
    case OrdinanceType.Clause:
    case OrdinanceType.SubClause:
    case OrdinanceType.SubClauseSection:
    case OrdinanceType.ScheduleSection:
      return [ordinanceNumber];
    case OrdinanceType.Schedule:
      return ['Schedule'];
    default:
      return [];
  }
};

const removeOrdinanceNumber: (title?: string) => string | undefined = (
  title,
) => {
  if (!title) {
    return undefined;
  }
  if (isNumeric(title[0])) {
    return title.substr(title.indexOf(' ') + 1);
  }
  return title;
};

const extractOrdinanceNumber: (title?: string) => string | undefined = (
  title,
) => {
  if (!title) {
    return undefined;
  }
  if (isNumeric(title[0])) {
    const [number] = title.split(' ');
    return number;
  }
  return undefined;
};

const extractTitles: (
  title?: string,
  parentTitle?: string,
) => { title?: string; parentTitle?: string } = (title, parentTitle) => ({
  title: removeOrdinanceNumber(title),
  parentTitle: removeOrdinanceNumber(parentTitle),
});

export {
  ordinanceNumberFromTitle,
  extractTags,
  extractTitles,
  removeOrdinanceNumber,
  extractOrdinanceNumber,
};
