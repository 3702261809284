












import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SelectedScheme, OrdinanceContent } from '@/models';
import {
  isSemanticOrdinanceNumber,
  parseOrdinanceNumber,
} from '@/store/schemes/convert-to-domain';
import getPageTitle from '@/helpers/page-title';
import Ordinance from './Ordinance.vue';

interface ScrollPosition {
  scrollHeight: number;
  scrollTop: number;
}

@Component({
  components: {
    Ordinance,
  },
  metaInfo() {
    const title = (this as SchemeOrdinance).pageTitle;
    return {
      title,
    };
  },
})
export default class SchemeOrdinance extends Vue {
  public pageTitle = '';

  public ordinance: OrdinanceContent = {
    id: '',
    number: '',
    amendmentNumber: '',
    title: '',
    content: '',
  };

  @Prop() ordinanceID;

  @Getter('selectedScheme')
  private selectedScheme!: SelectedScheme;

  @Action('getOrdinance', { namespace: 'schemes' })
  private getOrdinance!: any;

  public scrollToOrdinanceContent(): any {
    let el;

    if (isSemanticOrdinanceNumber(this.ordinanceID)) {
      el = this.$el.querySelector(
        `[scroll-to-number='${parseOrdinanceNumber(this.ordinanceID)}']`,
      );
    } else if (this.$route.hash) {
      el = this.$el.querySelector(`[id="${this.$route.hash.split('#')[1]}"]`);
    } else {
      el = document.getElementById(this.ordinanceID);
    }

    if (el) {
      const ordinanceContentPosition = el.getBoundingClientRect().top;
      const currentScrollPosition = el.ownerDocument.defaultView.pageYOffset;
      const top = ordinanceContentPosition + currentScrollPosition;

      if (window.innerWidth < 768) {
        let height = 0;
        const actionButtons = document.querySelector(
          '.ordinance-actions-small',
        ) as HTMLElement;

        if (actionButtons) height = actionButtons.offsetHeight;
        window.scrollTo(0, top - height - 25);
      } else {
        const activeElementInMenu = document.querySelector(
          '.pso-tabs .router-link-active',
        );
        const activeElementInNav = document.querySelector(
          '.rpl-section-menu .router-link-active',
        );

        if (activeElementInMenu) {
          const rect = activeElementInMenu.getBoundingClientRect();

          if (rect.bottom > window.innerHeight || rect.top < 0) {
            activeElementInMenu.scrollIntoView(true);
          }
        }
        if (activeElementInNav) {
          activeElementInNav.scrollIntoView(true);
        }

        window.scrollTo(0, top - 75);
      }
    }
  }

  public setPageTitle() {
    const ordinanceTitle = this.ordinance?.title;
    const ordinanceNumber =
      this.ordinance?.number === 'SCHEDULE' ? '' : this.ordinance?.number;

    if (ordinanceNumber && ordinanceTitle) {
      this.pageTitle = `${ordinanceNumber} ${ordinanceTitle} ${getPageTitle(
        this.$route,
      )}`;
    } else if (ordinanceTitle) {
      this.pageTitle = `${ordinanceTitle} ${getPageTitle(this.$route)}`;
    } else {
      this.pageTitle = getPageTitle(this.$route);
    }
  }

  private async loadOrdinanceAndScrollToTop(): Promise<void> {
    this.ordinance = await this.getOrdinance({
      schemeId: this.selectedScheme.schemeID,
      ordinanceID: this.ordinanceID,
    });

    Vue.nextTick(() => {
      this.scrollToOrdinanceContent();
      this.setPageTitle();
    });
  }

  async mounted() {
    this.loadOrdinanceAndScrollToTop();
  }

  @Watch('ordinanceID', { immediate: false })
  onUrlChange() {
    this.loadOrdinanceAndScrollToTop();
  }
}
