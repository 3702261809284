


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import RplDataTable from '@dpc-sdp/ripple-data-table';
import { formatBytes } from '@/filters';

import { constructPdfLink } from '@/components/histories/historic-links';
import { historicPdfUrl } from '@/config';
import { MapClause } from '@/store/schemes/types';

import Spinner from '../shared/Spinner.vue';
import HistoricInfoMessage from './HistoricInfoMessage.vue';
import HistoricSearchFilter from './HistoricSearchFilter.vue';
import { filterHistories } from './filter-params';

@Component({
  components: {
    HistoricInfoMessage,
    HistoricSearchFilter,
    RplCol,
    RplRow,
    RplDataTable,
    Spinner,
  },
})
export default class HistoricPDFMapTab extends Vue {
  @Prop() versionId;

  @Getter('historicMapPDFSearchTerm', { namespace: 'schemes' })
  private searchTerm;

  @Getter('mapClausesTableRows', { namespace: 'schemes' })
  private mapClausesTableRows!: MapClause[] | [];

  @Getter('mapClausesTableHeaders', { namespace: 'schemes' })
  private mapClausesTableHeaders!: [string, string, string][];

  @Action('fetchHistoricalSchemeMapData', { namespace: 'schemes' })
  private fetchHistoricalSchemeMapPdfData;

  @Action('setHistoricMapPDFSearchTerm', { namespace: 'schemes' })
  private setPDFSearchTerm;

  private dataIsLoading = false;

  public componentKey = 0;

  public filteredItems: MapClause[] = [];

  public baseUrl = '';

  public popUpText = 'Press [Enter] to search historical PDF Map content.';

  public helpText = "For example: 'map number or map type'";

  private isInvalidRecord = false;

  public enterClicked(val) {
    if (val === '' || val === undefined) {
      this.filteredItems = this.mapClausesTableRows;
    } else {
      this.filteredItems = this.mapClausesTableRows.filter(
        (item) =>
          filterHistories(item.mapNumber, val) ||
          filterHistories(item.mapType, val),
      );
    }

    this.setPDFSearchTerm(val);
    this.componentKey += 1;
  }

  public async checkItemValidity() {
    if (
      this.mapClausesTableRows.length === 0 ||
      (this.mapClausesTableRows.length === 1 &&
        !this.mapClausesTableRows[0].mapType)
    ) {
      this.isInvalidRecord = true;
    }
  }

  get filteredItemsWithLinks() {
    let filteredValues: any = [];

    filteredValues = this.filteredItems.map(
      (item): [string, string, string] => [
        constructPdfLink(
          item.fileName ? this.baseUrl + item.fileName : '#',
          item.mapNumber || 'Map (unknown number)',
          true,
        ),
        item.mapType || 'Map (unknown type)',
        formatBytes(item.size),
      ],
    );

    if (filteredValues.length === 0) {
      filteredValues.push(["Sorry! We couldn't find any matches.", '', '']);
    }

    return [this.mapClausesTableHeaders, ...filteredValues];
  }

  public clearSearch() {
    this.setPDFSearchTerm('');
    this.enterClicked('');
  }

  async mounted() {
    this.baseUrl = await historicPdfUrl;

    try {
      this.dataIsLoading = true;

      await this.fetchHistoricalSchemeMapPdfData(this.versionId);
      await this.checkItemValidity();
      this.filteredItems = this.mapClausesTableRows.filter(
        (item) =>
          filterHistories(item.mapNumber, this.searchTerm) ||
          filterHistories(item.mapType, this.searchTerm),
      );
      this.componentKey += 1;
      this.dataIsLoading = false;
    } catch {
      this.dataIsLoading = false;
    }
  }

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'historicPDFTab') {
      this.setPDFSearchTerm('');
    }
    next();
  }
}
