import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=25b41c76&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Footer.vue?vue&type=style&index=1&id=25b41c76&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b41c76",
  null
  
)

export default component.exports