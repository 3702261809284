







import Vue from 'vue';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import { Component, Prop } from 'vue-property-decorator';
import { RplDivider } from '@dpc-sdp/ripple-global';

import Filters from './Filters.vue';
import SearchAmendment from './SearchAmendment.vue';

@Component({
  components: {
    Filters,
    SearchAmendment,
    RplCheckbox,
    RplDivider,
  },
})
export default class Sidebar extends Vue {
  @Prop() public title;
}
