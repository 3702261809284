import {
  SearchAmendmentFilter,
  SearchAmendmentRequestOptions,
  SearchAmendmentQueryParams,
} from '@/models';
import { SortOrder } from '../schemes/types';

export const toStoreFilter = (filterParams: object): SearchAmendmentFilter =>
  Object.entries(filterParams).reduce((acc, [key, value]) => {
    if (!value || !key.includes('filter.', 0)) {
      return acc;
    }
    let val;

    if (key.includes('dfp')) {
      val = Boolean(value);
    } else {
      val =
        key.includes('from') || key.includes('to') ? value : value.split(',');
    }

    const fieldName = key.split('filter.')[1];
    const filter = { [`${fieldName}`]: val };
    return { ...acc, ...filter };
  }, {});

export const toStoreSearchParams = (
  queryParams: SearchAmendmentQueryParams,
): SearchAmendmentRequestOptions => {
  const { limit, page, sortKey, sortOrder, q, ...filterParams } = queryParams;
  const filter: SearchAmendmentFilter = toStoreFilter(filterParams);
  const searchParams = { limit, page, sortKey, sortOrder, q };

  const sortKeyEval =
    searchParams.sortKey === undefined ? 'lastUpdated' : searchParams.sortKey;

  const sortOrderEval =
    searchParams.sortOrder === undefined
      ? SortOrder.DESC
      : searchParams.sortOrder;

  const actualSearch =
    q === '' || q === undefined
      ? { limit, page, sortKey: sortKeyEval, sortOrder: sortOrderEval, q }
      : { limit, page, q };

  if (Object.keys(filter).length === 0) {
    return actualSearch;
  }

  return { ...actualSearch, filter };
};
