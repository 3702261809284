import {
  ExhibitionStage,
  PanelHearingStage,
  StageStatus,
  StageStatusSymbol,
  StageDetailsLabels,
  StageDetails,
  GazettalStage,
  StageDetailsType,
} from './stages.model';
import { groupBy } from 'lodash';

export const getStageDetails = (
  model: ExhibitionStage | PanelHearingStage | GazettalStage,
  labels: StageDetailsLabels[],
): StageDetails[] =>
  labels
    .filter(({ key }) => model[key] != null && Object.keys(model[key]).length)
    .map(({ type, key, label }) => ({
      label,
      value: model[key],
      type,
    }));

export const extractExtension = (documentName: string): string => {
  const parts = documentName.split('.');
  return parts.length! > 1 ? parts.pop()!.toLowerCase() : '';
};

type OtherDocument = Document & {
  classification: string;
};

export const mapOtherDocuments = (
  model: ExhibitionStage | PanelHearingStage | GazettalStage,
): StageDetails[] => {
  const groupedOtherDocuments: Array<Document> = groupBy(
    model.otherDocuments,
    (otherDocument: OtherDocument) => otherDocument.classification,
  );

  return Object.keys(groupedOtherDocuments).map((key) => ({
    label: key,
    value: groupedOtherDocuments[key],
    type: StageDetailsType.DocumentList,
  }));
};

export const getStageIcon = (status: StageStatusSymbol): string => {
  switch (status) {
    case StageStatus.NOT_REQUIRED:
      return 'notRequired';
    case StageStatus.PENDING:
      return 'pending';
    default:
      return 'complete';
  }
};
