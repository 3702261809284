
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { SelectOption, MultiSelectConfig } from '@/models';
import { LoaderStates, LoaderState } from '@/store/loading';

@Component({
  components: {},
})
export default class MultipleSelectMixin extends Vue {
  @Getter('state', { namespace: 'loading' })
  private loaderState!: LoaderState;

  get isRunning() {
    return this.loaderState !== LoaderStates.COMPLETED;
  }

  public multiSelectConfig: MultiSelectConfig = {
    multiselect: true,
    placeholder: 'Select',
    showItems: 1,
    fieldId: 'select',
    inputName: null,
    label: '',
  };

  get multiSelectOptions(): SelectOption[] {
    return [
      {
        selected:
          (this as any).selected.length === (this as any).options.length,
        focussed: true,
        uuid: '',
        id: 'all',
        name: 'All',
        disabled: this.isRunning,
      },
      ...(this as any).options.map((item) => ({
        id: typeof item === 'string' ? item : item && item.key,
        name: typeof item === 'string' ? item : item && item.label,
        selected: (this as any).selected.includes(
          typeof item === 'string' ? item : item && item.key,
        ),
        focussed: false,
        uuid: '',
        disabled: this.isRunning,
      })),
    ];
  }
}
