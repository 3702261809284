







































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Redirect from '@/components/shared/Redirect.vue';
import { mapUrl } from '@/config';
import { RplDivider } from '@dpc-sdp/ripple-global';
import { RplTextLink } from '@dpc-sdp/ripple-link';

import FeatureToggled from '@/components/shared/FeatureToggled.vue';
import { MapMessage } from '@/models/maps.model';
import { featureEnabled } from '@/helpers/feature-toggles';

import { urlToOldSite } from '@/helpers/redirect-to-old-app';
import { planningSchemeTitle } from '@/helpers/all-schemes';
import ContentLayout from '@/components/shared/ContentLayout.vue';
import { SelectedScheme } from '@/models';
import { logger } from '@/helpers';
import { lgaCodeForSchemeName } from './lga-code';

import MapsSidebar from './MapsSidebar.vue';
import PSOLink from '../shared/PSOLink.vue';

@Component({
  components: {
    ContentLayout,
    FeatureToggled,
    MapsSidebar,
    Redirect,
    RplDivider,
    RplTextLink,
    PSOLink,
  },
})
export default class Map extends Vue {
  @Prop({ type: String, required: false }) schemeName;

  @Prop({ type: String, required: false }) schemeId;

  @Getter('selectedScheme')
  private selectedScheme?: SelectedScheme;

  private backingToggle: boolean | null = null;

  private iframeSrc = '';

  get planningSchemeTitle(): string {
    return planningSchemeTitle(this.selectedScheme?.title || '');
  }

  get oldSiteUrl(): string {
    return urlToOldSite('maps', this.planningSchemeTitle);
  }

  get mapUrl() {
    return this.iframeSrc;
  }

  get vicPlanUrl(): string {
    return `https://mapshare.vic.gov.au/Vicplan/index.html?RunWorkflow=LgaSelect&lga=${
      this.selectedScheme ? this.selectedScheme.title : ''
    }`;
  }

  get pdfMapsToggledOn() {
    return this.backingToggle;
  }

  get selectedLgaExists(): boolean {
    if (this.schemeName === 'Browse amendments') {
      return true;
    }
    return lgaCodeForSchemeName(this.schemeName) !== undefined;
  }

  get notFoundUrl(): string {
    return `/404/${this.schemeName}/maps`;
  }

  public postToMap = (payload: MapMessage) => {
    const iframe: HTMLIFrameElement | null =
      document.querySelector('iframe#map');
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
    }
  };

  public setLGA = (schemeName: string) => {
    this.postToMap({ type: 'setLGA', value: lgaCodeForSchemeName(schemeName) });
  };

  public onMapReady() {
    this.setLGA(this.schemeName);
  }

  async mounted() {
    window.addEventListener(
      'message',
      (event) => {
        try {
          if (typeof event.data === 'string') {
            const message = JSON.parse(event.data);

            if (message.type === 'MapReady') {
              this.onMapReady();
            }
          }
        } catch (error) {
          logger.debug('Error parsing JSON data:', error);
        }
      },
      false,
    );

    this.iframeSrc = await mapUrl;
    this.backingToggle = await featureEnabled('maps.pdfMaps');
  }
}
