

























import { Component, Vue } from 'vue-property-decorator';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { Getter } from 'vuex-class';
import { groupedPlanningSchemes } from '@/helpers/grouped-schemes';
import { GroupedSchemes, OrdinanceSchemeDetails } from '@/models';

@Component({
  components: {
    RplContainer,
    RplRow,
    RplCol,
  },
})
export default class Home extends Vue {
  @Getter('allSchemes')
  private allSchemes!: OrdinanceSchemeDetails[];

  get sectionHeaders(): string[] {
    return Object.keys(this.groupedSchemes);
  }

  get groupedSchemes(): GroupedSchemes {
    return groupedPlanningSchemes(this.allSchemes);
  }
}
