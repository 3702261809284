















import Vue from 'vue';

import { Component } from 'vue-property-decorator';
import RplIcon from '@dpc-sdp/ripple-icon';
import { Action } from 'vuex-class';

interface MenuPosition {
  left: number;
  top: number;
}

@Component({
  components: {
    RplIcon,
  },
})
export default class BackToTop extends Vue {
  public visible = false;

  public visibleOffset = 600;

  public visibleOffsetBottom = 0;

  public mobile = false;

  public position = false;

  public mobileBreakPoint = 768;

  public open = false;

  public openState = false;

  @Action('setBackToTopSignal', { namespace: 'schemes' })
  private setBackToTopSignal!: any;

  @Action('setFocusedItem', { namespace: 'schemes' })
  private setFocusedItem;

  public isElementVisible(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const efp = (x, y) => document.elementFromPoint(x, y);

    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    )
      return false;

    return (
      el.contains(efp(rect.left, rect.top)) ||
      el.contains(efp(rect.right, rect.top)) ||
      el.contains(efp(rect.right, rect.bottom)) ||
      el.contains(efp(rect.left, rect.bottom))
    );
  }

  public catchScroll(): void {
    const pastTopOffset = window.pageYOffset > this.visibleOffset;
    const pastBottomOffset =
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - this.visibleOffsetBottom;
    const el = document.querySelector('.footer-inner-row') as HTMLElement;

    this.position = this.isElementVisible(el);
    this.visible =
      this.visibleOffsetBottom > 0
        ? pastTopOffset && !pastBottomOffset
        : pastTopOffset;
  }

  public getScrollToPosition(selector: string): MenuPosition {
    const el = document.querySelector(selector);

    if (el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.bottom + window.scrollY,
      };
    }

    return { left: 0, top: 0 };
  }

  public smoothScroll(): void {
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollToPosition = this.getScrollToPosition('.header-container').top;

    if (currentScroll > scrollToPosition) {
      this.setBackToTopSignal(true);
      window.scrollTo({
        top: scrollToPosition + 2,
        left: 0,
        behavior: 'smooth',
      });
    }

    this.setFocusedItem('.pso-tabs .router-link-active');
  }

  public isMobile() {
    const clientWidth = window.innerWidth;

    if (clientWidth > this.mobileBreakPoint) {
      this.openState = true;
    } else {
      this.openState = false;
    }
  }

  mounted(): void {
    this.smoothScroll();
    window.addEventListener('scroll', this.catchScroll);
    window.addEventListener('resize', this.isMobile);
    this.isMobile();
  }

  destroyed(): void {
    window.removeEventListener('scroll', this.catchScroll);
    window.removeEventListener('resize', this.isMobile);
  }
}
