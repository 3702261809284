import { GetterTree, Module, MutationTree } from 'vuex';
import { LoaderState, LoaderStates, Loading } from './types';
import { RootState } from '../types';

export * from './types';

const loadingState: Loading = {
  loaderState: LoaderStates.NOT_STARTED,
};

const mutations: MutationTree<Loading> = {
  setState(state, payload: LoaderState) {
    state.loaderState = payload;
  },
};

const getters: GetterTree<Loading, RootState> = {
  state({ loaderState }): LoaderState {
    return loaderState;
  },
};

export const loading: Module<Loading, RootState> = {
  namespaced: true,
  state: loadingState,
  getters,
  mutations,
};
