import { GetterTree } from 'vuex';

import {
  Amendment as AmendmentModel,
  Pager,
  SearchAmendmentRequestOptions,
} from '@/models';

import {
  AssessmentParams,
  OutcomeDetails,
  PlanningAuthorityStatuses as PlanningStatuses,
  Stages as StagesVM,
} from '@/components/amendments/detail/stages/stages.model';

import { RootState } from '../types';
import { AmendmentState } from './types';

export const getters: GetterTree<AmendmentState, RootState> = {
  getAmendments({ amendments }): AmendmentModel[] {
    return amendments;
  },
  getPager({ pager }): Pager | undefined {
    return pager;
  },
  stages({ amendmentDetail }): StagesVM {
    return amendmentDetail.stages;
  },
  amendmentDetails(state): any {
    return state.amendmentDetail;
  },
  planningAuthorityContact({ amendmentDetail }): any {
    return amendmentDetail?.planningAuthorityContact;
  },
  affectedSchemes({ amendmentDetail }): any {
    return amendmentDetail?.schemes;
  },
  searchParams({ searchParams }): SearchAmendmentRequestOptions {
    return searchParams!;
  },
  planningAuthorityStatuses({
    amendmentDetail: { status, outcome },
  }): PlanningStatuses {
    return { status, outcome };
  },
  departmentAssessment({
    amendmentDetail: {
      status,
      outcome,
      stages: { decisionRequired, submittedDate },
    },
  }): AssessmentParams {
    return { status, outcome, decisionRequired, submittedDate };
  },
  outcome({ amendmentDetail: { outcome } }): OutcomeDetails {
    return { outcome };
  },
  amendmentStatus({ amendmentDetail: { status } }): string {
    return status;
  },
  totalAmendments({ totalAmendments }) {
    return totalAmendments;
  },
};
