export const calcIndexToSubStr = (title: string): number => {
  let index = 0;
  const splittedTitle = title.split(' ');
  if (splittedTitle?.length > 0) {
    index = splittedTitle[0].length + 1;
  }
  return index;
};

export const refineHeader = (text: string): string => {
  if (!text) return '';
  return text.substr(calcIndexToSubStr(text));
};

export const extractCategory = (text: string): string => {
  if (!text) return '';
  const splittedText = text.split(' ');
  return splittedText[0];
};

export const extractOrdinanceTitle = (text: string): string => {
  const isSchedule = extractCategory(text).toLowerCase() === 'schedule';
  return isSchedule ? text : refineHeader(text);
};

export const formatDateYmd = (date: string): string => {
  if (!date) return '';
  const replacedDate = date.replace(/-/g, '/');
  const myDate = replacedDate.split('/');
  if (myDate[0]?.length === 4) {
    return myDate.reverse().join('/');
  }
  return replacedDate;
};

export const uppercaseFirstChar = (text: string): string => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validateUri = (uri: string): boolean => {
  let url;
  try {
    url = new URL(uri);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

type NumberTransform = (x: number, y: number) => number;

const stepsForTotalMatches: NumberTransform = (total, limit) =>
  Math.ceil(total / limit);

export const pagerForResult: (
  total: number,
  limit: number,
  page: number,
  stepsAround?: number,
) => any = (total, limit, page, stepsAround = 2) => {
  if (total === 0) return null;
  const totalSteps = stepsForTotalMatches(total, limit);
  return {
    totalSteps,
    initialStep: page,
    stepsAround,
  };
};

export const normalizeClassificationLabel = (inputString) => {
  if (!inputString) {
    return '';
  }

  if (inputString === 'Exhibited') {
    return inputString;
  }

  if (inputString === 'Prescribed') {
    return 'Prescribed - S20(A)';
  }

  if (inputString === 'Ministerial') {
    return 'Ministerial - S20(4)';
  }

  return inputString;
};
