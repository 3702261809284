import {
  AlertBannersFromDomain,
  IncorporatedDocumentResponse,
  OrdinanceContent,
  OrdinanceSchemeDetails,
  PDFMapsResponse,
  PlanningSchemeHistoricalVersion,
  PlanningSchemeHistoryResponse,
  SchemeStructure,
  SchemesClient,
} from '@/api/types';
import {
  composeHistoriesFilter,
  flattenFilter,
  formatFilter,
  formatTimeZones,
} from '@/helpers/filter-formatter';
import {
  InitialAmendmentDetail,
  OrdinanceReference,
  PlanningSchemeResultBySchemeIdAndOrdinanceId as PlanningScheme,
  PlanningScheme as PlanningSchemeLite,
  SchemeSearchResponse as SchemeSearch,
  SearchAmendmentResponse,
} from '@/models';

import { logger } from '@/helpers';
import { OrdinanceNumberParams } from '@/store/schemes/types';
import axios from 'axios';
import FileSaver from 'file-saver';
import qs from 'qs';
import { buildOrdinanceNumberParts } from './convert-from-domain';

const successHandler = (response) => response;
const errorHandler = (error) => {
  logger.debug('axios error:', JSON.parse(JSON.stringify(error)));
  return Promise.reject(error);
};
const schemesClient: (baseURL: string) => SchemesClient = (baseURL: string) => {
  // Override default encoding in axios as mention in
  // https://github.com/axios/axios/issues/1111
  axios.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { indices: true }); // param=value1&param=value2
  const client = axios.create({ baseURL });

  client.interceptors.response.use(successHandler, errorHandler);
  return {
    fetchOrdinanceIdForMap: (schemeCode, level, mapCode) =>
      client.get<OrdinanceReference>(
        `/ordinance/scheme/${schemeCode}/map-ref`,
        {
          params: { level, mapCode },
        },
      ),

    fetchSchemes: () => client.get<OrdinanceSchemeDetails[]>('/schemes/'),
    fetchScheme: (schemeId: string) =>
      client.get<SchemeStructure>(`ordinance/scheme/${schemeId}/nav`),
    fetchSchemesByTitle: (title) =>
      client.get<PlanningSchemeLite>('/ordinance/scheme/', {
        params: { title },
      }),
    fetchOrdinance: (schemeId, ordinanceId) =>
      client.get<PlanningScheme>(
        `/schemes/${schemeId}/ordinances/${ordinanceId}`,
      ),
    fetchOrdinanceByNumber: (params: OrdinanceNumberParams) =>
      client.get<OrdinanceContent>(
        `/ordinance/scheme/${params.schemeCode}/${buildOrdinanceNumberParts(
          params,
        )}`,
      ),
    fetchHistoricOrdinance: (schemeId, ordinanceId) =>
      client.get<OrdinanceContent>(
        `/schemes/${schemeId}/history/ordinances/${ordinanceId}`,
      ),
    searchSchemes: (params) =>
      client.get<SchemeSearch>('/schemes/ordinances/', { params }),
    searchAmendments: (params) => {
      const { filter = {}, ...searchParams } = params;

      if (searchParams.sortKey === 'lastUpdated') {
        searchParams.sortKey = 'statusDate';
      }

      return client.get<SearchAmendmentResponse>('/amendments', {
        params: {
          ...searchParams,
          ...flattenFilter(formatFilter(filter)),
        },
      });
    },
    fetchAmendment: (schemeCode: string, amendmentNumber: string) =>
      client.get<InitialAmendmentDetail>(`/amendments/${amendmentNumber}`, {
        params: { schemeCode },
      }),
    fetchSchemeHistory: (
      schemeCode: string,
      noZoneFormatFrom: string,
      noZoneFormatTo: string,
    ) => {
      const timeZoneDates = formatTimeZones(noZoneFormatFrom, noZoneFormatTo);

      return client.get<PlanningSchemeHistoryResponse>(
        `ordinance/scheme/${schemeCode}/history`,
        {
          params: {
            ...composeHistoriesFilter(timeZoneDates),
            includeScheme: 'true',
          },
        },
      );
    },
    fetchSchemeVersion: (schemeCode: string, version: string) =>
      client.get<PlanningSchemeHistoricalVersion>(
        `ordinance/scheme/${schemeCode}/history/${version}`,
      ),
    fetchSchemeVersionVersionMapData: (schemeCode: string, version: string) =>
      client.get<PlanningSchemeHistoricalVersion>(
        `ordinance/scheme/${schemeCode}/history/${version}/maps`,
      ),
    fetchSchemeIncorporatedDocuments: (schemeCode: string) =>
      client.get<IncorporatedDocumentResponse>(`docs/scheme/${schemeCode}`),
    fetchSchemePDFMaps: (schemeCode: string) =>
      client.get<PDFMapsResponse>(`pdfmaps/scheme/${schemeCode}`),
    downloadFile: (schemeCode, schemeName, from, to, status, outcome, type) => {
      const timeZoneDateFromTo = formatTimeZones(from, to);
      const endpointUrl = `/ordinance/scheme/${schemeCode}/history/export?${
        timeZoneDateFromTo.to !== undefined
          ? `to=${encodeURIComponent(timeZoneDateFromTo.to)}`
          : ''
      }${
        timeZoneDateFromTo.from !== undefined
          ? `&from=${encodeURIComponent(timeZoneDateFromTo.from)}`
          : ''
      }${status !== undefined ? `&filter.status=${status}` : ''}
        ${outcome !== undefined ? `&filter.outcome=${outcome}` : ''}
        ${type !== undefined ? `&filter.type=${type}` : ''}`;

      return new Promise((resolve, reject) => {
        client
          .get(endpointUrl, { responseType: 'blob' })
          .then((response) => {
            const newSchemeName = schemeName.replace(/ /g, '-');

            const filename = `${newSchemeName}-amendment-list.xlsx`;
            const blob = new Blob([response.data], {
              type: 'application/octet-stream',
            });

            FileSaver.saveAs(blob, filename);
            resolve(true);
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
            reject(error);
          });
      });
    },
    fetchAlertBannerDetails: (schemeId) =>
      client.get<AlertBannersFromDomain>('/banner', {
        params: { schemeCode: schemeId },
      }),
  };
};

export { schemesClient };
