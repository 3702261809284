import { OrdinanceNumberParams } from '@/store/schemes/types';

const navigableParams = (params: OrdinanceNumberParams): string[] => {
  if (params) {
    const { clause, subClause, schedule } = params;
    const sortedParams =
      params.schedule === undefined
        ? [clause, subClause]
        : [clause, subClause, 'schedule', schedule];
    return sortedParams.filter((it) => it !== undefined) as string[];
  }

  return [];
};

const buildOrdinanceNumberParts = (
  ordinanceParams: OrdinanceNumberParams,
): string => navigableParams(ordinanceParams).join('/');

export { buildOrdinanceNumberParts, navigableParams };
