































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SchemeAutocomplete extends Vue {
  @Prop({ required: true, type: Array }) schemes;

  private inputValue = '';

  private isOpen = false;

  private selectedIndex = 0;

  get selected() {
    return this.inputValue?.toLowerCase();
  }

  get matches() {
    return this.schemes.filter(
      (scheme) => scheme.toLowerCase().indexOf(this.selected) > -1,
    );
  }

  get openSuggestion(): boolean {
    return this.isOpen && this.matches.length > 0 && this.selected !== '';
  }

  public onEnter(event): void {
    const selectedScheme = this.matches[this.selectedIndex];
    if (!selectedScheme) {
      event.preventDefault();
      return;
    }

    this.toSelectedSchemePage(selectedScheme);
  }

  public onArrowUp(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    }
  }

  public onArrowDown() {
    if (this.selectedIndex < this.matches.length - 1) {
      this.selectedIndex += 1;
    }
  }

  public isActive(index) {
    return index === this.selectedIndex;
  }

  change() {
    this.selectedIndex = 0;
    if (this.isOpen === false) {
      this.isOpen = true;
    }
  }

  private toSelectedSchemePage(schemeName) {
    const url = `${window.location.protocol}//${window.location.host}/${schemeName}/ordinance`;
    window.location.assign(url);
  }

  public suggestionClick(index) {
    this.isOpen = false;
    const selectedScheme = this.matches[index];
    this.toSelectedSchemePage(selectedScheme);
  }

  public handleClickOutside(event) {
    if (!this.$el.contains(event.target)) {
      this.isOpen = false;
    }
  }

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  created() {
    this.$nextTick(() => {
      const customInput = this.$refs.customInput as HTMLInputElement;
      customInput.focus();
    });
  }

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }
}
