


















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import TextHighlight from 'vue-text-highlight';
import { SearchAmendmentRequestOptions } from '@/models';
import { AllSchemesScheme } from '@/helpers/all-schemes';
import {
  extractSearchKeywords,
  searchResultStyle,
} from '@/helpers/search-result-highlight';
import { RplCol, RplRow } from '@dpc-sdp/ripple-grid';
import PSOLink from '@/components/shared/PSOLink.vue';
import { schemesDisplayValue } from './search-amendment-row';
import { outcomeToString } from './convert-to-ui';

const namespace = 'amendment';

@Component({
  components: { TextHighlight, RplCol, RplRow, PSOLink },
  filters: { outcomeToString, schemesDisplayValue },
})
export default class SearchAmendmentRow extends Vue {
  public schemesDisplayValue;

  public capitalizeFirstLetter;

  public formatDate;

  public outcomeToString;

  @Prop() row;

  @Prop() allSchemesView;

  @Prop() psoColsBp;

  @Prop() listItems;

  @Getter('searchParams', { namespace })
  private searchParams!: SearchAmendmentRequestOptions;

  public queryTerms: string[] = [];

  get allSchemesTitle() {
    return AllSchemesScheme.title;
  }

  highlightStyle(): object {
    return searchResultStyle();
  }

  get markedId() {
    return this.searchAndMarkForSubString(
      this.queryTerms[0],
      this.row.amendmentNumber,
    );
  }

  searchAndMarkForSubString(searchTerm: string, text: string) {
    if (searchTerm && text) {
      const re = new RegExp(searchTerm, 'gi');
      const match = re.exec(text);

      if (match && match.length > 0) {
        const r = new RegExp(match[0], 'g');
        return text.replace(r, `<mark>${match[0]}</mark>`);
      }
    }

    return text;
  }

  mounted() {
    this.queryTerms = extractSearchKeywords(this.searchParams.q?.trim() || '');
  }
}
