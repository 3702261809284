import {
  Amendment as AmendmentModel,
  AmendmentDetail as AmendmentDetailModel,
  Pager,
  SearchAmendmentQueryParams,
} from '@/models';

import { MutationTree } from 'vuex';
import { toStoreSearchParams } from './filter-mapper';
import { AmendmentState } from './types';

export const mutations: MutationTree<AmendmentState> = {
  setAmendments(state, amendments: AmendmentModel[]) {
    state.amendments = amendments;
  },
  setPager(state, pager: Pager) {
    state.pager = pager;
  },
  setAmendmentDetail(state, amendmentDetail: AmendmentDetailModel) {
    state.amendmentDetail = amendmentDetail;
  },
  setSearchParams(state, params: SearchAmendmentQueryParams) {
    state.searchParams = toStoreSearchParams(params);
  },
  setTotalAmendments(state, total) {
    state.totalAmendments = total;
  },
};
