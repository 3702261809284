














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RplIcon from '@dpc-sdp/ripple-icon';

@Component({
  components: { RplIcon },
})
export default class CloseButton extends Vue {
  @Prop() public symbol;

  @Prop() public size;

  @Prop() public color;

  @Prop() public className;

  @Prop() public title;

  @Prop() public ariaLabel;

  public focus() {
    const customInput = this.$refs.button as HTMLInputElement;
    customInput.focus();
  }

  public hasFocus() {
    return document.activeElement === this.$refs.button;
  }
}
