const prePdfData = [
  {
    name: 'VPRS 16121',
    link: 'https://prov.vic.gov.au/archive/VPRS16121',
    title: 'Planning scheme record cards 1946 to 1988',
  },
  {
    name: 'VPRS 16131',
    link: 'https://prov.vic.gov.au/archive/VPRS16131',
    title:
      " Maps: Country Victoria planning schemes, interim development orders and amendments - 1946 to 'Day One' 16 February 1988",
  },
  {
    name: 'VPRS 16155',
    link: 'https://prov.vic.gov.au/archive/VPRS16155',
    title:
      'Maps: Country Victoria and Metropolitan region planning scheme maps, development plans and amendments - 1988 to 2000',
  },
  {
    name: 'VPRS 16156',
    link: 'https://prov.vic.gov.au/archive/VPRS16156',
    title:
      'Country Victoria and Metropolitan region planning schemes, interim development orders and approved amendments - 1946 to 1988',
  },
  {
    name: 'VPRS 16157',
    link: 'https://prov.vic.gov.au/archive/VPRS16157',
    title:
      'Melbourne Metropolitan Planning Scheme (MMPS) and interim development orders (IDOs), modifications, amendments and revocations - 1954 to 1988',
  },
  {
    name: 'VPRS 16158',
    link: 'https://prov.vic.gov.au/archive/VPRS16158',
    title:
      'Melbourne Metropolitan Planning Scheme (MMPS) ordinance and reprints 1968 to 1988 <br> Melbourne Metropolitan Planning Scheme amendments - as exhibited, as adopted, as approved, as lodged 1968 to 1988 <br> Melbourne Metropolitan Board of Works interim development orders 1954 to 1988',
  },
  {
    name: 'VPRS 16203',
    link: 'https://prov.vic.gov.au/archive/VPRS16203',
    title:
      'New format planning scheme maps based on the Victoria Planning Provisions - exhibition copy',
  },
  {
    name: 'VPRS 16204',
    link: 'https://prov.vic.gov.au/archive/VPRS16204',
    title:
      'Country Victoria and Metropolitan region planning scheme ordinances and amendments 1988 to 2000',
  },
  {
    name: 'VPRS 16223',
    link: 'https://prov.vic.gov.au/archive/VPRS16223',
    title:
      'New format planning scheme ordinances based on the Victoria Planning Provisions - exhibition copy',
  },
  {
    name: 'VPRS 16245',
    link: 'https://prov.vic.gov.au/archive/VPRS16245',
    title:
      'Map index to the Melbourne Metropolitan Planning Scheme amendment maps in VPRS 16157',
  },
  {
    name: 'VPRS 16260',
    link: 'https://prov.vic.gov.au/archive/VPRS16260',
    title:
      'Guide to the Melbourne Metropolitan Planning Scheme and Interim Development Orders in VPRS 16157',
  },
];
export default prePdfData;
