










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { PDFMapsByGridNumber } from '@/store/maps/types';
import Accordion from './Accordion.vue';

@Component({
  components: { Accordion },
})
export default class Accordions extends Vue {
  @Getter('selectedScheme')
  private selectedScheme;

  @Getter('schemeMaps', { namespace: 'maps' })
  private schemePDFMaps!: PDFMapsByGridNumber;

  @Action('fetchSchemePDFMaps', { namespace: 'maps' })
  private fetchPdfMaps;

  async mounted() {
    await this.fetchPdfMaps(this.selectedScheme.schemeID);
  }
}
