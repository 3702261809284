



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplDivider } from '@dpc-sdp/ripple-global';
import { DateFilter } from '@/store/incorporated-documents/types';
import DocumentsDateFilter from '@/components/incorporatedDocuments/DocumentsDateFilter.vue';
import DocumentsStatusFilter from '@/components/incorporatedDocuments/DocumentsStatusFilter.vue';
import DocumentsTypeFilter from '@/components/incorporatedDocuments/DocumentsTypeFilter.vue';
import DocumentsFilters from './DocumentsFilters.vue';

@Component({
  components: {
    DocumentsTypeFilter,
    DocumentsStatusFilter,
    DocumentsDateFilter,
    DocumentsFilters,
    RplDivider,
  },
})
export default class DocumentsSidebar extends Vue {
  @Prop() public title;

  @Prop() dateFilter!: DateFilter | undefined;

  handleDateChange(payload: DateFilter | undefined) {
    this.$emit('on-date-change', payload);
  }

  handleStatusChange(payload: string[]) {
    this.$emit('on-status-change', payload);
  }

  handleTypeChange(payload: string[]) {
    this.$emit('on-type-change', payload);
  }

  handleFiltersReset() {
    this.$emit('reset-filters');
  }
}
