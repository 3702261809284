import {
  AmendmentOutcome,
  AmendmentOutcomeSymbol,
  PlanningAuthority,
  PlanningAuthorityDecision,
  PlanningAuthorityDecisionSymbol,
  InitialPlanningAuthority,
} from '@/models';

export const decisionToSym = (
  decision: string,
): PlanningAuthorityDecisionSymbol => {
  switch (decision) {
    case 'Abandoned':
      return PlanningAuthorityDecision.Abandoned;
    case 'Adopted':
      return PlanningAuthorityDecision.Adopted;
    case 'Lapsed':
      return PlanningAuthorityDecision.Lapsed;
    case 'SplitIntoParts':
      return PlanningAuthorityDecision.SplitIntoParts;
    default:
      return PlanningAuthorityDecision.Unknown;
  }
};

export const outcomeToSym = (
  outcome?: string,
): AmendmentOutcomeSymbol | undefined => {
  if (!outcome) return undefined;

  switch (outcome) {
    case 'Abandoned':
      return AmendmentOutcome.Abandoned;
    case 'Approved':
      return AmendmentOutcome.Approved;
    case 'ApprovedByPlanningAuthority':
      return AmendmentOutcome.ApprovedByPlanningAuthority;
    case 'ApprovedWithChanges':
      return AmendmentOutcome.ApprovedWithChanges;
    case 'ATSMigratedUnspecified':
      return AmendmentOutcome.ATSMigratedUnspecified;
    case 'AuthorisationRefused':
      return AmendmentOutcome.AuthorisationRefused;
    case 'Lapsed':
      return AmendmentOutcome.Lapsed;
    case 'Refused':
      return AmendmentOutcome.Refused;
    case 'Revoked':
      return AmendmentOutcome.Revoked;
    case 'RevokingComplete':
      return AmendmentOutcome.RevokingComplete;
    case 'SplitParent':
      return AmendmentOutcome.SplitParent;
    case 'Withdrawn':
      return AmendmentOutcome.Withdrawn;
    default:
      return AmendmentOutcome.Unknown;
  }
};

export const convertPlanningAuthority = (
  planningStage?: InitialPlanningAuthority,
): PlanningAuthority | undefined => {
  if (!planningStage) return undefined;

  return {
    decision: decisionToSym(planningStage.decision),
    decisionDate: planningStage.decisionDate,
  };
};
