











import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Exhibition from './Exhibition.vue';
import Gazettal from './Gazettal.vue';
import Outcome from './Outcome.vue';
import PanelHearing from './PanelHearing.vue';
import PlanningAuthority from './PlanningAuthority.vue';
// import DepartmentAssessment from './DepartmentAssessment.vue';
import {
  // AssessmentParams,
  OutcomeDetails,
  PlanningAuthorityStatuses as PlanningStatuses,
  PlanningAuthorityDetails,
  Stages as StagesVM,
  GazettalDetails,
} from './stages.model';

const namespace = 'amendment';

@Component({
  components: {
    Exhibition,
    Gazettal,
    Outcome,
    PanelHearing,
    PlanningAuthority,
    // DepartmentAssessment,
  },
})
export default class Stages extends Vue {
  @Getter('stages', { namespace })
  private stages!: StagesVM;

  @Getter('planningAuthorityStatuses', { namespace })
  private planningAuthorityStatuses!: PlanningStatuses;

  // @Getter('departmentAssessment', { namespace })
  // private departmentAssessment!: AssessmentParams;

  @Getter('outcome', { namespace })
  private outcomeStage!: OutcomeDetails;

  @Getter('amendmentStatus', { namespace })
  private amendmentStatus!: string;

  get planningAuthorityDetails(): PlanningAuthorityDetails {
    return {
      stages: this.stages,
      statuses: this.planningAuthorityStatuses,
    };
  }

  // get departmentAssessmentData(): AssessmentParams {
  //   return this.departmentAssessment;
  // }

  get outcomeData(): OutcomeDetails {
    return this.outcomeStage;
  }

  get gazettalData(): GazettalDetails {
    const { gazettal } = this.stages;
    return {
      gazettal,
      status: this.amendmentStatus,
    };
  }
}
