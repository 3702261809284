export interface SchemeState {
  focusedItem: string;
  sortParams: Sorting | undefined;
  flatHistoricalOrdinances: SectionWithTags[];
  historicPDFSearchTerm: string;
  historicMapPDFSearchTerm: string;
  historicHTMLMapPDFSearchTerm: string;
  historicListSearchTerm: string;
  ordinance?: OrdinanceContent;
  historicOrdinance?: OrdinanceContent;
  redirectOrdinance?: OrdinanceReference;
  scheme: SchemeStructure;
  historicalScheme: HistoricalScheme | undefined;
  simpleOrdinances: SimpleOrdinance[];
  schemeVersions: PlanningSchemeVersion[];
  schemeVersionsFilters: SchemeVersionDateRange;
  backToTopSignal: string;
  pdfClausesColumns: string[];
  mapClausesColumns: string[];
  hasPlanningSchemeColumn: boolean;
  historicListOutcomeOptions: FilterOptions[];
  historicListStatusOptions: FilterOptions[];
  historicListTypeOptions: AmendmentTypeFilterOptions[];
  historicalListCurrentPage: number;
  mappedOrdinanceTypes: MappedOrdinanceType[];
  historicListClassificationOptions: FilterOptions[];
}

export interface MappedOrdinanceType {
  id: string;
  label: string;
}

export interface SchemeStructure {
  schemeID: string;
  title: string;
  amendmentNumber?: string;
  gazettalDate?: string;
  documentURL?: string;
  documentSize?: number;
  nav?: Navigation[];
  state?: SchemeActiveState;
}

export interface SchemeActiveState {
  active: boolean;
  altUri: string;
}

export interface Navigation {
  children?: Navigation[];
  ordinanceID: string;
  ordinanceLevel: string;
  ordinanceMapCode: string;
  ordinanceType: string;
  semanticNumber: string;
  title: string;
}

export interface SchemeVersionDateRange {
  from: string;
  to: string;
  status: string[];
  outcome: string[];
  type: string[];
  classification: string[];
}

export interface AmendmentTypeFilterOptions {
  key: string;
  label: string;
}

export interface PlanningSchemeVersion {
  id: string;
  operationalDate?: Date;
  description?: string;
  latest: boolean;
  planningScheme?: {
    code: string;
    name: string;
  };
  status: string;
  outcome: string;
  legacyAmendmentNumbers: string[];
  type: string;
  classification: AmendmentTypeFilterOptions;
}

export interface Scheme {
  amendmentNumber: string;
  clauses: Clause[];
  documentSize: number;
  documentURL: string;
  gazettalDate: string;
  schemeID: string;
  title: string;
}

export interface Clause extends Section {
  subClauses: SubClause[];
}

interface SubClause extends Section {
  schedules: Schedule[];
}

interface Schedule extends Section {
  sections: Section[];
}

export interface Section {
  amendmentNumber: string;
  gazettalDate: string;
  orderIndex: number;
  ordinanceID: string;
  ordinanceIncludable: string;
  ordinanceLevel: string;
  ordinanceMapCode: string;
  ordinanceType: string;
  title?: string;
  number?: string;
}

export interface OrdinanceReference {
  ordinanceID: string;
  childOrdinanceID?: string;
}

export interface OrdinanceContentActiveState {
  active: boolean;
  altUri?: string;
}

export interface OrdinanceContent {
  id: string;
  number: string;
  gazettalDate?: Date;
  amendmentNumber: string;
  title: string;
  content: string;
  sections?: OrdinanceContent[];
  state?: OrdinanceContentActiveState;
  ordinancePdfUrl?: string;
  error?: boolean;
}

// TODO- implement clause in here
export interface OrdinanceNumberParams {
  schemeCode?: string;
  clause?: string;
  subClause?: string;
  schedule?: string;
  section?: string;
  subSection?: string;
}

export interface SimpleOrdinance {
  id: string;
  type: string;
  semanticNumber: string;
}

export interface OrdinanceError {
  error: boolean;
  id: string;
  message: string;
}

export interface HistoricalScheme {
  amendmentNumber: string;
  clauses?: Clause[];
  operationalDate: Date | null;
  schemeID: string;
  title: string;
  isPdfVersion: boolean;
  isMapsPdfVersion?: boolean;
  documentSize?: number;
  documentURL?: string;
  pdfClauses?: PdfClause[];
  mapClauses?: MapClause[];
  legacyAmendmentNumbers: string[];
  type: string;
}

export interface MapClause {
  mapNumber?: string;
  mapType?: string;
  fileName?: string;
  size?: number;
}

export interface PdfClause {
  clause?: string;
  clauseType?: string;
  fileName?: string;
  size?: number;
}

export type SectionWithTags = Section & {
  tags: string[];
  ordinanceNumber: string | undefined;
};

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
} as const;

export type SortOrderType = typeof SortOrder[keyof typeof SortOrder];

export interface Sorting {
  sortKey: string;
  sortOrder: SortOrderType;
}

export interface SchemePDFMaps {
  amendmentNumber: string;
  maps: SchemePDFMap[];
}

export interface SchemePDFMap {
  id: number;
  introducedByAmendment: string;
  mapTitle: string;
  mapCode: string;
  gridReference: number;
  publicationDate: string;
  fileSize: number;
  fileType: string;
  status: string;
  url: string;
}

export interface PDFMapsByGridNumber {
  gridNumber: string;
  maps: SchemePDFMap[];
}

export interface SchemeAmendmentAndGazettalDate {
  amendmentNumber: string | undefined;
  gazettalDate: string | undefined;
}

export interface FilterOptions {
  key: string;
  label: string;
}
