









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { component as Viewer } from 'v-viewer';

@Component({
  components: {
    RplIcon,
    Viewer,
  },
})
export default class OpenImageModalButton extends Vue {
  @Prop() content;

  public show(): void {
    this.$viewerApi({
      options: {
        backdrop: true,
        navbar: false,
        title: false,
        inline: true,
        tooltip: false,
        toolbar: {
          zoomIn: { show: true, size: 'large' },
          zoomOut: { show: true, size: 'large' },
          oneToOne: { show: false, size: 'large' },
          reset: { show: false, size: 'large' },
          prev: { show: false },
          play: { show: false, size: 'large' },
          next: { show: false },
          rotateLeft: { show: true, size: 'large' },
          rotateRight: { show: true, size: 'large' },
          flipHorizontal: { show: false, size: 'large' },
          flipVertical: { show: false, size: 'large' },
        },
      },
      images: this.content,
    });
  }
}
