






























































import SvgIcon from '@/components/shared/SvgIcon.vue';
import { RplCol } from '@dpc-sdp/ripple-grid';
import { RplIcon, RplTextIcon } from '@dpc-sdp/ripple-icon';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { formatDateDmy } from './convert-from-domain';

@Component({
  filters: {
    formatDateDmy,
  },
  components: { RplCol, RplTextIcon, RplIcon, SvgIcon },
})
export default class OrdinanceInfo extends Vue {
  @Prop() mobile;

  @Prop() gazettalDate;

  @Prop() searchScheme;

  @Prop() amendmentNumber;

  @Prop() tags;

  @Prop() version;

  @Prop() schemeTitle;

  @Prop() mappedOrdinanceType;

  get isHistoricalNavigator(): boolean {
    return this.version !== undefined;
  }
}
