const formatDateDmy = (date: Date): string => {
  if (date instanceof Date) {
    return `${String(date.getDate()).padStart(2, '0')}/${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}/${date.getFullYear()}`;
  }
  return '';
};

export { formatDateDmy };
