const externalIcon = `<svg viewBox="0 0 14 14" aria-hidden="true" overflow="visible" class="rpl-icon rpl-icon--external_link rpl-icon--color_primary rpl-text-icon--after" style="width: 14px; height: 14px;"><path d="M14 1.54v11.062C14 13.3 13.3 14 12.46 14H1.54C.7 14 0 13.3 0 12.46V1.54C0 .7.7 0 1.54 0h11.062C13.3 0 14 .7 14 1.54zm-2.8.42H7.7c-.56 0-.981.84-.419 1.259l.98.98-6.16 6.16a.428.428 0 000 .563l.977.976c.14.141.422.141.563 0l6.16-6.16.98.98c.418.563 1.258.141 1.258-.417V2.8c0-.422-.418-.84-.84-.84z"></path></svg>`;
const pdfIcon = `<svg data-v-75058d3a="" viewBox="0 0 20 20" aria-hidden="true" overflow="visible" class="rpl-icon rpl-icon--pdf rpl-icon--color_primary" style="width: 20px; height: 20px;"><!----> <path d="M10 5V2.824L12.176 5H10zm.003-5H1a1 1 0 00-1 1v18a1 1 0 001 1h13a1 1 0 001-1v-3h-2v2H2V2h6v4a1 1 0 001 1h4v2h2V4.997L10.003 0z"></path><path d="M9.863 11.102H9.17v1.008h.693c.336 0 .532-.244.532-.503 0-.26-.196-.505-.532-.505zm.057 2.107h-.75v1.78H7.944v-4.987H9.92c1.106 0 1.701.785 1.701 1.604 0 .82-.595 1.604-1.7 1.604zM14.547 11.375c-.112-.168-.28-.28-.59-.28h-.538v2.801h.539c.309 0 .477-.112.589-.28.098-.139.133-.272.133-1.12 0-.841-.035-.98-.133-1.121zm.84 3.124c-.343.343-.826.49-1.345.49h-1.85v-4.986h1.85c.519 0 1.002.146 1.345.49.582.581.518 1.219.518 2.003s.064 1.422-.518 2.003zM17.836 11.095v.876h1.85v1.093h-1.85v1.926H16.61v-4.987H20v1.092h-2.164z"></path> </svg>`;

const constructLink = (
  link: string,
  name: string,
  newTab: boolean,
): string => `<a
      href=${link} ${newTab ? 'target=_blank' : ''}
        class="rpl-link rpl-text-link rpl-text-link--underline"
      >
        <span class="rpl-link__inner">
          <span
            class="
              rpl-text-label
              rpl-text-label--small--underline
            "
          >
             ${name}</span>
              <span class="rpl-text-icon__group">
                ${externalIcon}</span></span
      ></a>`;

const constructPdfLink = (
  link: string | undefined,
  name: string | undefined,
  newTab: boolean,
): string => `<a
      href=${link} ${newTab ? 'target=_blank' : ''}
        class="rpl-link rpl-text-link rpl-text-link--underline"
      >
        <span class="rpl-link__inner">
          <span
            class="
              rpl-text-label
              rpl-text-label--small--underline
            "
          >
           <span class="rpl-text-icon__group">
                ${pdfIcon}</span>
            <span>
              ${name}
             </span></span></span
      ></a>`;

export { constructLink, constructPdfLink };
