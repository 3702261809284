import { NavigationGuardNext, Route } from 'vue-router';
import Vue from 'vue';

export const previousRouteMixin = {
  data() {
    return { previousRoute: undefined as Route | undefined };
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    const { name, params, query } = from;
    next((vm: Vue) => {
      // Function to avoid no-param-reassign eslint
      const noParamReassignAvoidFunction = (vueComponent) => {
        const component = vueComponent;
        // Only override the previousRoute if name is defined.
        if (name) {
          component.previousRoute = { name, params, query };
        }
      };
      noParamReassignAvoidFunction(vm);
    });
  },
};
