








































import Vue from 'vue';
import RadioGroup from '@/components/shared/RadioGroup.vue';
import { Component, Prop } from 'vue-property-decorator';
import { RplSelect } from '@dpc-sdp/ripple-form';
import { TableHeader } from '@/models/table-header';
import { Sorting } from '@/store/schemes/types';

@Component({
  components: {
    RadioGroup,
    RplSelect,
  },
})
export default class SortMobile extends Vue {
  @Prop() headers!: TableHeader[];

  @Prop() sortParams: Sorting | undefined;

  get dropdownValues() {
    return this.headers.map((header) => ({
      id: header.id,
      name: header.text,
    }));
  }

  get selectedSortKey() {
    return this.sortParams?.sortKey || 'operationalDate';
  }

  get selectedSortOrder() {
    return this.sortParams?.sortOrder || 'desc';
  }

  public reset() {
    this.$emit('on-sort-change', undefined);
  }

  public updateSortOrder(sortOrder: string) {
    this.$emit('on-sort-change', { sortOrder, sortKey: this.selectedSortKey });
  }

  public updateSortKey(sortKey: string) {
    this.$emit('on-sort-change', {
      sortKey,
      sortOrder: this.selectedSortOrder,
    });
  }
}
