















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RadioGroup extends Vue {
  @Prop() name;

  @Prop() selectedValue;

  @Prop() options;

  get radioButtonValue() {
    return this.selectedValue;
  }

  set radioButtonValue(newValue: string) {
    this.$emit('change', newValue);
  }
}
