
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import OrdinanceInfo from '@/components/scheme/OrdinanceInfo.vue';

@Component({
  components: {
    OrdinanceInfo,
    RplRow,
    RplCol,
  },
})
export default class HistoricSearchResultRow extends Vue {
  @Prop() row;

  @Prop() searchTerm;

  @Prop() listItems;

  public displayUrl(href) {
    return `${window?.location?.host}${href}`;
  }

  get markedMapCode() {
    if (this.searchTerm && this.row.ordinanceMapCode) {
      const re = new RegExp(this.searchTerm, 'gi'); // search for all instances
      const match = re.exec(this.row.ordinanceMapCode);
      if (match && match.length > 0) {
        const r = new RegExp(match[0], 'g');
        return `Map code: ${this.row.ordinanceMapCode.replace(
          r,
          `<mark>${match[0]}</mark>`,
        )}`;
      }
    }

    return `Map code: ${this.row.ordinanceMapCode}`;
  }

  get markedTitle() {
    if (this.searchTerm && this.row.title) {
      const re = new RegExp(this.searchTerm, 'gi'); // search for all instances
      const match = re.exec(this.row.title);
      if (match && match.length > 0) {
        const r = new RegExp(match[0], 'g');
        return this.row.title.replace(r, `<mark>${match[0]}</mark>`);
      }
    }

    return this.row.title;
  }
}
